<h3 class="mb-2">{{ "CONTROLBAR_HEADER_AGENT" | Translate : "Agent" }}</h3>

  <p-accordion class="mr-5" [activeIndex]="[0]" [multiple]="true">
    <p-accordionTab #appointmentGroup class="pb-3"  *ngFor="let agent of (selectedAgents | keyvalue)">
      <ng-template pTemplate="header">
        <div class="d-flex w-100 flex-row align-items-center justify-content-between text-capitalize mr-2">
          <span class="flex-grow-1">{{agent.value.firstname}} {{agent.value.lastname}} </span>
          <p-chip [label]="agent.value.status"></p-chip>
        </div>
      </ng-template>

      <table class="table table-borderless " *ngIf="agentIsEngaged(agent.value); else notEngaged">
        <thead>
          <tr class="d-flex text-center">
            <th class="col-1">{{ "SUPERVISOR_TABLEHEADER_STATUS"   | Translate : 'Status' }}</th>
            <th class="col-2">{{ "SUPERVISOR_TABLEHEADER_DURATION" | Translate : 'Engagement Duration' }}</th>
            <th class="col-2">{{ "SUPERVISOR_TABLEHEADER_TIMERESPONSE" | Translate : 'Time Since Response' }}</th>
            <th class="col-3" [ngClass]="{'col-5': !privateChatAvailable}">
              {{ "SUPERVISOR_TABLEHEADER_LASTPAGE" | Translate : 'Last Page' }}</th>
            <th class="col-2" *ngIf="privateChatAvailable">
              {{ "SUPERVISOR_TABLEHEADER_AGENTASSIST" | Translate : 'Agent Assist' }}</th>
            <th class="col-2">{{ "SUPERVISOR_TABLEHEADER_ENTERSESSION" | Translate : 'Enter Session' }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let visitor of agentVisitors(agent.value)">
            <tr class="d-flex text-center">
              <td class="col-1">
                <img class="engagement-status-icon" width="32" height="32"
                     [src]="CallStatuses.getStatusIcon(visitor?.visitorStatus)"
                     [alt]="CallStatuses.getStatusText(visitor?.visitorStatus)"
                     [title]="CallStatuses.getStatusText(visitor?.visitorStatus)">
              </td>
              <td class="col-2">{{TimeUtil.PrettyRelativeTime(visitor.assistTime)}}</td>
              <td class="col-2">{{TimeUtil.PrettyRelativeTime(visitor.getTimeSinceResponse())}}</td>
              <td class="col-3 text-truncate" [ngClass]="{'col-5': !privateChatAvailable}">{{visitor.lastPage}}</td>
              <td class="col-2" *ngIf="privateChatAvailable">
                <img *ngIf="visitor.agentRequestingAssistance"
                      src="./assets/call-status-icons/handraise_btn.svg">
              </td>
              <td class="col-2">
                <div class="d-flex flex-row align-items-center justify-content-center">
                  <p-button  styleClass="center-icon" severity="success"
                            [ngClass]="{'d-none': !supervisingVisitors.has(visitor.engagementGuid) && !supervisingEngagements.has(visitor.engagementGuid)}"
                            (onClick)="onEnterSession(visitor)">
                    <fa-icon [icon]="faPhone"></fa-icon>

                  </p-button>
                  <p-button  severity="info" styleClass="center-icon"
                            [ngClass]="{'d-none': supervisingVisitors.has(visitor.engagementGuid) || supervisingEngagements.has(visitor.engagementGuid)}"
                            (onClick)="onStartSnooping(visitor)">
                    <fa-icon [icon]="faUserSecret"></fa-icon>

                  </p-button>
                </div>

              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <ng-template #notEngaged>
        <table class="table table-borderless ">
          <thead>
            <tr class="d-flex text-center">
              <th class="col-3">{{ "SUPERVISOR_TABLEHEADER_STATUS" | Translate : 'Status' }}</th>
              <th class="col-2">{{ "SUPERVISOR_TABLEHEADER_TIMELASTSESSION" | Translate : 'Time Since Last Session' }}</th>
              <th class="col-2">{{ "SUPERVISOR_TABLEHEADER_TIMELOGIN"  | Translate : 'Time Since Logging In' }}</th>
              <th class="col-2">{{ "SUPERVISOR_TABLEHEADER_TOTALCALLS" | Translate : 'Total Calls for Today' }}</th>
              <th class="col-2">{{ "SUPERVISOR_TABLEHEADER_ASYNCMESSAGES"  | Translate : 'Total Async Messages Sent Today' }}</th>
            </tr>
          </thead>
          <tbody>
              <tr class="d-flex text-center">
                <td class="col-3">{{agent.value.status | titlecase}}</td>
                <td class="col-2">{{TimeUtil.CreateTimeDifferenceForDisplay(agent.value.lastEngagementTimestamp)}}</td>
                <td class="col-2">{{TimeUtil.CreateTimeDifferenceForDisplay(agent.value.loggedInTimestamp)}}</td>
                <td class="col-2">{{agent.value.totalSessionNumber}}</td>
                <td class="col-2">{{agent.value.totalAsyncMessagesSent}}</td>
              </tr>
          </tbody>
        </table>
      </ng-template>
    </p-accordionTab>
  </p-accordion>

<div *ngIf="selectedAgents.size === 0">
  <p>{{ "SUPERVISOR_ALERT_NOAGENT" | Translate : 'No agents logged in.' }}</p>
</div>
