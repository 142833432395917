<div class="d-flex flex-row flex-grow-1 h-100 supervisor-engagement gap-1">
  <app-visitor-information
                                    [engagement]="engagement"
                                    [(currentSection)]="currentSection"
                                    [currentUrl]="currentUrl$ | async"
                                    [currentState]="(currentState$ | async)"
                                    [visitorCrmData]="(visitorCrmData$ | async)"
                                    [emailState]="CrmEmailState.Valid"
                                    [visitorDetails]="(visitorDetails$ | async)"
                                    [visitorSessionDetails]="(visitorSessionDetails$ |async)"
                                    [visitorSessionHistory]="(visitorSessionHistory$ | async)"
                                    [visitorBrowsingHistory]="(visitorBrowsingHistory$ | async)"
                                    [checklistfields]="[]"
                                    [userGuid]="userGuid">
  </app-visitor-information>

  <div *ngIf="privateChatAvailable && !(privateChatEnabled | async)"
       class="d-flex flex-column private-chat-placeholder justify-content-center p-4 mr-1"
       (click)="joinPrivateChat(true)">
    <p>{{ "SUPERVISORENGAGEMENT_LABEL_PREVIEW" | Translate : 'You are Currently Previewing the Engagement' }}</p>
    <p-button styleClass="p-button-text text-secondary">{{ "SUPERVISORENGAGEMENT_BUTTON_PRIVATECHAT" | Translate : 'Click here to begin a private chat with the agent' }}</p-button>
    <img src="./../../../../assets/images/icon-open-private-chat.svg" height="150">
  </div>

  <div *ngIf="privateChatAvailable && (privateChatEnabled | async)" class="sidebar-panel  ">
    <app-engagement-private-chat-tab class="h-100"
                                      [supervisorsAvailable]="true"
                                      [privateChatMessages]="(privateChatMessages | async)"
                                      (sendPrivateMessage)="sendPrivateChatMessage($event)">
    </app-engagement-private-chat-tab>
  </div>

  <div *ngIf="!(supPublicChatEnabled | async)" #scrollMe [scrollTop]="scrollMe.scrollHeight" class="d-flex flex-column h-100 sidebar-panel overflow-auto">
    <ng-container *ngFor="let message of engagement?.messages">
      <app-text-chat [message]="message" ></app-text-chat>
    </ng-container>
  </div>

  <div *ngIf="(supPublicChatEnabled | async)" class="d-flex flex-column sidebar-panel">
    <div class="d-flex flex-row align-items-center justify-content-center py-2 surface-b border-bottom">
      <p-tag severity="danger">{{ "SUPERVISORENGAGEMENT_LABEL_CUSTOMERCONVERSATION" | Translate : "Customer Facing Conversation" }}</p-tag>
    </div>
      <div #scrollMe [scrollTop]="scrollMe.scrollHeight" class="d-flex flex-column flex-grow-1 overflow-x-hidden overflow-y-auto messages-container">
        <ng-container *ngFor="let message of engagement?.messages">
          <app-text-chat [message]="message" class="text-message"></app-text-chat>
        </ng-container>
      </div>

      <div class="d-flex flex-row w-100 mt-2 message-editor gap-1">
        <textarea [(ngModel)]="agentText" pInputTextarea
                  class="message-area overflow-x-hidden overflow-y-auto flex-1"
                  rows="5"
                  placeholder='{{ "SUPERVISORENGAGEMENT_LABEL_CUSTOMERMESSAGE" | Translate : "Type customer message here..." }}'
                  (keyup.enter)="sendMessage()">
        </textarea>
        <p-button type="button"
                styleClass="btn-square center-icon h-100"
                  severity="success"
                (onClick)="sendMessage()">
            <fa-icon [icon]="faPaperPlane"></fa-icon>
        </p-button>
      </div>
    </div>

  <app-supervisor-engagement-controls class="bottom-ctrls"
                                      [privateChatEnabled]="(privateChatEnabled | async)"
                                      [publicChatEnabled]="(supPublicChatEnabled | async)"
                                      [privateChatAvailable]="privateChatAvailable"
                                      (startPrivateChat)="joinPrivateChat($event)"
                                      (startPublicChat)="joinPublicChat($event)">
  </app-supervisor-engagement-controls>
</div>
