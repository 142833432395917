import {Component, Output, EventEmitter, Input} from '@angular/core';
import { faSearch, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { MatchedCustomer } from '../../services/crm-service/matched-customer';
import { BehaviorSubject } from 'rxjs';
import { CrmService } from '../../services/crm-service/crm.service';

@Component({
  selector: 'app-engagement-customer-search',
  templateUrl: './engagement-customer-search.component.html',
  styleUrls: ['./engagement-customer-search.component.scss']
})
export class EngagementCustomerSearchComponent {
  @Input() disabled: boolean;

  public searchTerm: string;
  public rows: MatchedCustomer[];
  public count: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public pageNumber: number;
  public loading: boolean;

  faSearch = faSearch;
  faSignInAlt = faSignInAlt;

  @Output() loadOldCustomer: EventEmitter<MatchedCustomer> = new EventEmitter<MatchedCustomer>();

  constructor(private crmService: CrmService) { }


  searchForCustomer() {
    this.loading = true;
    this.crmService.findCustomer(this.searchTerm).subscribe(results => {
      if (results) {
        this.rows = results;
        this.loading = false;
      }
    });
  }

  loadCustomer(customer: MatchedCustomer) {
    if (!this.disabled) {
      this.loadOldCustomer.emit(customer);
    }
  }

  public setPage(pageInfo) {
    this.pageNumber = pageInfo.offset;
  }
}
