import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Visitor} from '../../../services/visitor-service/visitor';
import {EngagementService} from '../../../services/engagement.service';
import {
  HelpRequest,
  HelpRequestType,
  Joined,
  JoinedEngagement,
  NoEngagement,
  Snooping,
  SnoopingConfiguration,
  SnoopingEngagement,
  SnoopingType,
  SupervisingEngagement,
  SupervisingEngagementTypes,
} from '../supervisor.component';
import {VisitorService} from '../../../services/visitor-service/visitor.service';
import {TimeUtil} from '../../../utils/time-util';
import {AsyncConversation} from '../../../services/async-conversation';

export enum SupervisorEngagementState {
  SNOOPING,
  PRIVATE_CHAT,
  PUBLIC_CHAT,
  HELP_REQUEST
}

@Component({
  selector: 'app-supervisor-chat-list',
  templateUrl: './supervisor-chat-list.component.html',
  styleUrls: ['./supervisor-chat-list.component.scss']
})
export class SupervisorChatListComponent implements OnInit {
  @Input() supervisorVisitors: Snooping[];
  @Input() supervisorEngagements: Joined[];
  @Input() supervisingGroups: Set<string>;

  private _supervisingEngagement: SupervisingEngagement;
  @Input() get supervisingEngagement() {
    return this._supervisingEngagement;
  }

  set supervisingEngagement(supervisingEngagement: SupervisingEngagement) {
    this._supervisingEngagement = supervisingEngagement;
    this.supervisingEngagementChange.emit(supervisingEngagement);
  }

  @Input() privateChatAvailable: boolean;

  @Output() supervisingEngagementChange: EventEmitter<SupervisingEngagement> = new EventEmitter();
  @Output() startMonitoring: EventEmitter<SnoopingConfiguration> = new EventEmitter<SnoopingConfiguration>();
  @Output() joinPrivateChat: EventEmitter<SnoopingConfiguration> = new EventEmitter<SnoopingConfiguration>();

  @Input() helpRequestEngagements: Map<string, HelpRequest> = new Map<string, HelpRequest>();

  TimeUtil = TimeUtil;
  SupervisorEngagementState = SupervisorEngagementState;
  HelpRequestType = HelpRequestType;

  constructor(
    private engagementService: EngagementService,
    private visitorService: VisitorService,
  ) { }

  ngOnInit() {
  }

  changeSupervisingEngagement(newVisitor: Visitor) {
    if (!newVisitor) {
      this.supervisingEngagement = <NoEngagement> {
        type: SupervisingEngagementTypes.None,
      };
    } else if (!this.engagementService.isEngagedByMe(newVisitor) && newVisitor.isEngaged) {
      const snooping: SnoopingEngagement = {
        type: SupervisingEngagementTypes.Snooping,
        engagementId: newVisitor.engagementGuid,
        visitor: newVisitor,
      };
      this.supervisingEngagement = snooping;
    } else {
      const supervising: JoinedEngagement = {
        type: SupervisingEngagementTypes.Supervising,
        engagementId: newVisitor.engagementGuid,
        engagement: this.engagementService.getEngagement(newVisitor.engagementGuid),
        visitor: newVisitor,
      };
      this.supervisingEngagement = supervising;
    }
  }

  changeSupervisingAsyncConversation(supervisingConversation: AsyncConversation) {
    this.supervisingEngagement = {
      type: SupervisingEngagementTypes.SnoopingOnAsyncChat,
      conversationId: supervisingConversation.id.Id,
      conversation: supervisingConversation,
      visitor: supervisingConversation.visitor,
    };
  }

  public startSnooping(helpRequest: HelpRequest, $event:Event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    switch (helpRequest.type) {
      case HelpRequestType.EngagementHelpRequest:
        this.startMonitoring.emit({ type: SnoopingType.Visitor, visitor: helpRequest.visitor });
        break;
    }
  }

  public helpAgent(helpRequest: HelpRequest) {
    switch (helpRequest.type) {
      case HelpRequestType.EngagementHelpRequest:
        this.joinPrivateChat.emit({ type: SnoopingType.Visitor, visitor: helpRequest.visitor });
        break;
    }
  }

  public getAgentName(username: string): string {
    if (username) {
      const agent = this.visitorService.agentStatus.value.get(username);
      if (agent) {
        return `${agent.firstname} ${agent.lastname}`;
      } else {
        return username;
      }
    } else {
      return '';
    }
  }
}
