import {Component, Input} from '@angular/core';
import {faHeart} from '@fortawesome/free-solid-svg-icons/faHeart';
import {faEye} from '@fortawesome/free-solid-svg-icons/faEye';
import {faVolumeUp} from '@fortawesome/free-solid-svg-icons/faVolumeUp';
import {faEyeSlash, faHeartBroken, faHeartCircleMinus, faVolumeMute} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-engagement-toolbar',
  templateUrl: './engagement-toolbar.component.html',
  styleUrls: ['./engagement-toolbar.component.scss']
})
export class EngagementToolbarComponent {
  public faHeart = faHeart;
  public faEye = faEye;
  public faVolumeUp = faVolumeUp;

  @Input() visitorHeartbeat: boolean;
  @Input() chatOnly: boolean;
  @Input() speakerVolume: number;
  @Input() backgrounded: boolean = false;

  @Input() customerViewingOperator: boolean;
  @Input() customerHearingOperator: boolean;

  constructor() {
  }

  protected readonly faHeartCircleMinus = faHeartCircleMinus;
  protected readonly faHeartBroken = faHeartBroken;
  protected readonly faEyeSlash = faEyeSlash;
  protected readonly faVolumeMute = faVolumeMute;
}
