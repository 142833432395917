import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Visitor} from '../../../services/visitor-service/visitor';
import {faCaretDown, faCaretRight, faPhone, faSignInAlt, faUserSecret} from '@fortawesome/free-solid-svg-icons';
import {AgentStatus} from '../../../classes/visitor/AgentStatus';
import {CallStatuses} from '../../../utils/call-statuses';
import {TimeUtil} from '../../../utils/time-util';
import {Joined, Snooping, SnoopingConfiguration, SnoopingType} from '../supervisor.component';

@Component({
  selector: 'app-supervisor-table-view',
  templateUrl: './supervisor-table-view.component.html',
  styleUrls: ['./supervisor-table-view.component.scss']
})
export class SupervisorTableViewComponent  {
  @Input() supervisingVisitors: Map<string, Snooping> = new Map([]);
  @Input() supervisingEngagements: Map<string, Joined> = new Map([]);
  @Input() selectedVisitors: Visitor[];
  @Input() selectedAgents: Map<string, AgentStatus>;
  @Input() privateChatAvailable: boolean;

  @Output() enterSession: EventEmitter<SnoopingConfiguration> = new EventEmitter<SnoopingConfiguration>();
  @Output() startSnooping: EventEmitter<SnoopingConfiguration> = new EventEmitter<SnoopingConfiguration>();


  TimeUtil = TimeUtil;
  faCaretDown = faCaretDown;
  faCaretRight = faCaretRight;
  faSignIn = faSignInAlt;
  faPhone = faPhone;
  public CallStatuses = CallStatuses;

  onEnterSession(visitor: Visitor) {
    if (visitor.isAsync) {
      this.enterSession.emit({ type: SnoopingType.AsyncChat, visitor });
    } else {
      this.enterSession.emit({ type: SnoopingType.Visitor, visitor });
    }
  }

  onStartSnooping(visitor: Visitor) {
    if (visitor.isAsync) {
      this.startSnooping.emit({ type: SnoopingType.AsyncChat, visitor });
    } else {
      this.startSnooping.emit({ type: SnoopingType.Visitor, visitor });
    }
  }

  agentIsEngaged(agent: AgentStatus): boolean {
    return this.selectedVisitors.some(visitor => {
      return (visitor.currentOperator === agent.firstname + ' ' + agent.lastname) || (visitor.currentOperator === agent.username);
    });
  }

  agentVisitors(agent: AgentStatus): Visitor[] {
    return this.selectedVisitors.filter(visitor => {
      return (visitor.currentOperator === agent.firstname + ' ' + agent.lastname) || (visitor.currentOperator === agent.username);
    });
  }

  protected readonly faUserSecret = faUserSecret;
}
