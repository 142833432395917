import {Component, Input, OnInit} from '@angular/core';
import { faFile, faPuzzlePiece, faMapMarker, faGlobe, faBuilding, faUser, faLocationArrow, faAddressCard, faLink, faMobile } from '@fortawesome/free-solid-svg-icons';
import {Browsers} from '../../utils/browsers';
import {StringUtils} from '../../utils/string-utils';

@Component({
  selector: 'app-visitor-details',
  templateUrl: './visitor-details.component.html',
  styleUrls: ['./visitor-details.component.scss']
})
export class VisitorDetailsComponent implements OnInit {

  faFile = faFile;
  faPuzzlePiece = faPuzzlePiece;
  faGlobe = faGlobe;
  faBuilding = faBuilding;
  faMapMarker = faMapMarker;
  faUser = faUser;
  faLocationArrow = faLocationArrow;
  faAddressCard = faAddressCard;
  faLink = faLink;
  faMobile = faMobile;

  @Input() page: string;
  @Input() section: string;
  @Input() prevOp: string;

  @Input() set useragent(ua: string) {
    if (ua == null) {
      this.browser = '';
      this.deviceType = '';
    } else {
      const browser = Browsers.getBrowserInfo(ua);

      this.browser = `${StringUtils.capitalise(browser.browser)} ${browser.version}`;
      this.deviceType = StringUtils.capitalise(browser.deviceType);
    }
  }

  browser = '';
  deviceType = '';

  @Input() organisation: string;
  @Input() location: string;
  @Input() ip: string;

  @Input() referrer: string;

  /* Could have done this with a list of KVPs.
   * This gives a bit more scope to adding elements in though
   * without having to delegate off to some other components.
   */
  constructor() {
  }

  ngOnInit() {
  }
}
