import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, Subject, combineLatest } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggingService } from '../../../services/logging.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { CallInviteState, CustomerSizes, Engagement, EngagementAgent, EngagementState, EngagementStateType, PanelSize, CommunicationMode, EngagementVisitor } from '../../../services/engagement';
import { MissingVisitorModalContentComponent } from './engagement-missing-visitor-modal-content/engagement-missing-visitor-modal-content.component';
import { EngagementService } from '../../../services/engagement.service';
import { VisitorService } from '../../../services/visitor-service/visitor.service';
import { AlertService, AlertType } from '../../../services/alert-service/alert.service';
import { LoadingService } from '../../../services/loading.service';
import { OnlineService } from '../../../services/online.service';
import { OnlineState } from '../../../enums/online-state.enum';
import { distinctUntilChanged, map, first, filter, withLatestFrom, shareReplay, tap, take } from 'rxjs/operators';
import { EndChatAgent, EndChatModalComponent, EndType } from '../../../components/end-chat-modal/end-chat-modal.component';
import { NotificationService } from '../../../services/notification.service';
import { FileTransferService, ValidateFileType } from '../../../services/file-transfer-service/file-transfer.service';
import { IFileUpload } from '../../../services/file-transfer-service/file-upload-interface';
import { WebrtRTCMessagingService } from '../../../services/webrtc-messaging-service/webrtcmessaging.service';
import { CrmStructure } from '../../../services/crm-service/crm-category';
import { Visitor } from '../../../services/visitor-service/visitor';
import { VisitorDetails } from '../../../classes/visitor-details';
import { EngagementAsset } from '../../../classes/EngagementAssets';
import { EngagementEvent } from '../../../services/crm-service/engagement-event';
import { BrowsingHistory } from '../../../services/crm-service/browsing-history';
import { InsertAppointment } from '../../../services/crm-service/appointment';
import { EmailService } from '../../../services/email-service/email.service';
import { AuthService } from '../../../services/auth-service/auth.service';
import { CrmEmailState, CrmEmailStateType } from '../../../services/crm-service/crm-email-state';
import { CrmEmail } from '../../../utils/crm-email';
import { CrmService } from '../../../services/crm-service/crm.service';
import { CheckListField, ChecklistFieldType } from '../../../classes/checklist/CheckListField';
import { Opportunity } from '../../../services/crm-service/opportunity';
import { BrowserService } from '../../../services/browser-service/browser.service';
import { MatchedCustomer } from '../../../services/crm-service/matched-customer';
import { SettingsService } from '../../../services/settings-service/settings.service';
import { FeatureService, Features } from '../../../services/feature-service/feature.service';
import { TextMessage } from '../../../classes/TextMessage';
import { ElectronService } from '../../../services/electron-service/electron.service';
import { VersionService } from '../../../services/version-service/version.service';
import { IRecordingService, DesktopRecordingService } from '../../../services/desktop-recording-service/desktop-recording.service';
import { FeedType } from '../../../enums/multipeer/feed-type.enum';
import { SharingControllerService, SharingConfig } from '../../../services/sharing-controller-service/sharing-controller.service';
import { CustomerInvite } from '../../../components/engagement-join-customers/CustomerInvite';
import { TranslatePipe } from '../../../filters/Translate.pipe'
import { VisitorSiteDetails } from '../../../classes/visitor/VisitorSiteDetails';
import { VisitorSessionDetails } from '../../../classes/visitor/VisitorSessionDetails';
import {OutboundMessage} from '../../../components/engagement-text-chat/engagement-text-chat.component';
import {Size} from '../../../classes/Size';
import {CallType} from '../../../enums/call-type.enum';
import { BlockCustomerModalComponent } from '../../../components/block-user-modal/block-user-modal.component';
import { EngagementMode } from '../../../enums/engagement-mode';

import {PostEngagementStatus} from '../../../classes/post-engagement.status';
import {ModalService} from '../../../services/modal.service';
import {Section} from "../../../components/visitor-information/visitor-information.component";

@Component({
  selector: 'app-engagement',
  templateUrl: './engagement.component.html',
  styleUrls: ['./engagement.component.scss'],
  providers: [WebrtRTCMessagingService,TranslatePipe]
})
export class EngagementComponent implements OnInit, OnDestroy {
  private static MISSED_POLLS_REQ_FOR_MISSING_VISITOR_PROMPT = 60;
  private static readonly EMPTY_VISITOR_DETAILS = {
    page: '',
    section: '',
    prevOp: '',
    organisation: '',
    location: '',
    ip: '',
    referrer: '',
    useragent: '',
  };
  private static readonly EMPTY_VISITOR_SESSION_DETAILS = {
    ip: '',
    referrer: '',
    organisation: '',
    width: '0',
    height: '0',
    deviceScaleFactor: '0',
    isMobile: false,
    isTablet: false,
    clientWidth: '0',
    clientHeight: '0',
  };

  private subscriptions: Subscription[] = [];

  public engagementId: string;
  public username: string;
  public useCoBrowsing: boolean;
  public isElectron: boolean;
  public desktopRecording: boolean = false;
  public desktopRecordingAlways: boolean = false;

  public opportunities: Opportunity[];

  public canShare: boolean = false;

  private _canCoFormFillShare: boolean = false;
  public get canCoFormFillShare(): boolean {
    return this._canCoFormFillShare;
  }


  // Private is if it's available. Public getter is if we can do it at the moment.
  // The availability won't change but changes to the room (multicustomer multiparty) makes a feature unavailable.
  public _canSecureShare: boolean = false;
  public get canSecureShare(): boolean {
    return this._canSecureShare && (this.engagement.roomVisitors.size <= 1);
  }

  public screenSize: Size = { width: 0, height: 0};

  public get canAppView(): boolean {
    try {
      return this.engagement.visitor.isMobileSdk;
    } catch (err) {
      return false;
    }
  }


  private endChatModal: DynamicDialogRef;
  private blockCustomerModal: DynamicDialogRef;
  private visitorMissingModal: DynamicDialogRef;

  EngagementState = EngagementState; // export the engagement state for use in the UI
  CallType = CallType;

  public readonly engagement: Engagement;

  public readonly urlObs: BehaviorSubject<string> = new BehaviorSubject<string>("");

  public visitorMessageObs: BehaviorSubject<TextMessage> = new BehaviorSubject<TextMessage>(new TextMessage());

  public callPaused: Observable<boolean>;
  public currentPanelPosition: Observable<CustomerSizes>;

  public isPrimaryAgent: Observable<boolean>;
  public isBlockedOn: Observable<boolean>;
  public isPresentingAgent: Observable<boolean>;
  public isAppView: Observable<boolean>;

  public presentingAgent$: BehaviorSubject<string> = new BehaviorSubject('');
  public primaryAgent$: BehaviorSubject<string> = new BehaviorSubject('');
  public agents: ReadonlyMap<string, EngagementAgent> = new Map([]);
  public allowBrowsing: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public showingModal$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isSharing: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public visitors: ReadonlyMap<string, EngagementVisitor> = new Map([]);

  public get isViewOnly(): boolean {
    return this.sharingControllerService && this.sharingControllerService.isSharingViewOnly;
  }

  public isRecording: Observable<boolean>;
  public currentFeedType: Observable<FeedType>;

  // Number of consecutive missing visitor polls from the engagement hub
  // This is used for determining whether the visitor is actually missing
  // by comparing with MISSED_POLLS_REQ_FOR_MISSING_VISITOR_PROMPT
  private missingCount = 0;
  private readonly visitorMissing$: Subscription;

  public visitorDetails$: BehaviorSubject<VisitorSiteDetails> = new BehaviorSubject<VisitorSiteDetails>(EngagementComponent.EMPTY_VISITOR_DETAILS);
  public visitorSessionDetails$: BehaviorSubject<VisitorSessionDetails> = new BehaviorSubject<VisitorSessionDetails>(EngagementComponent.EMPTY_VISITOR_SESSION_DETAILS);
  public visitorCrmData$: BehaviorSubject<CrmStructure> = new BehaviorSubject<CrmStructure>(new CrmStructure());
  public visitorSessionHistory$: BehaviorSubject<EngagementEvent[]> = new BehaviorSubject<EngagementEvent[]>([]);
  public visitorBrowsingHistory$: BehaviorSubject<BrowsingHistory[]> = new BehaviorSubject<BrowsingHistory[]>([]);
  public emailState: BehaviorSubject<CrmEmailStateType> = new BehaviorSubject<CrmEmailStateType>({ type: CrmEmailState.Valid });

  public domSyncCommand$: Observable<string>;
  public isDomSync$ = new BehaviorSubject<boolean>(false);
  public domSyncOn$ = new BehaviorSubject<boolean>(false);


  public currentState$: BehaviorSubject<EngagementStateType>;
  public fileTransfers$: Observable<IFileUpload[]>;

  public checklistfields: BehaviorSubject<CheckListField[]> = new BehaviorSubject<CheckListField[]>([]);
  public currentSection: Section = Section.Contact;
  public appointment: InsertAppointment;

  private onSubmitClickCount  = 0;


  private sharingControllerService: SharingControllerService;
  private desktopRecordingService: IRecordingService;

  protected translationOn$: Observable<boolean>;
  protected getSuggestionOn$: Observable<boolean>;
  protected autoReplyOn$: Observable<boolean>;

  public isCollapsed = false;
  public privateChatEnabled: BehaviorSubject<boolean>;
  public privateChatMessages: BehaviorSubject<TextMessage[]>;
  public unreadPrivateMessages: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public unreadMessages: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public requestingHelp$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public privateChatAvailable: boolean;


  public readonly isMobileOrTablet$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isMobile: boolean;

  public isBrowserVisible: boolean = false;
  public isInBackground: boolean = false;


  public hideBrowserWhenSharing: boolean = false;

  public readonly showCustomerHasBackgrounded: boolean;

  constructor(private route: ActivatedRoute,
    private logging: LoggingService,
    private router: Router,
    private modalService: ModalService,
    private visitorService: VisitorService,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private onlineService: OnlineService,
    private engagementService: EngagementService,
    private fileTransferService: FileTransferService,
    private notificationService: NotificationService,
    private webrtcMessagingService: WebrtRTCMessagingService,
    private authService: AuthService,
    private crmService: CrmService,
    private emailService: EmailService,
    private featureService: FeatureService,
    private browserService: BrowserService,
    public settingsService: SettingsService,
    private versionService: VersionService,
    private electronService: ElectronService,
    private translate: TranslatePipe


  ) {

    this.onlineService.setCurrentState(OnlineState.Engaged);
    this.engagementId = this.route.snapshot.params['engagementId'];
    this.engagement = this.engagementService.getEngagement(this.engagementId);

    if (!this.engagement) {
      this.alertService.addAlert(this.translate.transform("ENGAGEMENT_ALERT_DANGER", "Don't do that!!"), AlertType.Danger);
      this.router.navigateByUrl('/veestudio');
      return;
    }

    this.engagement.currentState.subscribe(state => {
      this.loadingService.isLoading.next(false);

      switch (state.type) {
        case EngagementState.Ended:
          this.engagementService.endEngagement(this.engagementId);
          this.engagementClosed();
          break;
        case EngagementState.Post:
          this.onlineService.setCurrentState(OnlineState.PostEngagement);
          this.router.navigateByUrl(`/veestudio/engagement/${this.engagementId.toString()}/post`);
          break;
      }
    });


    // todo: tidy up these subs and observables
    this.engagement.visitorTypingMessage.pipe(distinctUntilChanged()).subscribe(n => {
      this.visitorMessageObs.next(n);
    });

    this.callPaused = this.engagement.callPaused.pipe(distinctUntilChanged());
    this.currentFeedType = this.engagement.currentFeedType$.pipe(distinctUntilChanged());

    this.isPrimaryAgent = this.engagement.isPrimary;
    this.isBlockedOn = this.engagement.blockedOn$;
    this.isPresentingAgent = this.engagement.isPresenter;
    this.presentingAgent$ = this.engagement.presentingAgent;
    this.primaryAgent$ = this.engagement.primaryAgent;
    this.agents = this.engagement.roomAgents;
    this.visitors = this.engagement.roomVisitors;
    this.domSyncCommand$ = this.engagement.domSyncCommand$;
    this.subscriptions.push(this.engagement.domSyncOn$.subscribe(v => this.domSyncOn$.next(v)));

    this.subscriptions.push(this.engagement.isDomSync$.pipe(distinctUntilChanged()).subscribe((v) =>{
        this.isDomSync$.next(v);
    }));

    this.currentPanelPosition = this.engagement.panelPositionAndSize.pipe(shareReplay(1));

    this.visitorMissing$ = this.engagement.visitorMissing.subscribe(missing => this.handleVisitorMissing(missing));

    // trigger a notification for a new message
    this.engagement.newMessage.subscribe(({privateMessage, message}) => this.showMsgNotification(privateMessage, message));

    this.subscriptions.push(
      this.engagement.visitorEnded.subscribe(() => this.loadingService.isLoading.next(true))
    );

    this.fileTransfers$ = this.engagement.fileTransfers$.pipe(map(transfers => Array.from(transfers.values())));

    this.engagement.initialise().subscribe(success => {
      if (!success) {
        // If we fail to connect then go to post and display a warning
        // that makes more sense, plus we can then do something different in the UI
        this.alertService.addAlert(this.translate.transform("CALLLIST_ALERT_CONNECTIONERROR",`Unable to connect to engagement. Please check your internet connection.`), AlertType.Danger);
        this.endChat();
      } else {
        this.onConnected();

        if (this.engagement.visitor.callType === CallType.TextDowngrade) {
          this.alertService.addAlert(this.translate.transform('ENGAGEMENT_VEESTUDIO_DOWNGRADE_TO_TEXT', 'Downgraded engagement to text'), AlertType.Danger, 9e9);
        }

        this.engagement.visitor.details.pipe(first(), map(details => details && details.isMobile === "1")).subscribe((onMobile) => {
          if (onMobile) {
            this.alertService.addAlert(this.translate.transform("ENGAGEMENT_VEESTUDIO_CUSTOMER_ON_MOBILE", `Customer is using mobile device`), AlertType.Info);
          }
        });
      }
    });

    this.showCustomerHasBackgrounded = true;

    this.translationOn$ = this.engagement.translationOn$;

    this.isAppView = this.engagement.isAppView.pipe(distinctUntilChanged());

    this.subscriptions.push(this.engagement.isBrowserVisible.pipe(distinctUntilChanged()).subscribe(n => this.isBrowserVisible = n));

    this.subscriptions.push(this.engagement.isInBackground.pipe(distinctUntilChanged()).subscribe(n => this.isInBackground = n));

    this.subscriptions.push(this.engagement.screenSize.pipe(distinctUntilChanged()).subscribe(n => this.screenSize = n));


    const browser = this.browserService.getBrowser(this.engagementId);
    if (browser) {
      browser.currentPage.pipe(distinctUntilChanged()).subscribe(url => this.urlObs.next(url));
    } else {
      this.engagement.currentPage.pipe(distinctUntilChanged()).subscribe(url => this.urlObs.next(url));
    }


    this.subscriptions.push(
      this.engagement.panelSize.pipe(distinctUntilChanged()).subscribe((size: number) => {
        this.engagement.setVideoSize(size);
      }));


    this.subscriptions.push(
      combineLatest([this.engagement.panelFullSize, this.engagement.engagementMode])
        .pipe(distinctUntilChanged((a, b) => (a[0] === b[0] && a[1] === b[1])))
        .subscribe(([isFullSize, engagementMode]) => {

          if (isFullSize) {
            if (engagementMode === EngagementMode.AV) {
              this.engagement.setVideoSize(PanelSize.Big);
            }
            else {
              this.engagement.setVideoSize(PanelSize.Small);
            }

          }
          else {
            this.engagement.setVideoSize(this.engagement.panelSize.getValue())
          }
        }));

  }

  ngOnInit() {

    this.useCoBrowsing = this.featureService.has(Features.COBROWSE);
    this.isElectron = this.versionService.isElectron;
    this.desktopRecording = this.featureService.has(Features.DESKTOP_RECORDING_TOGGLE);
    this.desktopRecordingAlways = this.featureService.has(Features.DESKTOP_RECORDING_ALWAYS);

    this._canSecureShare = this.engagement.isPrimaryAgent && this.featureService.has(Features.SECURE_SHARE);
    this.canShare = this.engagement.visitor.actualCallType(this.visitorService.agentLicenceType) === CallType.WebRTC;
    this._canCoFormFillShare = this.isElectron && this.canShare;

    this.currentState$ = this.engagement.currentState;
    this.username = this.engagement.username;
    this.visitorDetails$.next(this.createDetails(this.engagement.visitor));

    this.engagement.visitor.details.subscribe(deets => {
      if (deets != null) {
        this.visitorSessionDetails$.next(this.createSessionDetails(deets));
      }
    });

    this.engagement.visitor.crmData.subscribe(data => {
      if (data != null) {
        this.visitorCrmData$.next(data);
        this.emailState.next(CrmEmail.isValidEmail(this.visitorCrmData$.value));
      }
    });

    this.engagement.visitor.sessionHistory.subscribe(history => {
      if (history != null) {
        this.visitorSessionHistory$.next(history);
      }
    });

    this.engagement.visitor.browsingHistory.subscribe(history => {
      if (history != null) {
        this.visitorBrowsingHistory$.next(history);
      }
    });

    const clientSub = this.engagement.webrtcMessages.subscribe((msg) => {
      this.webrtcMessagingService.sendMessageToClient(msg);
    });
    this.subscriptions.push(clientSub);

    const serverSub = this.webrtcMessagingService.serverMessages$.subscribe((msg) => {
      this.engagement.sendWebrtcMessage(msg);
    });
    this.subscriptions.push(serverSub);

    const engagementEndedSub = this.engagement.engagementEnded.subscribe((ended) => {

      if (ended) {
        this.removeSharing();

        if (this.desktopRecordingService) {
          this.desktopRecordingService.stopRecording();
        }

        this.alertService.clearAlerts();
        this.removeVisitorMissingModal();
        this.cancelEndChat();
      }
    });

    this.subscriptions.push(engagementEndedSub);

    this.engagement.onPrimaryCustomerChanged$.subscribe(newGuid => {
      if (this.IsCRMOpportunityAvailable()) {
        this.crmService.getOpportunitiesForContact(newGuid).pipe(take(1)).subscribe(opps => {
          this.opportunities = opps;
        });
      }
    });

    this.getCheckListFields('Default');

    this.subscriptions.push(this.engagement.visitorJoined.subscribe(() => {
      this.logging.info(`Visitor joined. Current visitors: ${JSON.stringify([...this.engagement.roomVisitors.entries()])}`);

      if (this.engagement.roomVisitors.size > 1) {
        this.engagement.showPage(this.engagement.currentPage.getValue() || this.urlObs.getValue(), true);
        this.onSwitchCoBrowseOn(true);

        switch (this.engagement.communicationMode.getValue()) {
          case CommunicationMode.OFF:
            this.engagement.switchToTextMode();
            break;
          case CommunicationMode.TWOWAYAUDIO_NOVIDEO:
            this.engagement.switchToAudioMode();
            break;
          case CommunicationMode.TWOWAYAUDIO_VIDEO:
            this.engagement.switchToVideoMode();
            break;
        }

        const message = this.translate.transform('INTIMATE_VISITOR_JOINED', 'Visitor joined.');
        this.engagement.addInternalMessage(message);
        this.alertService.playVisitorJoinedSound();
      }
    }));

    this.subscriptions.push(this.engagement.visitorLeft.subscribe(() => {
      if (this.engagement.roomVisitors.size > 0) {
        const message = this.translate.transform('INTIMATE_VISITOR_LEFT', 'Visitor left.');
        this.engagement.addInternalMessage(message);
      }
    }));

    this.privateChatEnabled = this.engagement.privateChatEnabled;
    this.getSuggestionOn$ = this.engagement.agentAssistBotGetSuggestions;
    this.autoReplyOn$ = this.engagement.agentAssistBotAutosendMessage;
    this.privateChatMessages = this.engagement.privateMessages;
    this.unreadPrivateMessages = this.engagement.unreadPrivateMessages;
    this.unreadMessages = this.engagement.unreadMessages;
    this.requestingHelp$ = this.engagement.requestingHelp$;
    this.privateChatAvailable = this.featureService.has(Features.PRIVATE_CHAT);

    if (this.desktopRecording || this.desktopRecordingAlways) {
      this.desktopRecordingService = new DesktopRecordingService(this.electronService, this.settingsService);
      this.desktopRecordingService.setupRecording(this.engagementId, this.username);
      this.isRecording = this.desktopRecordingService.recordingState$.pipe(distinctUntilChanged());
    }

    this.subscriptions.push(this.engagement.visitorIsSharing
      .pipe(distinctUntilChanged())
      .subscribe((visitorIsSharing) => {
        this.isSharing.next(visitorIsSharing);
      }));
  }

  private setupSharing() {
    this.sharingControllerService = new SharingControllerService(this.engagementService, this.webrtcMessagingService, this.logging, this.electronService);

    const sharingConfig: SharingConfig = new SharingConfig();
    sharingConfig.shareWebpage = true;
    sharingConfig.shareWebpageFullscreen = this.isElectron && sharingConfig.shareWebpage && this.featureService.has(Features.SHARE_WEBPAGE_FULLSCREEN);
    sharingConfig.shareApplication = true;
    sharingConfig.shareDesktop = true;
    sharingConfig.isElectron = this.isElectron;
    sharingConfig.cropTarget = !this.isElectron && this.featureService.has(Features.SHARE_WEBPAGE_CROPPED);

    const sharingStateSub = this.sharingControllerService.setup(this.engagementId, sharingConfig).subscribe(sharingMessage => {

      if (sharingMessage.on) {
        if (this.isDomSync$.getValue()) {
          this.onSwitchDomSyncingOn(false);
        }

        //If we have a sourcename and that sourcename is not the co-browse browser then hide it
        //We should improve this functionality so that it does not rely on the browser name and instead we pass the type
        this.hideBrowserWhenSharing = (!this.isElectron && !sharingConfig.cropTarget) || (this.isElectron && sharingMessage.sourceName !== 'Co-Browse');
      }
      else {
        this.hideBrowserWhenSharing = false;
        sharingMessage.message = this.urlObs.getValue();
      }

      this.engagement.setSharingOn(sharingMessage.on, sharingMessage.message);
    });

    this.subscriptions.push(sharingStateSub);
  }

  IsCRMOpportunityAvailable(): boolean {
    return this.authService.currentAgent.value !== null;
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    if (this.desktopRecordingService) {
      this.desktopRecordingService.dispose();
      this.desktopRecordingService = null;
    }
  }

  private pauseSharing(val: boolean) {
    this.sharingControllerService?.pauseSharing(val);
  }

  private onConnected() {
    if (this.featureService.has(Features.TRANSLATOR)) {
      this.engagement.setTranslationEnabled(true);
    }

    if (this.desktopRecordingService && this.desktopRecordingAlways) {
      this.desktopRecordingService.startRecording();
    }

    this.subscriptions.push(
      this.modalService.showingModal$.pipe(distinctUntilChanged()).subscribe((isShown: boolean) => {
        this.pauseSharing(isShown);
        this.showingModal$.next(isShown);
      })
    );

    this.subscriptions.push(
    this.engagement.allowBrowsing
    .pipe(
      filter(() => !this.isSharing.getValue()),
      distinctUntilChanged()
    )
    .subscribe(allowed => {

      if(allowed && this.engagement.currentPage.getValue() != this.urlObs.getValue()) {
        this.engagement.showPage(this.urlObs.getValue());
      }

      this.allowBrowsing.next(allowed);
    }));

    this.setupSharing();
  }

  private showMsgNotification(privateMessage: boolean, message: TextMessage) {
    if (message) {
      if (privateMessage) {
        this.alertService.playNewPrivateMessageSound();
      } else {
        this.alertService.playNewMessageSound();
      }

      const title = 'New Message';
      const options = { body: message.message, icon: '../../assets/images/veestudio-icon.png', tag: 'visitorMessage', silent: true };
      this.notificationService.create(title, options);
    }
  }

  private handleVisitorMissing(missing: boolean) {

    // We are displaying a different modal so ignore
    if (this.endChatModal) {
      this.missingCount = 0;
      return;
    }

    if (missing) {
      if (this.visitorMissingModal) {
        return;
      }

      if (++this.missingCount > EngagementComponent.MISSED_POLLS_REQ_FOR_MISSING_VISITOR_PROMPT) {
        this.modalService.closeAllOpenModals();
        this.logging.debug('Visitor is missing');
        const data = {
          end: () => {
            this.removeVisitorMissingModal();
            this.endChat();
          },
          wait: () => this.removeVisitorMissingModal(),
        };

        this.visitorMissingModal = this.modalService.openModal(MissingVisitorModalContentComponent, {
          data,
          closeOnEscape: false,
          showHeader: true,
          closable: false,
          header: this.translate.transform('VISITORMODAL_HEADER_WAIT', 'Wait for customer?'),
          contentStyle: { width: '500px' }
        });
      }
    } else {
      this.logging.debug('Visitor no longer missing');
      this.removeVisitorMissingModal();
    }
  }

  private removeVisitorMissingModal() {
    if (this.visitorMissingModal) {
      this.modalService.closeModal(this.visitorMissingModal);
      this.visitorMissingModal = null;
    }
    this.missingCount = 0;
  }

  protected showEndChatModal() {
    // If the room is locked we ignore this method call
    if (this.engagement.roomLocked) {
      return;
    }

    const shouldEndChat: boolean = this.engagement.isPrimaryAgent();

    const endChatAgents: EndChatAgent[] = [];
    for (const [agentUsername, agent] of this.agents) {
      if (this.engagement.username !== agentUsername) {
        endChatAgents.push({
          username: agentUsername,
          nickname: agent.nickname,
        });
      }
    }

    const data = {
      agents: endChatAgents,
      endChatType: shouldEndChat ? EndType.END_CHAT : EndType.LEAVE_CHAT,
      onEndChat: (newUsername: string) => {

        if (shouldEndChat) {
          this.engagement.unlockRoom();

          if (newUsername.length > 0) {
            this.transferToNewPrimaryAndLeave(newUsername);
          } else {
            this.endChat();
          }
        } else {
          this.leaveChat();
        }
      },
      cancelEndChat: () => {

        if (shouldEndChat) {
          this.engagement.unlockRoom();
        }
        this.cancelEndChat();
      }
    };
    this.endChatModal = this.modalService.openModal(EndChatModalComponent, {
      data,
      closeOnEscape: false,
      showHeader: true,
      closable: false,
      header: shouldEndChat ? this.translate.transform('ENDCHATMODAL_HEADER_ENDCHAT', 'Are you sure you want to end this chat?') : this.translate.transform('ENDCHATMODAL_HEADER_LEAVECHAT', 'Are you sure you want to leave this chat?'),
      contentStyle: {width: '500px'}
    });

    if (shouldEndChat) {
      this.engagement.lockRoom();
    }
  }

  public showBlockCustomerModal() {
    const engagement = this.engagement;
    const sub = engagement.engagementEnded.subscribe((ended) => {
      if (ended) {
        sub.unsubscribe();
        this.cancelBlockCustomer();
      }
    });

    const data = {
      onBlockCustomer: (reason: string) => {
        sub.unsubscribe();
        this.onBlockCustomer(engagement, reason);

      },
      cancelBlockCustomer: () => {
        sub.unsubscribe();
        this.cancelBlockCustomer();
      }
    };

    this.blockCustomerModal = this.modalService.openModal(BlockCustomerModalComponent, {
      data,
      closeOnEscape: false,
      showHeader: true,
      closable: false,
      header: this.translate.transform('BLOCKCUSTOMERMODAL_HEADER_BLOCKUSER', 'Are you sure you want to block customer?'),
      contentStyle: {width: '500px'}
    });

  }

  public onBlockCustomer(engagement: Engagement, reason: string) {
    if (this.blockCustomerModal) {
      this.modalService.closeModal(this.blockCustomerModal);
      this.blockCustomerModal = null;
    }
    let ip = "0.0.0.0";
    engagement.visitor.details.subscribe(x => ip = x.IPAddress || "0.0.0.0");
    this.crmService
      .blockCustomer(
        engagement.visitor.userGuid,
        ip,
        reason.length > 0 ? reason : "Default"
      )
      .subscribe((data) => {
        if (!data) {
          this.alertService.addAlert(
            this.translate.transform(
              "BLOCKCUSTOMER_ALERT_ERROR",
              "Error when calling the user blocking method."
            ),
            AlertType.Danger
          );
        }
        engagement.setBlockedOn();
        if (!engagement.engagementEnded.value) {
          this.endChat();
        }
      });
  }

  private transferToNewPrimaryAndLeave(newUsername: string) {
    // Only change the presenter if it is us
    if (this.engagement.isPresentingAgent()) {
      this.engagement.changePresenter(newUsername).subscribe();
    }

    this.engagement.changePrimary(newUsername).subscribe(
      () => this.leaveChat(),
      () => { }
    );
  }

  private leaveChat() {
    this.logging.debug('Leaving chat');
    if (this.endChatModal) {
      this.modalService.closeModal(this.endChatModal);
      this.endChatModal = null;
    }

    this.engagement.leaveChat();
  }

  private endChat() {
    // make the CRM tab active

    this.getCheckListFields('Default');
    this.logging.debug('Ending chat');

    this.visitorMissing$.unsubscribe();
    this.removeVisitorMissingModal();

    if (this.endChatModal) {
      this.modalService.closeModal(this.endChatModal);
      this.endChatModal = null;
    }

    this.loadingService.isLoading.next(true);

    this.engagement.endChat()
                  .catch(() => {})
                  .finally(() => {
                    this.loadingService.isLoading.next(false);
                  });
  }

  public cancelEndChat() {
    if (this.endChatModal) {
      this.modalService.closeModal(this.endChatModal);
      this.endChatModal = null;
    }
  }
  public cancelBlockCustomer() {
    if (this.blockCustomerModal) {
      this.modalService.closeModal(this.blockCustomerModal);
      this.blockCustomerModal = null;
    }
  }

  private removeSharing() {
    this.isSharing.next(false);

    // The sharing service is set up in onConnected.
    // Failing to connect will try and call removingSharing on null.
    if (this.sharingControllerService) {
      this.sharingControllerService.removeSharing();
    }
  }

  onSendMessage($event: OutboundMessage) {
      //always send html
      this.engagement.sendChatMessage($event.formattedMessage.message);
  }

  onSwitchSharingMode($event: boolean, viewOnly: boolean = true) {
    this.sharingControllerService.switchSharingOn($event, viewOnly);
  }

  switchedToCrm() {
    // If we are currently sharing with customer control (co-form fill) then
    // disable customer control when we move to the CRM tab.
    if (this.isElectron
        && this.sharingControllerService.isWebRTCSharing
        && !this.sharingControllerService.isSharingViewOnly) {
      this.sharingControllerService.switchSharingOn(true, true);
    }
  }

  onSwitchAppView($event: boolean) {
    if (this.isSharing.getValue()) {
      this.sharingControllerService.switchSharingOn(false, false);
    }

    if (this.domSyncOn$.getValue()) {
      this.onSwitchDomSyncingOn(false);
    }

    this.engagement.toggleAppView($event);
  }

  onSwitchCoBrowseOn($event: boolean) {
    if (this.isSharing.getValue()) {
        this.sharingControllerService.switchSharingOn(false, false);
    }

    if (this.domSyncOn$.getValue()) {
        this.onSwitchDomSyncingOn(false);
    }

    this.engagement.switchCoBrowseOn($event);
    this.engagement.showPage(this.urlObs.getValue(), true);

  }

  onSwitchDomSyncingOn($event: boolean) {
    if (this.isSharing.getValue()) {
      this.sharingControllerService.switchSharingOn( false, false);
    }

    if ($event) {

      // Don't send message to the customer to turn ON DomSync here.
      // Send this message, once the frame is loaded (onDomSyncAgentReady)

      // //responsible for setting the SyncDom room state
      // //once that is set domsyncCreated obsv is true which will create the domsync viewer
      // //dom sync viewer will load the dom sync iframe and send onReady event
      // //on ready event sends 82 message to customer


      this.engagement.startDomSync(true);

    }
    else {
      this.resetDomSync();
    }
  }

  private resetDomSync() {
    this.engagement.startDomSync(false);
    this.engagement.setDomSyncOn(false);
  }

  onDomSyncAgentReady() {
    this.engagement.setDomSyncOn(true);
  }

  onDomSyncToCustomerCommand($event) {
    this.engagement.sendDomSyncCommand($event);
  }

  onPauseCall() {
    this.engagement.togglePauseCall();
  }

  onRecordingToggle() {
    if (this.desktopRecordingService) {
      this.desktopRecordingService.toggle();
    }
  }

  isProceedOnChecklistFailed(postStatus: PostEngagementStatus) {
    if (this.endChatModal) {
      this.modalService.closeModal(this.endChatModal);
      this.endChatModal = null;
    }

    const data = {
      endChatType: EndType.END_ON_CHECKLIST_FAIL,
      onEndChat: () => {
        if (this.endChatModal) {
          this.modalService.closeModal(this.endChatModal);
          this.endChatModal = null;
        }
        this.submit(postStatus);
      },
      cancelEndChat: () => {
        if (this.checklistfields.value.some(field => field.IsRulePassed === false && !field.CRMField.startsWith('Custom Task:'))) {
          this.currentSection = Section.Tasks;
        }
        if (this.endChatModal) {
          this.modalService.closeModal(this.endChatModal);
          this.endChatModal = null;
        }
      }
    };

    this.endChatModal = this.modalService.openModal(EndChatModalComponent, {
      data,
      closeOnEscape: false,
      showHeader: true,
      closable: false,
      header: this.translate.transform('ENDCHATMODAL_HEADER_PROCEEDCHAT', 'Not all recommended fields have been entered. Are you sure you want to proceed?'),
      contentStyle: { width: '500px' }
    });
  }

  postFinished(postStatus: PostEngagementStatus) {
    this.appointment = this.crmService.isValidAppointment(this.appointment);
    if (this.appointment.validationError !== ``) {
      this.alertService.addAlert(this.appointment.validationError, AlertType.Danger);
      return;
    }

    if(this.checklistfields.value.length === 0) {
      this.submit(postStatus);
      return;
    }

    this.onSubmitClickCount++;

    if (this.hasMandatoryFieldNotPassed()) {
      this.currentSection = Section.Tasks;
      this.alertService.addAlert(this.translate.transform("ENGAGEMENTOPPORTUNITY_ALERT_VALIDATION",`Not all required fields have been entered.`), AlertType.Danger);
      return;
    }

    if (this.hasFailedRuleAndSubmittedMultipleTimes()) {
      this.isProceedOnChecklistFailed(postStatus);
      return;
    }

    if (this.hasFailedRule()) {
      this.currentSection = Section.Tasks;
      this.alertService.addAlert(this.translate.transform("ENGAGEMENT_ALERT_FIELDVALIDATION",`Not all fields have been entered.`), AlertType.Danger);
      return;
    }

    this.submit(postStatus);
  }

  private hasMandatoryFieldNotPassed(): boolean {
    return this.checklistfields.value.some(field => field.IsCustomTask === false && field.IsRulePassed == false && field.CheckType === ChecklistFieldType.MANDATORY);
  }

  private hasFailedRuleAndSubmittedMultipleTimes(): boolean {
    return this.checklistfields.value.some(field => field.IsCustomTask === false && field.IsRulePassed === false) && this.onSubmitClickCount > 1;
  }

  private hasFailedRule(): boolean {
    return this.checklistfields.value.some(field => field.IsCustomTask === false && field.IsRulePassed === false);
  }


  submit(postStatus: PostEngagementStatus) {
    this.emailService.submitMessages(this.engagementId, this.engagement.visitor.userGuid).subscribe();

    this.visitorService.qualifyEngagement(this.engagementId, postStatus.status, postStatus.substatus, postStatus.notes, this.appointment).subscribe(
      response => {
        if (response.success) {
          this.engagementClosed();
        } else {
          this.alertService.addAlert(response.message, AlertType.Danger);
        }
      },
      error => {
        this.logging.error(`Unable to submit post.`, error);

        if (this.visitorService.noInternet.getValue()) {
          this.engagementClosed();
          const alert = this.translate.transform("ENGAGEMENT_POSTENGAGEMENT_NO_INTERNET", 'Engagement removed due to no internet. Post engagement not saved.');
          this.alertService.addAlert(alert, AlertType.Danger);
        } else {
          const alert = this.translate.transform("ENGAGEMENT_POSTENGAGEMENT_TIMEOUT", 'Currently unable to save engagement.');
          this.alertService.addAlert(alert, AlertType.Danger);
        }
      }
    );
  }

  private engagementClosed() {
    this.onlineService.setCurrentState(OnlineState.Available);
    this.router.navigateByUrl('/veestudio');
  }

  transferRequest($event: any) {
    this.engagement.sendInviteRequest($event);

    const sub = this.engagement.currentState.subscribe(newState => {
      switch (newState.type) {
        case EngagementState.Transfer:
          {
            switch (newState.inviteState) {
              case CallInviteState.InviteSelecting:
                sub.unsubscribe();
                break;
            }
          }
          break;
        default:
          break;
      }
    });
  }

  cancelTransfer() {
    this.engagement.cancelTransfer();
  }

  selectTransferringOperator() {
    this.engagement.selectTransferringOperator();
  }

  selectJoiningOperator() {
    this.engagement.selectJoiningOperator();
  }

  uploadFiles(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      const result = this.fileTransferService.validateFile(files[i]);
      switch (result.type) {
        default:
        case ValidateFileType.FileExtensionInvalid:
          this.alertService.addAlert(files[i].name + ' has an invalid file extension', AlertType.Danger);
          break;
        case ValidateFileType.InvalidOther:
          this.alertService.addAlert(files[i].name + ' is invalid', AlertType.Danger);
          break;
        case ValidateFileType.FileTooLarge:
          this.alertService.addAlert(files[i].name + ' is too large', AlertType.Danger);
          break;
        case ValidateFileType.Valid:
          break;
      }
      this.engagement.uploadFile(files[i]);
    }
  }

  cancelFileTransfer(file: IFileUpload) {
    this.engagement.removeFileUpload(file);
  }

  joinRequest($event) {
    this.engagement.sendInviteRequest($event);
  }

  customerJoinRequest($event: CustomerInvite) {
    this.engagement.customerJoinRequest($event);
  }

  cancelJoin() {
    this.engagement.cancelJoin();
  }

  changePresenter(agentUsername: string) {
    this.engagement.changePresenter(agentUsername);
  }

  kickAgent(agentUsername: string) {
    this.engagement.kickAgent(agentUsername);
  }

  private createDetails(visitor: Visitor): VisitorSiteDetails {
    return {
      section: visitor.lastSection,
      prevOp: visitor.previousOperator,
      location: visitor.location,
      useragent: visitor.browser,
      page: visitor.lastPage,
    };
  }

  private createSessionDetails(details: VisitorDetails): VisitorSessionDetails {

    const parsedDetails = {
      organisation: details.Org,
      ip: details.IPAddress,
      referrer: details.Referrer,
      deviceScaleFactor: details.DevicePixelRatio,
      isMobile: details.isMobile === "1",
      isTablet: details.isTablet === "1",
      clientWidth: details.ClientWidth,
      clientHeight: details.ClientHeight
    };

    this.isMobileOrTablet$.next((parsedDetails.isMobile || parsedDetails.isTablet));
    this.isMobile = parsedDetails.isMobile;
    return parsedDetails;
  }

  public selectAsset(asset: EngagementAsset) {
    // Only push assets for the presenting agent
    if (this.engagement.isPresentingAgent()) {

      const browser = this.browserService.getBrowser(this.engagementId);
      browser.changeUrl(asset.Url);

      if(asset.IsShared && this.canShare) {
        if(!this.isElectron) {
          this.openNewTab(this.urlObs.getValue());
        }
        this.sharingControllerService.shareAsset(true);
      }
      else {
        this.sharingControllerService.shareAsset(false);
      }
    }
  }

  openNewTab(value:string) {
    window.open(value, 'asset');
  }

  saveCrmData() {
    this.emailState.next(CrmEmail.isValidEmail(this.visitorCrmData$.value));
    this.engagement.setCrmData(this.visitorCrmData$.value);
    this.validateCheckList();
  }

  saveOpportunity(opp: Opportunity) {
    this.crmService.insertUpdateOpportunity(this.engagement.visitor.userGuid, opp.opportunityTitle, opp.opportunityProduct, opp.opportunityNotes,
      opp.opportunityStatus.toString(), opp.opportunityValue, opp.opportunityId.toString()).subscribe(
      res => {
        if (res) {
         this.alertService.addAlert(this.translate.transform("ENGAGEMENTOPPORTUNITY_ALERT_SUCCESS",'Opportunity Saved'), AlertType.Success);

         this.crmService.getOpportunitiesForContact(this.engagement.visitor.userGuid).pipe(take(1)).subscribe(opps => {
            this.opportunities = opps;
          });
       }
    },
    err => {
      this.alertService.addAlert(this.translate.transform("ENGAGEMENTOPPORTUNITY_ALERT_FAILEDOPPORTUNITY",'Failed to save opportunity'), AlertType.Danger);
    });
  }

  getCheckListFields(checklistName: string) {
    this.crmService.getChecklistFields(checklistName).subscribe(checklist => {
      if (checklist == null) {
        this.checklistfields.next([]);
      } else {
        this.checklistfields.next(checklist);
        this.validateCheckList();
      }
    });
  }

  validateCheckList() {
    this.crmService.validateChecklist(this.checklistfields.value, this.visitorCrmData$.value);
  }

  public setTranslation(enabled: boolean) {
    this.engagement.setTranslationEnabled(enabled);
  }

  public setGetSuggestion(enabled: boolean) {
    this.engagement.setGetSuggestionEnabled(enabled);
  }

  public setAutoReply(enabled: boolean) {
    this.engagement.setAutoReplyEnabled(enabled);
  }

  sendPrivateChatMessage(privateMessage: string) {
    this.engagement.sendPrivateChatMessage(privateMessage);
  }

  toggleHelpRequest() {
    this.engagement.toggleHelpRequest();
  }

  loadCustomer(customer: MatchedCustomer) {
    const currentUserId = this.engagement.visitor.userGuid;
    this.engagement.switchCustomer(currentUserId, customer.customerGuid);
  }

  agentTextChange(agentText: string): void {
    this.engagement.agentTextChange(agentText);
  }

  toggleCobrowseBrowser() {
    if (this.isBrowserVisible) {
      this.engagement.removeBrowser();
    } else {
      this.engagement.showPage(this.urlObs.value);
    }
  }

  savePost($event: PostEngagementStatus) {
    this.visitorService.savePostStatus(this.engagement.engagementId.toString(), this.engagement.visitor.userGuid, this.engagement.visitor.sessionGuid, $event);
  }
}
