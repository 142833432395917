<div *ngIf='!crmInformationView' class="h-100">
  <div class=" d-flex flex-column flex-grow-1 h-100">
    <div class="d-flex flex-column flex-grow-1 overflow-hidden">
      <h2 class="primary-color-text pt-2 pl-2">{{ "APPOINTMENTSEARCH_HEADER" | Translate : 'Appointments' }}</h2>
      <p-tabMenu (activeItemChange)="onTabChange($event)" [activeItem]="activeItem" [model]="menu"
                 styleClass="justify-end"></p-tabMenu>
      <p-toolbar *ngIf="!agentsCalendarView"
                 styleClass="flex-nowrap">
        <div class="p-toolbar-group-start gap-4 align-items-center w-100 container-fluid">
          <ng-container *ngIf="activeItem === menu[2]">
            <label for="start">{{ "APPOINTMENTSEARCH_LABEL_UNAVAILABLESTART" | Translate : 'Start:' }}
              <div class="d-flex flex-row align-items-center gap-2">
                <input [(ngModel)]="this.agentAppointment.startDate" id="start" pInputText type="date">
                <input [(ngModel)]="this.agentAppointment.startTime" pInputText type="time">
                <label class="control-label">{{ timeZone }}</label>
              </div>
            </label>
            <label for="end">{{ "APPOINTMENTSEARCH_LABEL_UNAVAILABLEEND" | Translate : 'End:' }}
              <div class="d-flex flex-row align-items-center gap-2">
                <input [(ngModel)]="this.agentAppointment.endDate" id="end" pInputText type="date">
                <input [(ngModel)]="this.agentAppointment.endTime" pInputText type="time">
                <label class="control-label">{{ timeZone }}</label>
              </div>
            </label>

            <label>{{ "APPOINTMENTSEARCH_LABEL_DESCRIPTION" | Translate : 'Description:' }}
              <textarea [(ngModel)]="this.agentAppointment.subject" class="w-100" pInputTextarea></textarea></label>
          </ng-container>
          <ng-container *ngIf="activeItem !== menu[2] && !crmInformationView">

            <div class="row w-100">
              <div class="col-2 form-item">
                <!-- Appointment Type Column -->
                <label
                  for="appointmenttype">{{ "APPOINTMENTSEARCH_LABEL_APPOINTMENTTYPE" | Translate : 'Appointment Type' }} </label>
                <p-dropdown (onChange)="onChangeAppointmentType($event)"
                            [(ngModel)]="this.appointmentFilterParameters.appointmentTypeId" [filter]='true'

                            [options]="(appointmentTypes)" [resetFilterOnHide]="true" [showClear]="false"
                            id="appointmenttype" optionLabel="appointmentType" styleClass="w-100"
                            optionValue="appointmentTypeId"></p-dropdown>


              </div>
              <div class="col-2  form-item">
                <!-- Call Type Column -->
                <label for="calltype">{{ "APPOINTMENTSEARCH_LABEL_CALLTYPE" | Translate : 'Call Type' }} </label>
                <p-dropdown (onChange)="onCallTypeChange($event)"
                            [(ngModel)]="this.appointmentFilterParameters.callType"
                            [filter]='true' [options]="(sessionTypes)" [resetFilterOnHide]="true" [showClear]="false"
                            id="calltype" optionLabel="Label" optionValue="Label"></p-dropdown>


              </div>
              <div class="col-2 form-item">
                <!-- Status Column -->
                <label for="status">{{ "APPOINTMENTSEARCH_LABEL_STATUS" | Translate : 'Status' }} </label>
                <p-dropdown (onChange)="onStatusChange($event)" [(ngModel)]="this.appointmentFilterParameters.status"
                            [filter]='true' [options]="(appointmentStatuses)" [resetFilterOnHide]="true"
                            [showClear]="false" id="status" optionLabel="status"
                            optionValue="value"></p-dropdown>


              </div>
              <div class="col-2 form-item">

                <label for="agentfilter">{{ "APPOINTMENTSEARCH_LABEL_AGENT" | Translate : 'Agent' }} </label>
                <p-multiSelect (onChange)="onChangeAgent($event)" (onClear)="search()"
                               [(ngModel)]="this.appointmentFilterParameters.agents"
                               [options]="agentLists" [showClear]="true" autocomplete="true"
                               id="agentfilter" optionLabel="realname" optionValue="username"
                               placeholder="Select Agents"
                               resetFilterOnHide="true"></p-multiSelect>


              </div>
              <div class="col-2 form-item">
                <!-- From Date Column -->
                <label for="from">{{ "APPOINTMENTSEARCH_LABEL_FROM" | Translate : 'From' }} </label>
                <input (input)="onChangeStartDate($event)" [(ngModel)]="this.appointmentFilterParameters.from"
                       id="from" pInputText
                       type="date" value="cFrom">


              </div>
              <div class="col-2 form-item">
                <!-- To Date Column -->
                <label for="to">{{ "APPOINTMENTSEARCH_LABEL_TO" | Translate : 'To' }}</label>
                <input (input)="onChangeEndDate($event)" [(ngModel)]="this.appointmentFilterParameters.to"
                       id="to" pInputText
                       type="date" value="cTo">
              </div>


            </div>


          </ng-container>
        </div>
        <div *ngIf="activeItem === menu[2]" class="p-toolbar-group-end gap-1 align-items-center">
          <p-button (onClick)="onSaveAgentAppointment()"
                    pTooltip='{{ "APPOINTMENTSEARCH_LABEL_SAVEAPPOINTMENT" | Translate : "Save Appointment" }}'
                    styleClass="center-icon btn-rectangle p-button-success">
            <fa-icon [icon]="faSave"></fa-icon>
          </p-button>
          <p-button (onClick)="onCancelAgentAppointment()"
                    pTooltip='{{ "APPOINTMENTSEARCH_LABEL_CANCELAPPOINTMENT" | Translate : "Cancel Appointment" }}'
                    styleClass="center-icon btn-rectangle p-button-danger">
            <fa-icon [icon]="faTrash"></fa-icon>
          </p-button>
        </div>
      </p-toolbar>

      <div *ngIf="!agentsCalendarView && !crmInformationView" class="flex-grow-1 m-2 overflow-hidden">
        <p-table #appointmentTable (onLazyLoad)="onLazyLoad($event)" (onSort)="onSort($event)" [lazy]="true"
                 [paginator]="true" [rows]="pageSize" [scrollable]="true" [totalRecords]="totalRecords"
                 tableStyleClass="overflow-auto"
                 [value]="appointments" styleClass="p-datatable-striped"
                 dataKey="appointmentId" scrollHeight="flex">
          <ng-template pTemplate="header">
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.prop">
                {{ col.name }}
                <p-sortIcon [field]="col.prop"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template let-expanded="expanded" let-row pTemplate="body">
            <tr role="button" (click)="selectRow(row)" [pRowToggler]="row"
                [pRowTogglerDisabled]="isEmptyGuid(row.userGuid)">
              <td *ngFor="let col of columns">
                {{ row[col.prop] }}
              </td>
            </tr>
          </ng-template>
          <ng-template let-row pTemplate="rowexpansion">
            <tr class="expanded-row">
              <td [attr.colspan]="columns.length">
                <app-appointment-row-detail (filterData)='search()'
                                            (showCrmInformationView)='showCRMInformation($event)'
                                            [appointment]='row'
                                            [isOpen]="isOpen(row)"
                                            [inSupervisorMode]='inSupervisorMode()'>
                </app-appointment-row-detail>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div *ngIf='agentsCalendarView' class='h-100 mb-2 overflow-auto '>
        <app-agent-calendar></app-agent-calendar>
      </div>
    </div>
  </div>
</div>
<div *ngIf='crmInformationView' class="h-100 overflow-hidden">
  <app-crm (backToForm)='onBackToForm()'
           *ngIf='crmInformationView'
           [selectSessionHistoryTab]='selectSessionHistoryTab'
           [selected]='selectedContact'
           class="h-100">
  </app-crm>
</div>
