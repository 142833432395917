import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {VisitorService} from '../../../services/visitor-service/visitor.service';
import {BehaviorSubject, Subscription, Observable, of} from 'rxjs';
import {Visitor} from '../../../services/visitor-service/visitor';
import {VisitorDetails} from '../../../classes/visitor-details';
import { VisitorSessionDetails } from '../../../classes/visitor/VisitorSessionDetails';
import { VisitorSiteDetails } from '../../../classes/visitor/VisitorSiteDetails';
import { BrowsingHistory } from '../../../services/crm-service/browsing-history';
import { map } from 'rxjs/operators';
import { EngagementEvent } from '../../../services/crm-service/engagement-event';
import { CrmStructure } from '../../../services/crm-service/crm-category';
import {SettingsService} from '../../../services/settings-service/settings.service';
import {LoggingService} from '../../../services/logging.service';
import {AsyncMessageHandler} from '../../../services/async-message-handler';

@Component({
  selector: 'app-veechat-visitor',
  templateUrl: './veechat-visitor.component.html',
  styleUrls: ['./veechat-visitor.component.scss']
})
export class VeechatVisitorComponent implements OnInit, OnDestroy {

  @Input() username: string;

  private _visitor: Visitor;
  @Input() set visitor(visitor: Visitor) {
    if (!this._visitor || this._visitor.userGuid !== visitor.userGuid) {
      this._visitor = visitor;

      if (visitor) {
        this.visitorBrowsingHistory$ = visitor.browsingHistory.asObservable();
        this.visitorSessionHistory$ = visitor.sessionHistory.asObservable();
        this.visitorSessionDetails$ = visitor.details.pipe(map(v => this.createSessionDetails(v)));
        this.visitorDetails$ = of(this.createDetails(visitor));
        this.visitorCRMData$ = visitor.crmData;
        this.selectedUserGuid = visitor.userGuid;

        if (visitor.isAsync) {
          this.visitorService.getAsyncMessages(
            { Id: visitor.sessionGuid },
            { Id: visitor.userGuid },
            false).subscribe(msgs => {
              const messageHandler = new AsyncMessageHandler(this.logging, this.settingService, this.username, visitor.sessionGuid);
              messageHandler.handleMessages(msgs);
              visitor.transcript = messageHandler.Messages.messages;
            });
        }
       }

    }
  }
  get visitor(): Visitor {
    return this._visitor;
  }

  public visitorDetails$: Observable<VisitorSiteDetails>;
  public visitorCRMData$: Observable<CrmStructure>;
  public visitorSessionDetails$: Observable<VisitorSessionDetails> ;
  public visitorBrowsingHistory$: Observable<BrowsingHistory[]>;
  public visitorSessionHistory$: Observable<EngagementEvent[]>;

  public selectedUserGuid: string = null;

  private visitorsSub: Subscription;

  constructor(
    private readonly visitorService: VisitorService,
    private readonly logging: LoggingService,
    private readonly settingService: SettingsService,
  ) {
  }

  ngOnInit() {
    this.visitorsSub = this.visitorService.visitors.subscribe(visitors => this.updateVisitorDetails(visitors));
  }

  ngOnDestroy() {
    this.visitorsSub.unsubscribe();
  }

  private updateVisitorDetails(visitors: Visitor[]) {
    const idx = visitors.findIndex(v => v.userGuid === this._visitor.userGuid);

    if (idx !== -1) {
      const visitor = visitors[idx];
      this.visitorDetails$ = of(this.createDetails(visitor));
    }
  }

  private createDetails(visitor: Visitor): VisitorSiteDetails {
    return {
      page: visitor.lastPage,
      section: visitor.lastSection,
      prevOp: visitor.previousOperator,
      location: visitor.location,
      useragent: visitor.browser,
    };
  }

  private createSessionDetails(details: VisitorDetails): VisitorSessionDetails {
    return {
      organisation: details.Org,
      ip: details.IPAddress,
      referrer: details.Referrer,
      deviceScaleFactor: details.DevicePixelRatio,
      isMobile: false,
      isTablet: false,
      clientWidth: details.ClientWidth,
      clientHeight: details.ClientHeight
    };
  }

  public showTranscript(visitor:Visitor): boolean {
    if (visitor && visitor?.transcript.length > 0 && (visitor.isEngaged || visitor.isAsync)) {
      if (visitor.beingTransferred && visitor.isAsync) {
        return true;
      }

      if (!visitor.beingTransferred && !visitor.isMultiparty) {
        return true;
      }
    }
    return false;
  }
}
