export class TimeUtil {
  private constructor() {
    throw new Error('Not for instantiation');
  }

  public static CreateTimeDifferenceForDisplay(status: Date) {
    if (status === null) {
      return 'N/A';
    }
    const seconds = Math.floor(Math.max(0, Date.now() - status.getTime()) / 1000);

    // Over an hour
    if (seconds > 3599) {
      return '1 hr+';
    } else if (seconds < 60) {
      // Under a minute
      return `00:${TimeUtil.formatLeadingZero(seconds)}`;
    } else {
      // We have minutes and seconds to deal with
      return `${TimeUtil.formatLeadingZero(Math.floor(seconds / 60))}:${TimeUtil.formatLeadingZero(Math.floor(seconds % 60))}`;
    }
  }

  private static formatLeadingZero(num: number): string {
    if (num > 9.5) {
      return num.toFixed(0);
    } else {
      return '0' + num.toFixed(0);
    }
  }

  public static PrettyRelativePastTime(previousTime: Date): string {
    const seconds = Math.max(0, (new Date().getTime() - previousTime.getTime()) / 1000);
    return TimeUtil.PrettyRelativeTime(seconds);
  }

  public static PrettyRelativeTime(seconds: number): string {
    if (seconds < 60) {
      return `${seconds.toFixed(0)}s`;
    } else if (seconds < 3600) {
      return `${(seconds/60).toFixed(0)}m`;
    } else if (seconds < (3600 * 24)) {
      return `${(seconds/3600).toFixed(0)}h`;
    } else {
      return `${(seconds/(3600*24)).toFixed(0)}d`;
    }
  }
}
