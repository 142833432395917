<table class="table table-borderless ">
  <thead>
  <tr class="d-flex text-center">
    <th class="col-2">{{ "SUPERVISOR_HEADER_AGENT"  | Translate : 'Agent' }}</th>
    <th class="col-2">{{ "SUPERVISOR_HEADER_TIMEINSESSION"  | Translate : 'Time in Session' }}</th>
    <th class="col-2">{{ "SUPERVISOR_HEADER_TIMERESPONSE"  | Translate : 'Time Since Response' }}</th>
    <th class="col-3" [ngClass]="{'col-4': !privateChatAvailable}">
      {{ "SUPERVISOR_HEADER_LASTPAGE"  | Translate : 'Last Page' }}</th>
    <th class="col-1" *ngIf="privateChatAvailable">
      {{ "SUPERVISOR_HEADER_AGENTASSIST"  | Translate : 'Agent Assist' }}</th>
    <th class="col-2">{{ "SUPERVISOR_HEADER_ENTERSESSION"  | Translate : 'Enter Session' }}</th>
  </tr>
  </thead>
  <tbody *ngIf="show">
  <ng-container *ngFor="let group of selectedGroups | keyvalue">
    <tr class="op-group-header" *ngIf="(selectedVisitors | visitorFilter : group.value).length > 0">
      <td>
        <h3 class="op-group-name">{{group.value}}</h3>
      </td>
    </tr>

    <tr *ngFor="let visitor of (selectedVisitors | visitorFilter : group.value)"
        class="d-flex text-center ml-3">
      <td class="col-2">{{visitor.currentOperator}}</td>
      <td class="col-2">{{visitor.assistTime}}</td>
      <td class="col-2">{{visitor.getTimeSinceResponse()}}</td>
      <td class="col-3 text-truncate" [ngClass]="{'col-4': !privateChatAvailable}">{{visitor.lastPage}}</td>
      <td class="col-1" *ngIf="privateChatAvailable">
        <img *ngIf="visitor.agentRequestingAssistance"
             src="./assets/call-status-icons/handraise_btn.svg">
      </td>
      <td class="col-2">
        <div class="d-flex flex-row align-items-center justify-content-center">
          <p-button  severity="success" styleClass=" center-icon" appDebounceClick
                    [ngClass]="{'d-none': !supervisingVisitors.has(visitor.engagementGuid) && !supervisingEngagements.has(visitor.engagementGuid)}"
                    (clicked)="onEnterSession(visitor)">
            <fa-icon [icon]="faSignIn"></fa-icon>

          </p-button>
          <p-button  severity="info" styleClass="center-icon" appDebounceClick
                    [ngClass]="{'d-none': supervisingVisitors.has(visitor.engagementGuid) || supervisingEngagements.has(visitor.engagementGuid)}"
                    (clicked)="onStartSnooping(visitor)">
            <fa-icon [icon]="faUserSecret"></fa-icon>
          </p-button>
        </div>

      </td>
    </tr>

    <tr class="op-group-header"  *ngIf="(selectedAgents | agentDashboardFilter: 'Post Engagement' : group.key).size > 0">
      <td>
        <h3 class="op-group-name">{{group.value}}</h3>
      </td>
    </tr>

    <tr *ngFor="let agent of (selectedAgents | agentDashboardFilter: 'Post Engagement' : group.key | keyvalue)"
        class="d-flex text-center ml-3">
      <td class="col-2"><span class="text-truncate">{{agent.value.firstname}} {{agent.value.lastname}}</span>
      </td>
      <td class="col-2">{{agent.value.status | titlecase}}</td>
      <td class="col-2">N/A</td>
      <td class="col-3" [ngClass]="{'col-4': !privateChatAvailable}">N/A</td>
    </tr>
  </ng-container>
  </tbody>
</table>
