import { version } from "./version";

export const environment = {
  production: true,
  version: version,
  environmentName: "prod_uk",
  rollbarToken: "75f5ca3694d24eabbb296524c7b3e9cd",
  visitoRHub: "https://visitor-eu.vee24.com",
  engageHub: "https://engage-eu.vee24.com",
  customerEngageRHub: "engage-eu.vee24.com",
  authApiRoot: "https://visitor-eu.vee24.com/auth",
  configApiRoot: "https://visitor-eu.vee24.com/settings",
  staticRoot: "https://static.vee24.com",
  turnAuthRoot: "https://visitor-eu.vee24.com/turn",
  screenshotRoot: "https://visitor-eu.vee24.com/screenshot",
  engagementApiRoot: "https://visitor-eu.vee24.com/engagement",
  agentsRoot: "https://visitor-eu.vee24.com/agents",
  assetProxy: "https://static.vee24.com/assets",
  crmRoot: "https://visitor-eu.vee24.com/crm",
  linkpreviewRoot: "https://visitor-eu.vee24.com/linkpreview",
  emailRoot: "https://visitor-eu.vee24.com/email",
  fulfillmentRoot: "https://visitor-eu.vee24.com/fulfillment",
  appointmentRoot: "https://visitor-eu.vee24.com/appointment",
  translationRoot: "https://visitor-eu.vee24.com/translate",
  webinarRoot: "https://visitor-eu.vee24.com/webinar",
  webinarHub: "https://webinar-eu.vee24.com",
  inviteRoot: "https://visitor-eu.vee24.com/invite",
  visitorRoot: "https://visitor-eu.vee24.com/visitor",
  uploadRoot: "https://visitor-eu.vee24.com/upload",
};
