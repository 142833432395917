import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {
  faCalendar,
  faCalendarTimes,
  faRectangleList,
  faSave,
  faTimes,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import {CrmService} from '../../services/crm-service/crm.service';
import {Observable, Subscription} from 'rxjs';
import {OnlineState} from '../../enums/online-state.enum';
import {OnlineService} from '../../services/online.service';
import {CrmContact} from '../../services/crm-service/crm-contact';
import {AlertService, AlertType} from '../../services/alert-service/alert.service';
import {
  Appointment,
  AppointmentFilterParameter,
  AppointmentType,
  InsertAppointment
} from '../../services/crm-service/appointment';
import {StatusEnum} from '../../enums/appointment-status';
import * as moment from 'moment';
import {TranslatePipe} from '../../filters/Translate.pipe'
import {Location} from '@angular/common';
import {Timezones} from '../../utils/timezones';
import {AuthService} from '../../services/auth-service/auth.service';
import {SessionTypes} from '../../services/crm-service/sessionTypes';
import {CrmComponent} from '../../components/crm/crm.component';
import {CrmTabs} from '../../enums/CrmTabs';
import {first, map} from 'rxjs/operators';
import {ISortObject, SortFactoryService} from "../../services/sort-factory-service/sort-factory.service";
import {LazyLoadEvent, MenuItem} from "primeng/api";
import {Table} from "primeng/table";
import {Agent} from "../../classes/agent";
import {SettingsService} from "../../services/settings-service/settings.service";

type StatusMappingType = {
  value: StatusEnum,
  status: string
};

const StatusMapping:StatusMappingType[] = [
  {value: StatusEnum.ALL, status: 'ALL'},
  {value: StatusEnum.OPEN, status: 'OPEN'},
  {value: StatusEnum.CLOSED, status: 'CLOSED'},
  {value: StatusEnum.CANCELLED, status: 'CANCELLED'},
  {value: StatusEnum.UNAVAILABLE, status: 'UNAVAILABLE'},
  {value: StatusEnum.NOSHOW, status: 'NOSHOW'},
  {value: StatusEnum.CLOSED_AUTO, status: 'CLOSED (AUTO)'},
  {value: StatusEnum.NOSHOW_AUTO, status: 'NOSHOW (AUTO)'},
];

const EMPTY_GUID:string = '00000000-0000-0000-0000-000000000000';

@Component({
  selector: 'app-appointment-search',
  templateUrl: './appointment-search.component.html',
  styleUrls: ['./appointment-search.component.scss'],
  providers: [TranslatePipe]
})
export class AppointmentSearchComponent implements OnInit {

  @ViewChild("appointmentTable", {static: false}) appointmentTable: Table;
  @Output() selectedAppointment: EventEmitter<CrmContact> = new EventEmitter<CrmContact>();
  public crmInformationView: boolean = false;
  public selectedContact: CrmContact;
  public selectedRow: Appointment;
  public selectSessionHistoryTab: boolean = false;
  public agentAppointment: InsertAppointment;
  public appointmentFilterParameters: AppointmentFilterParameter = new AppointmentFilterParameter();
  public appointmentStatuses:StatusMappingType[] = [];
  public sessionTypes: SessionTypes[] = [];
  public agentLists: Agent[] = [];
  public appointmentTypes: AppointmentType[] = [];
  public unAvailableAptFields: boolean = false;
  public agentsCalendarView: Boolean = false;
  public menu: MenuItem[] = [
    {
      label: this.translate.transform("APPOINTMENTSEARCH_LABEL_APPOINTMENTS", 'Appointments'),
      icon: 'pi pi-list',
      command: () => {
        this.unAvailableAptFields = false;
        this.agentsCalendarView = false;
      }
    },
    {
      label: this.translate.transform("APPOINTMENTSEARCH_LABEL_AGENTCALENDAR", 'Agent Calendar'),
      icon: 'pi pi-calendar',
      command: () => {
        this.unAvailableAptFields = false;
        this.agentsCalendarView = true;
      }
    },
    {
      label: this.translate.transform("APPOINTMENTSEARCH_LABEL_NAVAILABLEBLOCK", 'Unavailable Block'),
      icon: 'pi pi-calendar-times',
      command: () => {
        this.resetAgentAptFields();
        this.agentsCalendarView = false;
        this.unAvailableAptFields = true;
      }
    }
  ];
  public activeItem: MenuItem = this.menu[0];
  public timeZone: string = 'GMT Standard Time';
  public loading: boolean;
  public pageSize: number = 30;
  public totalRecords: number = 0;
  public appointments: Appointment[] = [];
  public columns = [
    {name: this.translate.transform("APPOINTMENTSEARCH_RESULT_COLUMN_CALLTYPE", 'Call Type'), prop: 'callType'},
    {name: this.translate.transform("APPOINTMENTSEARCH_RESULT_COLUMN_DATE", 'Date'), prop: 'appointmentDate'},
    {name: this.translate.transform("APPOINTMENTSEARCH_RESULT_COLUMN_STARTTIME", 'Start Time'), prop: 'startTime'},
    {name: this.translate.transform("APPOINTMENTSEARCH_RESULT_COLUMN_ENDTIME", 'End Time'), prop: 'endTime'},
    {name: this.translate.transform("APPOINTMENTSEARCH_RESULT_COLUMN_DESCRIPTION", 'Description'), prop: 'description'},
    {
      name: this.translate.transform("APPOINTMENTSEARCH_RESULT_COLUMN_APPOINTMENTTYPE", 'Appointment Type'),
      prop: 'appointmentType'
    },
    {name: this.translate.transform("APPOINTMENTSEARCH_RESULT_COLUMN_STATUS", 'Status'), prop: 'status'},
    {name: this.translate.transform("APPOINTMENTSEARCH_RESULT_COLUMN_ASSIGNEDAGENT", 'Assigned Agent'), prop: 'agent'}
  ];
  public currentState$: Observable<OnlineState>;
  public OnlineState = OnlineState;
  protected readonly faSave = faSave;
  protected readonly faTrash = faTrash;
  protected readonly faCalendar = faCalendar;
  protected readonly faRectangleList = faRectangleList;
  protected readonly faTimes = faTimes;
  protected readonly faCalendarTimes = faCalendarTimes;
  private allLabel: string = "ALL";
  private subscriptions: Subscription[] = [];
  private appointmentSort: ISortObject<Appointment>;

  constructor(
    private onlineService: OnlineService,
    private authService: AuthService,
    private crmService: CrmService,
    private translate: TranslatePipe,
    private alertService: AlertService,
    private location: Location,
    private cdr: ChangeDetectorRef,
    private sortFactory: SortFactoryService,
    private settingsService: SettingsService
  ) {
  }

  ngOnInit() {

    StatusMapping.forEach(({status, value}) => {
      StatusMapping[value].status = this.translate.transform(`APPOINTMENTSEARCH_STATUS_${value.toString()}`, status);
    });

    this.appointmentStatuses = StatusMapping;
    this.selectedContact = new CrmContact(CrmComponent.EMPTY_SELECTION);

    this.authService.currentAgent.pipe(first()).subscribe(agent => {
      this.timeZone = Timezones.TIMEZONE_LOOKUP.get(agent.timezone) ?? 'GMT Standard Time';
      this.appointmentFilterParameters.agents = [agent.username];
    });

    const appointmentFilterFrom = this.settingsService.getResourceOrDefault('APPOINTMENTSEARCH_FILTER_FROM_NUMBER_OF_WEEKS', '0');
    const appointmentFilterTo = this.settingsService.getResourceOrDefault('APPOINTMENTSEARCH_FILTER_TO_NUMBER_OF_WEEKS', '2');

    this.appointmentFilterParameters.from = moment().add(Number(-appointmentFilterFrom), 'week').format('YYYY-MM-DD');
    this.appointmentFilterParameters.to = moment().add(Number(appointmentFilterTo), 'week').format('YYYY-MM-DD');
    this.appointmentFilterParameters.agents = [this.authService.currentAgent.value.username];
    this.loadFilterParameters();
    this.search();
    this.resetAgentAptFields();
    this.appointmentSort = this.sortFactory.getSortObject<Appointment>();

    this.allLabel = this.translate.transform("APPOINTMENTSEARCH_LABEL_ALL", "ALL");
    this.currentState$ = this.onlineService.currentState.pipe(map(([s, _]) => s));
    this.onlineService.setCurrentState(OnlineState.Appointment);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  }

  onEndBreak() {
    this.onlineService.setCurrentState(OnlineState.Appointment);
  }

  loadFilterParameters() {
    const appointmentTypeListSub = this.crmService.loadAppointmentTypes().subscribe(types => {
      const apt = new AppointmentType();
      apt.appointmentType = this.allLabel;
      apt.appointmentTypeId = 0;
      if (types != undefined) {
        this.appointmentTypes = Array.from(types);
        this.appointmentTypes.unshift(apt);
      }
      this.appointmentFilterParameters.appointmentTypeId = 0;
    });

    const sessionTypeListSub = this.crmService.loadSessionTypes().subscribe(types => {
      const session = new SessionTypes();
      session.Label = this.allLabel;
      if (this.crmService.sessionTypes != undefined) {
        this.sessionTypes = Array.from(this.crmService.sessionTypes.value);
        this.sessionTypes.unshift(session);
        this.appointmentFilterParameters.callType = this.allLabel;
      }
    });

    const agentsListSub = this.crmService.loadAgents().subscribe(types => {
      if (types != undefined) {
        this.agentLists = Array.from(types);
      }
    });

    this.subscriptions.push(agentsListSub);
    this.subscriptions.push(appointmentTypeListSub);
    this.subscriptions.push(sessionTypeListSub);

    this.cdr.detectChanges();
  }

  public inSupervisorMode(): Boolean {
    const path = this.location.path();
    return path.includes('supervisor');
  }

  public resetAgentAptFields() {
    this.agentAppointment = new InsertAppointment();
    this.agentAppointment.startDate = moment().format('YYYY-MM-DD');
    this.agentAppointment.endDate = moment().format('YYYY-MM-DD');
    this.agentAppointment.startTime = moment().format('HH:mm');
    this.agentAppointment.endTime = moment().format('HH:mm');
    this.agentAppointment.notes = '';
    this.agentAppointment.callType = '';
    this.agentAppointment.appointmentGuid = null;
    this.agentAppointment.isEditing = true;
    this.agentAppointment.siteSection = '';
    this.agentAppointment.userGuid = null;
    this.agentAppointment.subject = '';
  }

  public onChangeAppointmentType($event) {
    this.appointmentTable.filter($event.value, 'appointmentType', 'in');
    this.appointmentFilterParameters.appointmentTypeId = $event.value;
  }

  public onCallTypeChange($event) {
    this.appointmentTable.filter($event.value, 'callType', 'in');
    this.appointmentFilterParameters.callType = $event.value;
  }

  public onStatusChange($event) {
    this.appointmentTable.filter($event.value, 'status', 'in');
    this.appointmentFilterParameters.status = $event.value;
  }

  public onChangeAgent($event) {
    if (!event) {
      this.appointmentTable.filter([], 'agent', 'in');
      this.appointmentFilterParameters.agents = [];
      return;
    }
    this.appointmentTable.filter($event.value, 'agent', 'in');
    this.appointmentFilterParameters.agents = $event.value;
  }

  public onChangeStartDate($event) {
    this.appointmentFilterParameters.from = $event.target.value;
    this.appointmentTable.filter($event.target.value, 'startDate', 'gte');
  }

  public onChangeEndDate($event) {
    this.appointmentFilterParameters.to = $event.target.value;
    this.appointmentTable.filter($event.target.value, 'endDate', 'lte');

  }

  public clearTable() {
    this.appointmentTable?.clear();
  }

  public search(pageNumber: number = 0) {
    this.loading = false;
    this.crmService.filterAppointmentData(
      this.appointmentFilterParameters.appointmentTypeId,
      this.appointmentFilterParameters.callType == this.allLabel ? "" : this.appointmentFilterParameters.callType,
      this.appointmentFilterParameters.status,
      this.appointmentFilterParameters.agents ?? [],
      this.appointmentFilterParameters.from,
      this.appointmentFilterParameters.to,
      this.pageSize,
      pageNumber).pipe(first()).subscribe(pagedData => {
      if (pagedData == null) {
        this.alertService.addAlert(this.translate.transform('APPOINTMENTSEARCH_ERROR_RETRIEVING', "An error occurred while retrieving appointments."), AlertType.Danger);
        this.appointments = [];
        this.totalRecords = 0;
        this.loading = false;
      } else {
        const res = pagedData.appointmentList.map(contact => new Appointment(contact));
        res.forEach(element => {
          if (this.isEmptyGuid(element.userGuid)) {
            ///FIXME: this needs removing and done properly
            const unavailableString = StatusMapping[StatusEnum.UNAVAILABLE].status
            element.status = unavailableString;
            element.appointmentType = unavailableString;
            element.callType = unavailableString;
          } else {
            element.status = StatusMapping[element.status].status;
          }


          if (element.appointmentType == '') {
            element.appointmentType = this.translate.transform("APPOINTMENTSEARCH_APPOINTMENTTYPE_AGENTSCHEDULED", "Agent Scheduled Appointment");
          }

          if (new Date(element.startDate).toDateString() !== new Date(element.endDate).toDateString()) {
            element.endTime = element.endDate;
          }

          const resourceKey = "SESSIONTYPE_" + element.callType.toUpperCase() + "_PRODUCT";
          const resourceValue = this.translate.transform(resourceKey, element.callType.toUpperCase());
          element.callType = resourceValue;

        });
        this.appointments = this.appointmentSort.sortData(res);
        this.totalRecords = pagedData.totalAppointments;
        this.loading = false;
      }
    });
  }

  onLazyLoad($event: LazyLoadEvent) {
    const page = ($event.first / $event.rows) + 1;
    this.search(page);
  }

  public isEmptyGuid(userGuid:string) {
    return userGuid === EMPTY_GUID || userGuid === "";
  }

  selectRow(row) {
    if (this.isEmptyGuid(row.userGuid)) {
      this.populateUnAvailableAptFields(row);
    } else {
      this.selectedContact.vee24Guid = row.userGuid;
      this.selectedRow = row;
      this.selectedAppointment.emit(row);
      this.goToTabAt(0);

    }

  }

  onTabChange(activeTab) {
    this.activeItem = activeTab;
  }

  populateUnAvailableAptFields(row) {
    this.goToTabAt(2);
    this.agentAppointment = new InsertAppointment();
    this.agentAppointment.appointmentGuid = row.appointmentGuid;
    this.agentAppointment.subject = row.description;
    this.agentAppointment.startDate = moment(new Date(row.startDate)).format('YYYY-MM-DD');
    this.agentAppointment.endDate = moment(new Date(row.endDate)).format('YYYY-MM-DD');
    this.agentAppointment.startTime = moment(new Date(row.startDate)).format('HH:mm');
    this.agentAppointment.endTime = moment(new Date(row.endDate)).format('HH:mm');
    this.agentAppointment.isEditing = true;
    this.agentAppointment.siteSection = '';
    this.agentAppointment.userGuid = null;
    this.agentAppointment.callType = '';
  }

  onCancelAgentAppointment() {
    if (this.agentAppointment.appointmentGuid !== null) {
      this.subscriptions.push(
        this.crmService.deleteAppointment(this.agentAppointment.appointmentGuid).subscribe(data => {
          if (data) {
            this.alertService.addAlert(this.translate.transform("APPOINTMENTSEARCH_UNAVAILABLEAPT_DELETED", "Appointment Deleted."), AlertType.Success);
            this.search();
          } else {
            this.alertService.addAlert(this.translate.transform("APPOINTMENTSEARCH_UNAVAILABLEAPT_NOT_DELETED", "Appointment Not Deleted."), AlertType.Danger);
          }
        })
      );
    }
    this.goToTabAt(0);
    this.resetAgentAptFields();
  }

  onSaveAgentAppointment() {
    this.agentAppointment = this.crmService.isValidAppointment(this.agentAppointment);
    if (this.agentAppointment.validationError !== '') {
      this.alertService.addAlert(this.agentAppointment.validationError, AlertType.Danger);
      return;
    }
    if (this.agentAppointment.appointmentGuid !== null) {
      this.subscriptions.push(
        this.crmService.updateAppointment(this.agentAppointment, null).subscribe(data => {
          if (data) {
            this.alertService.addAlert(this.translate.transform("APPOINTMENTSEARCH_UNAVAILABLEAPT_UPDATED", "Appointment Updated."), AlertType.Success);
            this.search();
          } else {
            this.alertService.addAlert(this.translate.transform("APPOINTMENTSEARCH_UNAVAILABLEAPT_NOT_UPDATED", "Appointment Not Updated."), AlertType.Danger);
          }
        })
      );
    } else {
      this.subscriptions.push(
        this.crmService.insertAppointment(this.agentAppointment, null).subscribe(data => {
          if (data) {
            this.alertService.addAlert(this.translate.transform("APPOINTMENTSEARCH_UNAVAILABLEAPT_INSERTED", "Appointment Saved."), AlertType.Success);
            this.search();
          } else {
            this.alertService.addAlert(this.translate.transform("APPOINTMENTSEARCH_UNAVAILABLEAPT_NOT_INSERTED", "Appointment Not Saved."), AlertType.Danger);
          }
        })
      );
    }
    this.goToTabAt(0);
    this.resetAgentAptFields();
  }

  public showCRMInformation($selectedCrmTab: CrmTabs) {
    if ($selectedCrmTab == CrmTabs.SESSIONHISTORY) {
      this.selectSessionHistoryTab = true;
      this.crmInformationView = true;
    } else {
      this.selectSessionHistoryTab = false;
      this.crmInformationView = true;
    }
  }

  public onBackToForm() {
    this.crmInformationView = false;
  }

  onSort(event: any) {
    this.appointmentSort.setSortField(event.field);
  }

  isOpen(row: Appointment): boolean {
    const statusValue = this.getStatusValue(row.status);
    return statusValue === StatusEnum.OPEN;
  }

  private getStatusValue(translatedStatus: string): StatusEnum | undefined {
    const status = this.appointmentStatuses.find(mapping => mapping.status === translatedStatus);
    return status ? status.value : undefined;
  }

  private goToTabAt(index: number) {
    this.activeItem = this.menu[index];
    this.activeItem?.command(null);
  }
}
