import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CrmField } from '../../../services/crm-service/crm-field';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';

@Component({
  selector: 'app-crm-field-datetime',
  templateUrl: './crm-field-datetime.component.html',
  styleUrls: ['./crm-field-datetime.component.scss']
})
export class CrmFieldDatetimeComponent implements OnInit {
  faCalendarAlt = faCalendarAlt;

  private _field: CrmField;

  @Input() set field(field: CrmField) {
    this._field = field;
  }

  get field(): CrmField {
    return this._field;
  }

  @Input() disableCrm: boolean;

  @Output() saveField: EventEmitter<CrmField> = new EventEmitter<CrmField>();

  constructor() { }

  ngOnInit() {
  }

  public getDate(date: string) {
    const dateValue = moment(date, 'DD/MM/YYYY HH:mm:ss');
    const res = moment(dateValue).format('YYYY-MM-DD');
    return res;
  }

  public onFieldValueChange(event) {
    const dateValue = moment(event.target.value).format('DD/MM/YYYY');
    this.field.value = dateValue.toString() + ' ' + '00:00:00';
    this.saveField.emit();
  }
}
