<div>
  <div class="pl-3 pr-3 w-100">
    <table class="table table-borderless">
      <tr>
        <th>{{ "APPOINTMENTSEARCH_LABEL_CUSTOMERINFORMATION" | Translate : 'Customer Information :' }}
        </th>
      </tr>
      <tr>
        <td>{{ "APPOINTMENTSEARCH_APPOINTMENT_ID" | Translate : 'Appointment ID:' }} {{appointment.appointmentId}}</td>
      </tr>
      <tr>
        <td>{{ "APPOINTMENTSEARCH_LABEL_NAME" | Translate : 'Name:' }} {{appointment.customerName}}</td>
        <td rowspan="3" class="text-left note-column">
          {{ "APPOINTMENTSEARCH_LABEL_NOTES" | Translate : 'Notes:' }}
          <textarea pInputTextarea id="notes"  value="{{appointment.notes}}" (change)="onNoteFieldValueChange($event)"></textarea>
        </td>
      </tr>
      <tr>
        <td>{{ "APPOINTMENTSEARCH_LABEL_EMAIL" | Translate : 'Email:' }} {{appointment.customerEmail}}</td>
      </tr>
      <tr>
        <td>{{ "APPOINTMENTSEARCH_LABEL_PHONE" | Translate : 'Phone:' }} {{appointment.customerPhone}}</td>
      </tr>
    </table>
  </div>
  <div class="pl-3 pr-3 w-100 d-flex flex-row justify-content-between">
    <div class="float-left d-flex flex-row gap-2 justify-content-between">
       <p-button
        pTooltip='{{ "APPOINTMENTSEARCH_LABEL_VIEWCRM" | Translate : "View CRM Record" }}'
        (onClick)="onShowCRMData()">{{ "APPOINTMENTSEARCH_LABEL_VIEWCRM" | Translate : "View CRM Record" }}</p-button>
       <p-button    (onClick)="onShowSessionHistory()"
        pTooltip='{{ "APPOINTMENTSEARCH_LABEL_VIEWSESSIONHISTORY" | Translate : "View Session History" }}'> {{
        "APPOINTMENTSEARCH_LABEL_VIEWSESSIONHISTORY" | Translate : "View Session History" }}</p-button>
    </div>
    <div class="float-right d-flex flex-row gap-2">
      <p-button id="transfer-appt-btn" *ngIf="canTransferOrChangeDisposition()" size="large"
                tooltipPosition="top"
        [pTooltip]='"APPOINTMENTSEARCH_LABEL_TRANSFERAPPOINTMENT" | Translate : "Transfer Appointment"'
        (onClick)="onTransferAppointment()">
          <fa-icon [icon]="faRightLeft" />
      </p-button>
      <p-button id="close-appt-btn" size="large" tooltipPosition="top" *ngIf="canTransferOrChangeDisposition()"
        [pTooltip]='"APPOINTMENTSEARCH_LABEL_CLOSEAPPOINTMENT" | Translate : "Close Appointment"'
        (onClick)="onCloseAppointment()">
          <fa-icon [icon]="faCalendarCheck" />
      </p-button>
      <p-button id="cancel-appt-btn" size="large" severity="danger" *ngIf="canTransferOrChangeDisposition()" (onClick)="onCancelAppointment()" tooltipPosition="top"
        [pTooltip]='"APPOINTMENT_LABEL_CANCELAPPOINTMENT" | Translate : "Cancel Appointment"'>
          <fa-icon [icon]="faCalendarXmark" />
      </p-button>
      <p-button id="noshow-appt-btn" size="large" *ngIf="canTransferOrChangeDisposition()" (onClick)="onNoShowAppointment()"  tooltipPosition="left"
        [pTooltip]='"APPOINTMENT_LABEL_NOSHOWAPPOINTMENT" | Translate : "Mark Appointment No Show"'>
          <fa-icon [icon]="faUserTimes" size="sm" />
      </p-button>
    </div>
  </div>
</div>
