import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {faEllipsisV} from '@fortawesome/free-solid-svg-icons';
import {StringUtils} from '../../../utils/string-utils';
import {MenuItem, PrimeIcons} from 'primeng/api';
import {TranslatePipe} from '../../../filters/Translate.pipe';
import {Engagement} from '../../../services/engagement';


@Component({
  selector: 'app-engagement-join-row',
  templateUrl: './engagement-join-row.component.html',
  styleUrl: './engagement-join-row.component.scss'
})
export class EngagementJoinRowComponent implements OnInit, OnChanges {

  constructor(private translate: TranslatePipe) {
  }

  @Input() engagement: Engagement;
  @Input() username: string;
  @Input() name: string;
  @Input() photo: string;
  @Input() deviceType: 'desktop' | 'mobile' | 'veestudio' | 'veechat';
  @Input() onlineState: 'online' | 'offline' | 'backgrounded';
  @Input() isAgent: boolean;

  @Output() kick = new EventEmitter<string>();
  @Output() changePresenter = new EventEmitter<string>();

  public menuItems: MenuItem[] = [];

  protected readonly StringUtils = StringUtils;
  protected readonly faEllipsisV = faEllipsisV;

  ngOnInit() {
    this.updateMenuItems();
  }

  ngOnChanges(changes: SimpleChanges) {
      this.updateMenuItems();
  }

  hasVisibleMenuItems(): boolean {
    return this.menuItems.some(item => item.visible);
  }

  getStyleClassForOnlineState() {
    switch (this.onlineState) {
      case 'online':
        return 'surface-green';
      case 'backgrounded':
        return 'surface-grey';
      default:
        return 'surface-red';
    }
  }

  private updateMenuItems(): MenuItem[] {

    const presenterLbl: string = this.translate.transform('ENGAGEMENT_JOIN_ROW_MAKE_PRESENTER', 'Make Presenter');
    const removeLbl: string = this.translate.transform('ENGAGEMENT_JOIN_ROW_MAKE_REMOVE', 'Remove from call');
    this.menuItems = [
      {
        id: 'make-presenter-btn',
        label: presenterLbl,
        icon: PrimeIcons.DESKTOP,
        command: () => this.changePresenter.emit(this.username),
        visible: this.isAgent,
        disabled: this.isAgent && this.engagement?.presentingAgent.getValue() === this.username
      },
      {
        id: 'remove-btn',
        label: removeLbl,
        icon: PrimeIcons.MINUS_CIRCLE,
        command: () => this.kick.emit(this.username),
        visible: this.username !== this.engagement?.primaryCustomer.getValue() && this.username !== this.engagement?.primaryAgent.getValue()
      }
    ];

   return this.menuItems;
  }

}
