import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import { Visitor } from '../../../../services/visitor-service/visitor';
import {faPhone} from '@fortawesome/free-solid-svg-icons';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import { CountryFlags } from '../../../../utils/country-flags';
import { CallStatuses } from '../../../../utils/call-statuses';
import {Subject, Subscription} from 'rxjs';
import {throttleTime} from 'rxjs/operators';
import {TimeUtil} from '../../../../utils/time-util';

@Component({
  selector: '[app-veechat-call-list-row]',
  templateUrl: './veechat-call-list-row.component.html',
  styleUrls: ['./veechat-call-list-row.component.scss']
})
export class VeechatCallListRowComponent implements OnInit, OnDestroy {
  faPhone = faPhone;
  faInfoCircle = faInfoCircle;
  public CallStatuses = CallStatuses;
  public CountryFlags = CountryFlags;

  public browserInfo: any = {};

  private _visitor: Visitor;
  @Input() set visitor(visitor: Visitor) {
    this._visitor = visitor;
    this.browserInfo = visitor.browserInfo;
  }
  get visitor(): Visitor {
    return this._visitor;
  }

  @Output() acceptCall = new EventEmitter();
  private clicks = new Subject();
  private click$: Subscription;

  public get assistTime(): string {
    return TimeUtil.PrettyRelativeTime(this.visitor.assistTime);
  }

  constructor() { }

  public accept(): void {
    this.clicks.next({});
  }

  ngOnInit(): void {
    this.click$ = this.clicks.pipe(throttleTime(150)).subscribe(_ => this.acceptCall.emit());
  }

  ngOnDestroy(): void {
    this.click$.unsubscribe();
  }
}
