import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AgentStatus} from '../../../../../../classes/visitor/AgentStatus';
import { TimeUtil } from '../../../../../../utils/time-util';
import {Visitor} from '../../../../../../services/visitor-service/visitor';
import {Joined, Snooping, SnoopingConfiguration, SnoopingType} from '../../../../supervisor.component';
import {faSignInAlt, faPhone, faUserSecret} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dashboard-card-vee-studio-operators-busy',
  templateUrl: './dashboard-card-vee-studio-operators-busy.component.html',
  styleUrls: [
    './dashboard-card-vee-studio-operators-busy.component.scss',
    '../../../supervisor-dashboard-view.component.scss'
  ]
})
export class DashboardCardVeeStudioOperatorsBusyComponent implements OnInit {
  public TimeUtil = TimeUtil;
  public faSignIn = faSignInAlt;
  public faPhone = faPhone;

  // selectedGroups is not the selected groups in the hovis UI (that drop down in veesupervisor)
  // but the groups the supervisor belongs to (i.e. select in my.vee24.com)
  @Input() selectedGroups: Map<number, string>;

  @Input() selectedAgents: Map<string, AgentStatus>;

  @Input() show: boolean = true;

  @Input() privateChatAvailable: boolean;

  @Input() selectedVisitors: Visitor[];
  @Input() supervisingVisitors: Map<string, Snooping> = new Map([]);
  @Input() supervisingEngagements: Map<string, Joined> = new Map([]);

  @Output() enterSession: EventEmitter<SnoopingConfiguration> = new EventEmitter<SnoopingConfiguration>();
  @Output() startSnooping: EventEmitter<SnoopingConfiguration> = new EventEmitter<SnoopingConfiguration>();

  constructor() { }

  ngOnInit() {
  }

  onEnterSession(visitor: Visitor) {
    if (visitor.isAsync) {
      this.startSnooping.emit({ type: SnoopingType.AsyncChat, visitor });
    } else {
      this.enterSession.emit( { type: SnoopingType.Visitor, visitor });
    }
  }

  onStartSnooping(visitor: Visitor) {
    if (visitor.isAsync) {
      this.startSnooping.emit({ type: SnoopingType.AsyncChat, visitor });
    } else {
      this.startSnooping.emit({ type: SnoopingType.Visitor, visitor });
    }
  }

  protected readonly faUserSecret = faUserSecret;
}
