<div class="d-flex flex-row h-100 gap-2">
  <app-visitor-information class="main-layout" [visitorDetails]="(visitorDetails$ | async)"
    [visitorSessionDetails]="(visitorSessionDetails$ | async)"
    [visitorSessionHistory]="(visitorSessionHistory$ | async)"
    [visitorBrowsingHistory]="(visitorBrowsingHistory$ | async)"
    [visitorCrmData]="(visitorCRMData$ | async)"
    [userGuid]="(selectedUserGuid)">
  </app-visitor-information>

  <div *ngIf="showTranscript(visitor)">
    <div #scrollMe [scrollTop]="scrollMe.scrollHeight"
      class="d-flex flex-column flex-grow-1 h-100 sidebar-panel  overflow-auto">
      <ng-container *ngFor="let message of visitor.transcript">
        <app-text-chat [message]="message"></app-text-chat>
      </ng-container>
    </div>
  </div>
</div>
