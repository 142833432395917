<div class="d-flex flex-row">
  <!-- Heartbeat -->
  <div id="heartbeat" class="flex-grow-1 d-flex center-icon"
       [class.surface-green]="visitorHeartbeat && !backgrounded"
       [class.surface-grey]="visitorHeartbeat && backgrounded"
       [class.surface-red]="!visitorHeartbeat"
       [pTooltip]="visitorHeartbeat ?
                                  (backgrounded ? ('ENGAGEMENTTOOLBAR_TITLE_USERBG' | Translate: 'The user has backgrounded the browser')
                                                : ('ENGAGEMENTTOOLBAR_TITLE_USERPRESENT' | Translate: 'User is there'))
                                 : ('ENGAGEMENTTOOLBAR_TITLE_USERNOTPRESENT' | Translate:'User is not there')"
       >
    <fa-icon [fixedWidth]="true"  *ngIf="!backgrounded && visitorHeartbeat" [icon]="faHeart"></fa-icon>
    <fa-icon [fixedWidth]="true"  *ngIf="!backgrounded && !visitorHeartbeat" [icon]="faHeartBroken"></fa-icon>
    <fa-icon [fixedWidth]="true"  *ngIf="backgrounded" [icon]="faHeartCircleMinus"></fa-icon>
  </div>

  <!-- Can see me -->
  <div id="cansee" class="flex-grow-1 d-flex center-icon"
       [class.surface-green]="!chatOnly && visitorHeartbeat && customerViewingOperator"
       [class.surface-red]="chatOnly || !visitorHeartbeat || !customerViewingOperator"
       [pTooltip]="(!chatOnly && visitorHeartbeat && customerViewingOperator) ? ('ENGAGEMENTTOOLBAR_TITLE_USERCANSEE' | Translate:  'User Can See Me') : ('ENGAGEMENTTOOLBAR_TITLE_USERCANNOTSEE' | Translate:  'User Can Not See Me') ">
    <fa-icon [fixedWidth]="true"  *ngIf="!chatOnly && visitorHeartbeat && customerViewingOperator" [icon]="faEye"></fa-icon>
    <fa-icon [fixedWidth]="true"  *ngIf="chatOnly || !visitorHeartbeat || !customerViewingOperator" [icon]="faEyeSlash"></fa-icon>
  </div>

  <!-- Can hear me -->
  <div id="canhear" class="flex-grow-1 d-flex center-icon"
       [class.surface-green]="!chatOnly && (speakerVolume > 0) && visitorHeartbeat && customerHearingOperator"
       [class.surface-red]="chatOnly || (speakerVolume <= 0) || !visitorHeartbeat || !customerHearingOperator"
       [pTooltip]="(!chatOnly && (speakerVolume > 0) && visitorHeartbeat && customerHearingOperator) ? ('ENGAGEMENTTOOLBAR_TITLE_USERCANHEAR' | Translate:  'User Can Hear Me')  : ('ENGAGEMENTTOOLBAR_TITLE_USERCANNOTHEAR' | Translate:  'User Can Not Hear Me')">
    <fa-icon [fixedWidth]="true"  *ngIf="!chatOnly && (speakerVolume > 0) && visitorHeartbeat && customerHearingOperator" [icon]="faVolumeUp"></fa-icon>
    <fa-icon [fixedWidth]="true"  *ngIf="chatOnly || (speakerVolume <= 0) || !visitorHeartbeat || !customerHearingOperator" [icon]="faVolumeMute"></fa-icon>
  </div>

</div>
