import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'localDateTime'
})
export class LocalDateTimePipe implements PipeTransform {
  transform(timestamp: string, args?: any): string {
    return moment.utc(timestamp, 'DD-MM-YYYY HH:mm:ss').local().toString();
  }
}
