import {TextMessage, TextMessageTypes} from '../../classes/TextMessage';
import {EngagementEvent} from './engagement-event';
import Guid from '../../classes/Guid';
import * as moment from 'moment/moment';
import {CrmService} from './crm.service';
import {ChatHistory} from './ChatHistory';
import {TranslatePipe} from '../../filters/Translate.pipe';
import {SettingsService} from '../settings-service/settings.service';

export class CustomerChatHistory {
  private readonly currentEngagementMessage: string;
  private readonly statusMessage: string;
  private readonly loiMessage: string;
  private readonly notesMessage: string;
  private readonly blankStr: string;
  private readonly displayEngagementGuid: boolean = false;

  private watermark = -1;
  private count = 20;
  private finished = false;
  private currentRequest: Promise<TextMessage[]> = Promise.resolve([]);
  private sessionHistory: EngagementEvent[] = [];

  // Sorted as old to new.
  private _history: TextMessage[] = [];
  private _loading: boolean = false;

  public get done(): boolean {
    return this.finished;
  }

  public get history(): TextMessage[] {
    return this._history;
  }

  public get loading(): boolean {
    return this._loading;
  }

  constructor(
    private readonly crmService: CrmService,
    settingsService: SettingsService,
    private readonly vee24guid: Guid,
    private readonly engagementGuid: Guid,
    private readonly username: string)
  {
    const translatePipe = new TranslatePipe(settingsService);
    this.currentEngagementMessage = translatePipe.transform('TEXT_MESSAGE_DIVIDER_CURRENT_ENGAGEMENT', 'Current Engagement');
    this.statusMessage = translatePipe.transform('TEXT_MESSAGE_DIVIDER_STATUS', 'Status:');
    this.loiMessage = translatePipe.transform('TEXT_MESSAGE_DIVIDER_LOI', 'LOI:');
    this.notesMessage = translatePipe.transform('TEXT_MESSAGE_DIVIDER_NOTES', 'Notes:');
    this.blankStr = translatePipe.transform('TEXT_MESSAGE_DIVIDER_BLANK', '(blank)');
    this.displayEngagementGuid = settingsService.getResourceSettingEnabledOrDefault("TEXT_MESSAGE_DIVIDER_DISPLAY_ENGAGEMENTGUID", false);
  }

  public updateSessionHistory(sessionHistory: EngagementEvent[]) {
    if (sessionHistory) {
      this.sessionHistory = sessionHistory;
      this._history = this.insertSessionMessages(this.history);
    }
  }

  public loadMore(): Promise<TextMessage[]> {
    this._loading = true;

    this.currentRequest = this.currentRequest.then((_) => {
      if (this.finished) {
        return Promise.resolve([]);
      } else {
        return this.crmService.getChatHistory(this.vee24guid, this.engagementGuid, this.count, this.watermark)
          .toPromise()
          .then(items => {
            this._loading = false;

            if (items.length === 0) {
              this.finished = true;
              return Promise.resolve([]);
            } else {
              if (items.length < this.count) {
                this.finished = true;
              }
              const messages = this.createMessages(items);
              this.mergeHistory(messages);
              return Promise.resolve(messages);
            }
          });
      }
    });

    return this.currentRequest;
  }

  private createMessages(chatHistory: ChatHistory[]): TextMessage[] {
    return chatHistory.map(item => {
      return {
        id: item.id,
        message: item.message,
        timestamp: item.timestamp,
        originalMessage: '',
        senderType: this.getSender(item),
        senderName: item.senderName,
        senderId: item.senderId,
        deliveryTimestamp: undefined,
        currentEngagement: this.engagementGuid.toString() === item.engagementGuid,
        engagementGuid: item.engagementGuid,
      };
    }).filter(item => !item.currentEngagement);
  }

  private getSender(chatHistory: ChatHistory): TextMessageTypes {
    let senderType: TextMessageTypes;

    if (!chatHistory.senderIsAgent) {
      senderType = chatHistory.senderId.toLowerCase() === this.vee24guid.toString() ? TextMessageTypes.CUSTOMER : TextMessageTypes.OTHER_CUSTOMER;
    } else if (chatHistory.senderId.toLowerCase() === this.username.toLowerCase()) {
      senderType = TextMessageTypes.ME;
    } else {
      senderType = TextMessageTypes.OTHER_AGENT;
    }

    return senderType;
  }

  /**
   * Merge in new history to previously retrieved history.
   * @param items New history data.
   * @private
   */
  private mergeHistory(items: TextMessage[]) {
    items.sort((a, b) => a.id - b.id);
    if (this._history.length > 0) {
      this._history = this._history.filter(x => x.senderType !== TextMessageTypes.SESSION_START && x.senderType !== TextMessageTypes.DIVIDER);
      const lastHistoryId = this._history[0].id;
      const idx = items.findIndex(x => x.id == lastHistoryId);
      if (idx > -1) {
        const nonOverlappingItems = items.slice(0, idx);
        this._history = [...nonOverlappingItems, ...this._history];
      } else {
        this._history = [...items, ...this._history];
      }
    } else {
      this._history = [...items, ...this._history];
    }

    if (this._history.length > 0) {
      this.watermark = this._history[0].id - 1;
      this._history = this.insertSessionMessages(this._history);
      this._history.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
      this._history.push(this.createHistoryDivider(this._history[this._history.length - 1]));
    }
  }

  private insertSessionMessages(textMessages: TextMessage[]): TextMessage[] {
    textMessages = textMessages.filter(x => x.senderType !== TextMessageTypes.SESSION_START && x.senderType !== TextMessageTypes.SESSION_END);

    if (textMessages.length < 1) {
      return textMessages;
    }

    const messageStart = new Date(textMessages[0].timestamp.getTime() - 2000);
    let idx = -99999;
    for (let i = this.sessionHistory.length - 1; i >= 0; --i) {
      const session = this.sessionHistory[i];
      const sessionStart = moment.utc(session.StartTime, 'DD-MM-YYYY HH:mm:ss').toDate();
      const sessionEnd = moment.utc(session.EndTime, 'DD-MM-YYYY HH:mm:ss').toDate();

      // Load this session history entry old if it's not the current engagement
      // and either we have loaded the entire transcript, or the session started
      // after the first message.
      const shouldLoadPrevious = this.done || sessionStart > messageStart;
      const notCurrent = this.engagementGuid.toString() != session.EngagementGuid.toLowerCase()

      if (shouldLoadPrevious && notCurrent) {
        const startMessage = this.createStartMessage(sessionStart, session.EngagementType, idx--, session.EngagementGuid.toLowerCase());
        textMessages.push(startMessage);

        const endMessage = this.createEndMessage(sessionEnd, session.Status, session.LOI, session.OperatorNotes, idx--, session.EngagementGuid.toLowerCase());
        textMessages.push(endMessage);
      }
    }

    return textMessages;
  }

  private createStartMessage(startDate: Date, engagementType: string, id: number, engagementGuid: string): TextMessage {
    const typeMessage = CustomerChatHistory.engagementTypeMessage(engagementType);
    const message = this.displayEngagementGuid ? `${typeMessage} ${engagementGuid}` : typeMessage;
    const startMessage: TextMessage = {
      id,
      senderName: 'system',
      senderId: 'system',
      senderType: TextMessageTypes.SESSION_START,
      message,
      timestamp: startDate,
      originalMessage: '',
      currentEngagement: false,
    };
    return startMessage;
  }

  private createEndMessage(endDate: Date, status: string, loi: string, notes: string, id: number, engagementGuid: string): TextMessage {
    const statusStr = `${this.statusMessage} ${this.replaceBlankString(status)}`;
    const loiStr = `${this.loiMessage} ${this.replaceBlankString(loi)}`;
    const notesStr = `${this.notesMessage} ${this.replaceBlankString(notes)}`;
    const eventMessage = `${statusStr}\n${loiStr}\n${notesStr}`;
    const message = this.displayEngagementGuid ? `${eventMessage} ${engagementGuid}` : eventMessage;

    const endMessage: TextMessage = {
      id,
      senderName: 'system',
      senderId: 'system',
      senderType: TextMessageTypes.SESSION_END,
      message,
      timestamp: endDate,
      originalMessage: '',
      currentEngagement: false,
    };
    return endMessage;
  }

  private replaceBlankString(str: string) {
    if (str == null || str.length === 0) {
      return this.blankStr;
    } else {
      return str;
    }
  }

  private static engagementTypeMessage(engagementType: string) {
    if (engagementType == null) {
      return 'Engagement';
    }

    switch (engagementType.toLowerCase()) {
      default:
        return `${engagementType} Engagement`;
      case 'veecall':
        return 'VeeStudio Engagement';
      case 'bot':
        return 'Bot engagement';
      case 'chat':
        return 'VeeChat Engagement';
    }
  }

  private createHistoryDivider(textMessage: TextMessage): TextMessage {
    const timestamp = new Date(textMessage.timestamp.getTime() + 1);
    const divider: TextMessage = {
      id: textMessage.id + 1,
      senderName: 'system',
      senderId: 'system',
      senderType: TextMessageTypes.DIVIDER,
      message: this.currentEngagementMessage,
      timestamp,
      originalMessage: '',
      currentEngagement: false,
    };
    return divider;
  }
}
