<div class="d-flex flex-column justify-content-between h-100">
  <div class="overflow-y-auto">
    <div *ngFor="let group of supervisingGroups">
      <p class="lead my-2 px-2">{{ group }}</p>
      <div class="d-flex flex-column"
           *ngFor="let engagement of (supervisorEngagements | supervisorEngagementGroup : group)"
           (click)="changeSupervisingEngagement(engagement.visitor)">

        <app-supervisor-chat-row
          [class.selected]="supervisingEngagement.engagementId === engagement.visitor.engagementGuid"
          [currentOperator]="engagement.visitor.currentOperator"
          [engagementState]="(engagement.supPublicChatEnabled | async) ? SupervisorEngagementState.PUBLIC_CHAT : SupervisorEngagementState.PRIVATE_CHAT"
          [lastContactTime]="(engagement.lastContactTime | async)"
          [unreadMessages]="(engagement.unreadMessages | async)"
          [unreadPrivateMessages]="(engagement.unreadPrivateMessages | async)"
        >
        </app-supervisor-chat-row>
      </div>

      <div class="d-flex flex-column"
           *ngFor="let supervisingConversation of (supervisorVisitors | supervisorAsyncConversationGroup : group)"
           (click)="changeSupervisingAsyncConversation(supervisingConversation.conversation)">
        <app-supervisor-chat-row
          [class.selected]="supervisingEngagement.conversationId === supervisingConversation.conversation.id.Id"
          [currentOperator]="getAgentName(supervisingConversation.visitor.currentOperator)"
          [engagementState]="SupervisorEngagementState.SNOOPING"
          [lastContactTime]="supervisingConversation.conversation.lastCustomerMessageTimestamp"
          [unreadMessages]="supervisingConversation.conversation.unreadMessages"
          [unreadPrivateMessages]="0">
        </app-supervisor-chat-row>
      </div>

      <div class="d-flex flex-column"
           *ngFor="let visitor of (supervisorVisitors | supervisorVisitorFilter : group)"
           (click)="changeSupervisingEngagement(visitor)">

        <app-supervisor-chat-row
          [class.selected]="supervisingEngagement.engagementId === visitor.engagementGuid"
          [currentOperator]="visitor.currentOperator"
          [engagementState]="SupervisorEngagementState.SNOOPING"
          [assistTime]="visitor.assistTime">
        </app-supervisor-chat-row>
      </div>
    </div>
  </div>

  <div class="w-100" *ngIf="privateChatAvailable && helpRequestEngagements.size > 0">
    <p
      class="surface-red lead text-center">{{ "SUPERVISORCHATLIST_HEADER_AGENTREQUEST" | Translate : 'Agent Assistance Requests' }}</p>
    <div class="help-requests">
      <div class="d-flex flex-column"
           *ngFor="let helpRequestPair of helpRequestEngagements | keyvalue">

        <ng-container [ngSwitch]="helpRequestPair.value.type">
          <app-supervisor-chat-row *ngSwitchCase="HelpRequestType.EngagementHelpRequest"
                                   [class.selected]="supervisingEngagement.engagementId === helpRequestPair.value.engagementGuid"
                                   (click)="startSnooping(helpRequestPair.value, $event)"
                                   [currentOperator]="helpRequestPair.value.visitor.currentOperator"
                                   [engagementState]="SupervisorEngagementState.HELP_REQUEST"
                                   [assistTime]="helpRequestPair.value.requestTime"
                                   (joinPrivateChat)="helpAgent(helpRequestPair.value)">
          </app-supervisor-chat-row>
        </ng-container>
      </div>
    </div>

  </div>
</div>

