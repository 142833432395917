import {LoggingService} from '../services/logging.service';
import {StringUtils} from '../utils/string-utils';
import {EventEmitter} from '@angular/core';

export enum TextMessageTypes {
  ME,
  CUSTOMER,
  OTHER_AGENT,
  OTHER_CUSTOMER,
  TRANSFER_MESSAGE,
  ME_OLD_PRIVATE_CHAT,
  OTHER_AGENT_OLD_PRIVATE_CHAT,
  INTERNAL_MESSAGE,
  SESSION_START,
  DATE_DIVIDER,
  DIVIDER,
  SESSION_END,
}

export class TextMessage {
  public readonly id: number;
  public readonly timestamp: Date;
  public readonly message: string;
  public readonly senderName: string;
  public readonly senderId: string;
  public readonly senderType: TextMessageTypes;
  public readonly originalMessage: string;
  public readonly deliveryStatus?: DeliveryStatus = DeliveryStatus.Unknown;
  public readonly deliveryTimestamp?: Date;
  public readonly currentEngagement: boolean = true;
}

const PARTIAL_MESSAGE_INCREMENT = 0.01;

export class TextMessages {
  public get messages(): TextMessage[] {
    return this._messages;
  }

  private _messages: TextMessage[] = [];

  public get length(): number {
    return this._messages.length;
  }

  public *[Symbol.iterator]() {
    // Delegate to the messages iterator
    yield* this._messages;
  }

  private useDateDividers: boolean = true;

  public onNewMessage: EventEmitter<TextMessage> = new EventEmitter<TextMessage>();

  private static yesterday: string;
  private static today: string;
  private static formatDate: (Date) => string = function(date) { return date.toDateString(); };

  static {
    try {
      if (Intl && Intl.RelativeTimeFormat) {
        const rtf = new Intl.RelativeTimeFormat(navigator.language, { numeric: "auto" });
        TextMessages.yesterday = StringUtils.capitalizeUnicode(rtf.format(-1, "day")); // "yesterday"
        TextMessages.today = StringUtils.capitalizeUnicode(rtf.format(0, "day")); // "today"

        const dateTimeFormat = new Intl.DateTimeFormat(navigator.language, {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
        TextMessages.formatDate = function(date) {
          return dateTimeFormat.format(date);
        };
      }
    } catch (err) {
      // Logger may not have been created at this point.
      console.error("Failed to detect user's locale.", err);
    }
  }

  constructor(private readonly logging: LoggingService, oldMessages?: TextMessages) {
    if (oldMessages) {
      this._messages = oldMessages.messages;
      oldMessages.clear();
    }
  }

  static from(loggingService: LoggingService, textMessages: TextMessage[]) {
    const container = new TextMessages(loggingService);
    container.disableDateDividers();
    container.addMessages(textMessages);
    container.enableDateDividers();
    return container;
  }

  public clear(): void {
    this._messages = [];
  }

  public currentTranscript(): TextMessage[] {
    const transcript = this._messages.filter(m => m.currentEngagement);
    return TextMessages.createDateDividers(transcript);
  }

  public disableDateDividers() {
    this.useDateDividers = false;
    this._messages = this._messages.filter(m => m.senderType != TextMessageTypes.DATE_DIVIDER);
  }

  public enableDateDividers() {
    this.useDateDividers = true;
    this.addDateDividers();
  }

  public addMessage(textMessage: TextMessage): void {
    this._messages.push(textMessage);

    // Only re-run and sort if we get a single message from "the past"
    // The common case is sequentially adding messages from the future.
    if (this._messages.length === 1) {
      this.addDateDividers();
    } else {
      const currDate = TextMessages.getLocalComparisonDate(this._messages[this.length - 2].timestamp);
      const newDate = TextMessages.getLocalComparisonDate(textMessage.timestamp);

      if (newDate > currDate) {
        this.addDateDividers();
      }
    }

    this.onNewMessage.emit(textMessage);
  }

  public addMessages(textMessages: TextMessage[]): void {
    if (!textMessages) {
      return;
    }

    for (const m of textMessages) {
      this._messages.push(m);
      this.onNewMessage.emit(m);
    }

    this.addDateDividers();
  }

  public addHistory(textMessages: TextMessage[]): void {
    if (!textMessages) {
      return;
    }

    this._messages = this._messages.filter(x => x.currentEngagement);
    for (const m of textMessages) {
      this._messages.push(m);
    }
    this.addDateDividers();
  }

  public removeMessage(textMessage: TextMessage): void {
    this._messages = this._messages.filter(t => t != textMessage);
  }

  private addDateDividers(): void {
    if (!this.useDateDividers) {
      return;
    }
    this._messages = TextMessages.createDateDividers(this._messages);
  }

  private static createDateDividers(textMessages: TextMessage[]): TextMessage[] {
    textMessages = textMessages.filter(m => m.senderType != TextMessageTypes.DATE_DIVIDER);

    if (textMessages.length == 0) {
      return textMessages;
    }

    textMessages.sort(TextMessages.messageSortByDate);

    const today = TextMessages.getLocalComparisonDate(new Date());
    const yesterday = TextMessages.getLocalComparisonDate(new Date(new Date().getTime() - 24*60*60*1000));

    let currDate = 0;
    let dividerId = textMessages[0].id - 1 + PARTIAL_MESSAGE_INCREMENT;
    let max = textMessages.length;
    for (let i = 0; i < max; ++i) {
      const m = textMessages[i];
      const newDate = TextMessages.getLocalComparisonDate(m.timestamp);
      if (newDate > currDate) {
        const isToday = newDate === today;
        const isYesterday = newDate === yesterday;

        const divider = TextMessages.createDateDivider(m.timestamp, m.currentEngagement, dividerId, isToday, isYesterday);
        textMessages.splice(i, 0, divider);

        // Added dividers up to today
        if (isToday) {
          break;
        }

        ++max;
        ++i;
        currDate = newDate;
      }
      dividerId = m.id + PARTIAL_MESSAGE_INCREMENT;
    }

    return textMessages;
  }

  private static getLocalComparisonDate(date: Date): number {
    const localYear = date.getFullYear() * 10000;
    const localMonth = date.getMonth() * 100;
    const localDate = date.getDate();
    return localYear + localMonth + localDate;
  }

  private static createDateDivider(date: Date, currentEngagement: boolean,  id: number, isToday: boolean, isYesterday: boolean): TextMessage {
    let message: string;
    if (isToday && TextMessages.today) {
      message = TextMessages.today;
    } else if (isYesterday && TextMessages.yesterday) {
      message = TextMessages.yesterday;
    } else {
      message = TextMessages.formatDate(date);
    }

    const newDate = new Date();
    newDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    newDate.setHours(0,0, 0, 1);

    return {
      id,
      senderName: 'system',
      senderId: 'system',
      senderType: TextMessageTypes.DATE_DIVIDER,
      message,
      timestamp: newDate,
      originalMessage: '',
      deliveryStatus: DeliveryStatus.Sent,
      deliveryTimestamp: date,
      currentEngagement,
    };
  }

  private static messageSortById(a: TextMessage, b: TextMessage): number {
    return a.id - b.id;
  }

  private static messageSortByDate(a: TextMessage, b: TextMessage): number {
    return a.timestamp.getTime() - b.timestamp.getTime();
  }

  public updateMessage(lastMessage: TextMessage, updates: Partial<TextMessage>): TextMessage {
    const newMessage = this.findAndReplaceMessage(lastMessage, () => {
      return {
        ...lastMessage,
        ...updates
      }
    });

    if (newMessage) {
      const currDate = TextMessages.getLocalComparisonDate(lastMessage.timestamp);
      const newDate = TextMessages.getLocalComparisonDate(newMessage.timestamp);
      if (newDate > currDate || updates.timestamp) {
        this.addDateDividers();
      }
    } else {
      this.logging.warn(`Unable to update message for ${lastMessage.id} as it has not been added to the messages container`);
    }
    return newMessage;
  }

  private findAndReplaceMessage(previousMessage: TextMessage, factory: () => TextMessage): TextMessage {
    const idx = this._messages.indexOf(previousMessage);
    if (idx > -1) {
      const newMessage = factory();
      this._messages[idx] = newMessage;
      return newMessage;
    } else {
      this.logging.warn(`Unable to find message ${previousMessage.id} to update.`);
      return undefined;
    }
  }

  public findMessage(predicate: (TextMessage) => boolean): TextMessage {
    return this._messages.find(predicate);
  }

  public findLastMessage(predicate: (TextMessage) => boolean): TextMessage {
    // We can replace with findLast when we support that version of ecmascript
    // return this._messages.findLast(predicate());
    for (let i = this._messages.length - 1; i >= 0; i--) {
      const msg = this._messages[i];
      if (predicate(msg)) {
        return msg;
      }
    }

    return undefined;
  }
}

export enum DeliveryStatus {
  Unknown= 0,
  Sending = 1,
  Sent= 2,
  Delivered= 3,
  Read= 4,
  Failed = 5,
}
