import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { distinctUntilChanged, first } from 'rxjs/operators';
import { AuthService } from '../auth-service/auth.service';
import { BrowserConfiguration } from './browser-configuration';
import { BrowserTypes } from './browser-types';
import { ElectronService } from '../electron-service/electron.service';
import { PanelPositionMethod } from '../electron-service/panel-position-method';
import { Features, FeatureService } from '../feature-service/feature.service';
import { ScreenshotBackendService } from '../screenshot.backend.service';
import { SettingsService } from '../settings-service/settings.service';
import { VersionService } from '../version-service/version.service';
import { IBrowser } from './IBrowser';
import { BrowserServiceStatus, IBrowserService } from './IBrowserService';
import { IFrameBrowserProxy } from './iframe-browser-proxy';
import { ImageBrowser } from './image-browser';
import { Visitor } from '../visitor-service/visitor';

@Injectable({
  providedIn: 'root'
})
export class BrowserService implements IBrowserService {
  public readonly onStatus:Observable<number>;

  public requestTimeoutMs:number = 20000;

  private status$:Subject<number> = new Subject<number>();
  private timeoutRef:any = -1;

  private readonly browsers: Map<string, IBrowser> = new Map();

  constructor(
    private readonly electronService: ElectronService,
    private readonly authService: AuthService,
    private readonly screenshotBackendService: ScreenshotBackendService,
    private readonly versionService: VersionService,
    private readonly featureService: FeatureService,
    private readonly settingService: SettingsService
  ) {
    this.onStatus = this.status$.pipe(distinctUntilChanged());
    this.onStatus.subscribe(v => {

      clearTimeout(this.timeoutRef);

      if(v === BrowserServiceStatus.LOADING){
        this.timeoutRef = setTimeout(this.requestTimedOut.bind(this), this.requestTimeoutMs);
      }
    });
   }

   requestTimedOut(){
    this.setStatus(BrowserServiceStatus.FAILED);
   }

   setStatus(val: number){
     setTimeout(() => this.status$.next(val));
   }

  public getBrowser(browserId: string) : IBrowser {
    return this.browsers.get(browserId); 
  }

  public createBrowser(browserId: string, visitor: Visitor, isVeeStudio: boolean = false): IBrowser {
    if (this.browsers.has(browserId)) {
      return this.browsers.get(browserId);
     } else {
       let newBrowser: IBrowser;

       if (this.useElectronBrowser(isVeeStudio)) {
        newBrowser = this.electronService.createElectronBrowserProxy(this);
        const enableCustomerResize = this.settingService.getResourceSettingEnabledOrDefault('BROWSER_ELECTRONBROWSER_ENABLE_CUSTOMER_RESIZE', true);
        this.create(newBrowser, visitor, enableCustomerResize);
       } else if(this.useImageBrowser()) {
        newBrowser = new ImageBrowser(browserId, this, this.authService, this.screenshotBackendService);
        const enableCustomerResize = this.settingService.getResourceSettingEnabledOrDefault('BROWSER_IMAGEBROWSER_ENABLE_CUSTOMER_RESIZE', false);
        this.create(newBrowser, visitor, enableCustomerResize);
       } else {
        newBrowser = new IFrameBrowserProxy(browserId, this);
        const enableCustomerResize = this.settingService.getResourceSettingEnabledOrDefault('BROWSER_IFRAMEBROWSER_ENABLE_CUSTOMER_RESIZE', false);
        this.create(newBrowser, visitor, enableCustomerResize);
       }

       this.browsers.set(browserId, newBrowser);
       return newBrowser;
     }
  }

  private useElectronBrowser(isVeeStudio: boolean): boolean {
    // If using the old legacy electron API then we can old support veestudio
    const electronVersionTooOld = isVeeStudio && this.electronService.getUseLegacyAPI();
    
    return this.versionService.isElectron && this.featureService.has(Features.COBROWSE) && !electronVersionTooOld;
  }

  private useImageBrowser(): boolean {
    return !this.featureService.has(Features.COBROWSE);
  }

  private create(newBrowser: IBrowser, visitor: Visitor, enableCustomerResize: boolean) {
    const config: BrowserConfiguration = new BrowserConfiguration();
    const overlayPanelPositionMethod = this.settingService.getResourceOrDefault('ELECTRON_USERPANEL_POSITION_METHOD', PanelPositionMethod.OffsetFromCenter) as PanelPositionMethod;

    let emulateDevice = false;
    let deviceScaleFactor = 0;

    if (visitor) {
      visitor.details.pipe(first()).subscribe(details => {
        emulateDevice = details.isMobile == "1" || details.isTablet == "1";
        deviceScaleFactor = parseInt(details.DevicePixelRatio);
      });
    }

    config.title = "cobrowse-window";
    config.type = BrowserTypes.cobrowse;
    config.maxWidth = 0;
    config.maxHeight = 0;
    config.enableCustomerResize = enableCustomerResize;
    config.deviceScaleFactor = deviceScaleFactor || window.devicePixelRatio;
    config.emulateDevice = emulateDevice;
    config.showPanelOverlay = visitor ? !visitor.isMobileSdk : false;
    config.overlayPanelPositionMethod = overlayPanelPositionMethod;
    config.initialBounds = "";
    config.initialUrl = (visitor && !visitor.beingTransferred) ? visitor.lastPage : "";

    newBrowser.create(config);
  }

  public destroyBrowser(browserId: string) {
    const browser = this.getBrowser(browserId);
    if (browser) {
      this.browsers.delete(browserId);
      browser.dispose();
    }
  }

  public createDomSyncBrowser(): IBrowser {
    if (this.versionService.isElectron) {
      return this.electronService.createElectronBrowserProxy(this);
    } else {
      return new IFrameBrowserProxy("domsync-browser", this);
    }
  }
}
