import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Features, FeatureService} from '../../../../services/feature-service/feature.service';
import {
  faBan,
  faCircleCheck,
  faCircleXmark,
  faRightLeft,
  faTimesCircle,
  faUsers, faXmark
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-veechat-conversation-controls',
  templateUrl: './veechat-conversation-controls.component.html',
  styleUrls: ['./veechat-conversation-controls.component.scss']
})
export class VeechatConversationControlsComponent implements OnInit {
  @Input() ended: boolean = true;
  @Input() isBlocked: boolean;

  @Output() end = new EventEmitter<void>();
  @Output() blockCustomer = new EventEmitter<any>();
  @Output() transferCold = new EventEmitter<void>();
  @Output() transferWarm = new EventEmitter<void>();

  protected hasTransfer: boolean;

  constructor(private readonly featureService: FeatureService) {
  }

  ngOnInit() {
    this.hasTransfer = true;
  }

  protected readonly faTimesCircle = faTimesCircle;
  protected readonly faCircleCheck = faCircleCheck;
  protected readonly faCircleXmark = faCircleXmark;
  protected readonly faBan = faBan;
  protected readonly faRightLeft = faRightLeft;
  protected readonly faUsers = faUsers;
  protected readonly faXmark = faXmark;
}
