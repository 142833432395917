import {StatusFlag} from '../../enums/status-flag.enum';
import {VisitorStatus} from '../../enums/EngagementStatus.enum';
import {HubVisitor} from './HubVisitor';
import {Group} from '../../classes/visitor/Group';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {CrmData, CrmStructure} from '../crm-service/crm-category';
import {BrowsingHistory} from '../crm-service/browsing-history';
import {VisitorDetails} from '../../classes/visitor-details';
import {EngagementEvent} from '../crm-service/engagement-event';
import {TextMessage} from '../../classes/TextMessage';
import {TextMessageUtils} from '../../utils/text-message-utils';
import {BrowserInformation, Browsers} from '../../utils/browsers';
import {CallType} from '../../enums/call-type.enum';
import {LicenceType} from '../../enums/licence-type.enum';
import {CrmService} from '../crm-service/crm.service';
import {Channels} from './Channel';
import { VirtualBackground } from '../../classes/virtualBackground';

export class Visitor {
  userGuid: string;
  sessionGuid: string;

  site: string;
  flag: number;
  secondsOnPage: number;
  secondsOnSite: number;

  timeSinceLastNudge: number;

  lastPage: string;

  lastSection: string;

  lastSession: string;
  timeSinceLastEngagement: number;

  lockedBy: string;

  _assistTime: number;
  waitingSince: Date;

  culture: string;
  previousOperator: string;

  browser: string;
  countryCode: string;
  location: string;
  hasGetUserMedia: boolean;
  hasCrmData: boolean;
  isMaybeLater: boolean;

  timeStamp: string;

  currentOperator: string;
  engagementGuid: string;
  callDuration: number;

  lastResponseTime: Date;

  timeSinceLastHearbeat: number;

  // TODO: rename or remove these
  isMultiparty = false;
  beingTransferred = false;

  agentRequestingAssistance = false;
  agentRequestAssistTime: Date;
  transcript: TextMessage[];
  agentGroup: Group[];
  targettedSection: string;
  groupId: number = -1;

  private crmData$: BehaviorSubject<CrmStructure> = new BehaviorSubject({list: []});
  public crmData: Observable<CrmStructure> = this.crmData$.asObservable();
  browsingHistory: BehaviorSubject<BrowsingHistory[]> = new BehaviorSubject<BrowsingHistory[]>([]);
  details: ReplaySubject<VisitorDetails> = new ReplaySubject<VisitorDetails>(1);
  sessionHistory: BehaviorSubject<EngagementEvent[]> = new BehaviorSubject<EngagementEvent[]>([]);

  virtualBackground: BehaviorSubject<VirtualBackground> = new BehaviorSubject<VirtualBackground>(new VirtualBackground());

  channel: Channels;
  currentUsername: string;

  get inColdTransferState() {
    return this.beingTransferred && this.isAsync && !this.isWaiting;
  }

  constructor(hubVisitor: HubVisitor, groupId: number = -1) {
    this.userGuid = hubVisitor.UserGuid;
    this.sessionGuid = hubVisitor.SessionGuid;
    this.waitingSince = new Date();
    this.site = hubVisitor.Site;
    this.flag = hubVisitor.flag;

    this.secondsOnPage = hubVisitor.SecondsOnPage;
    this.secondsOnSite = hubVisitor.SecondsOnSite;
    this.timeSinceLastNudge = hubVisitor.TimeSinceLastNudge;

    this.lastPage = hubVisitor.LastPage;

    this.lastSection = hubVisitor.LastSection;
    this.lastSession = hubVisitor.LastSession;

    this.timeSinceLastEngagement = hubVisitor.TimeSinceLastEngagement;

    this.lockedBy = hubVisitor.LockedBy;

    this._assistTime = hubVisitor.AssistTime;

    this.culture = hubVisitor.Culture;
    this.previousOperator = hubVisitor.PreviousOperator;
    this.browser = hubVisitor.Browser;
    this.countryCode = hubVisitor.CountryCode;
    this.location = hubVisitor.Location;

    this.hasGetUserMedia = hubVisitor.HasGetUserMedia > 0;
    this.hasCrmData = hubVisitor.HasCrmData;
    this.isMaybeLater = hubVisitor.IsMaybeLater;

    this.engagementGuid = hubVisitor.EngagementGuid;
    this.agentRequestingAssistance = hubVisitor.AgentRequestingAssistance;
    this.transcript = TextMessageUtils.CreateTextMessages(hubVisitor.Transcript, '');
    this.agentGroup = hubVisitor.AgentGroup;
    this.targettedSection = hubVisitor.TargettedSection;
    this.groupId = groupId;

    this.timeStamp = hubVisitor.TimeStamp;

    this.currentOperator = hubVisitor.CurrentOperator;
    this.currentUsername = hubVisitor.CurrentUsername;
    this.callDuration = hubVisitor.CallDuration;

    this.timeSinceLastHearbeat = hubVisitor.TimeSinceLastHeartbeat;

    if (hubVisitor.LastResponseTime) {
      this.lastResponseTime = new Date(hubVisitor.LastResponseTime);
    }

    this.channel = hubVisitor.ChannelType;
    this.beingTransferred = hubVisitor.BeingTransferred;
  }

  public updateVisitor(hubVisitor: HubVisitor, groupId: number) {
    this.flag = hubVisitor.flag;

    this.secondsOnPage = hubVisitor.SecondsOnPage;
    this.secondsOnSite = hubVisitor.SecondsOnSite;
    this.timeSinceLastNudge = hubVisitor.TimeSinceLastNudge;

    this.lastPage = hubVisitor.LastPage;
    this.lastSection = hubVisitor.LastSection;

    // These may change because caps haven't been logged so need to get updated here
    this.culture = hubVisitor.Culture;
    this.previousOperator = hubVisitor.PreviousOperator;
    this.browser = hubVisitor.Browser;
    this.countryCode = hubVisitor.CountryCode;
    this.location = hubVisitor.Location;

    this.hasGetUserMedia = hubVisitor.HasGetUserMedia > 0;

    this.agentRequestingAssistance = hubVisitor.AgentRequestingAssistance;
    this.engagementGuid = hubVisitor.EngagementGuid;

    if (!this.isAsync) {
      this.transcript = TextMessageUtils.CreateTextMessages(hubVisitor.Transcript, '');
    }

    this.agentGroup = hubVisitor.AgentGroup;
    this.targettedSection = hubVisitor.TargettedSection;
    this.groupId = groupId;

    this.lockedBy = hubVisitor.LockedBy;

    this._assistTime = hubVisitor.AssistTime;

    this.currentOperator = hubVisitor.CurrentOperator;
    this.currentUsername = hubVisitor.CurrentUsername;
    this.callDuration = hubVisitor.CallDuration;

    this.timeSinceLastHearbeat = hubVisitor.TimeSinceLastHeartbeat;

    if (hubVisitor.LastResponseTime) {
      this.lastResponseTime = new Date(hubVisitor.LastResponseTime);
    }

    if (this.isAsync) {
      this.waitingSince = this.lastResponseTime;
    }
  }

  public get key(): string {
    return `${this.userGuid}+${this.sessionGuid}`;
  }

  public removeMultichatFlag(): void {
    this.flag &= ~StatusFlag.IsMultichat;
  }

  public get visitorCRMData(): CrmStructure{
    return this.crmData$.value;
  }

  get assistTime(): number {
    if (this.beingTransferred || this.isMultiparty) {
       return Math.floor((new Date().getTime() - this.waitingSince.getTime()) / 1000);
    }
    return this._assistTime;
  }

  get requiresAssistance(): boolean {
    return !!(this.flag & StatusFlag.RequiresAssistance);
  }

  get isWaiting(): boolean {
    return !!(this.flag & StatusFlag.IsWaiting);
  }

  get onCallback(): boolean {
    return !!(this.flag & StatusFlag.OnCallback);
  }

  get isEngaged(): boolean {
    return !!(this.flag & StatusFlag.IsEngaged);
  }

  get isMultichat(): boolean {
    return !!(this.flag & StatusFlag.IsMultichat);
  }

  get isInactive(): boolean {
    return !!(this.flag & StatusFlag.PageInvisible); //&& this.timeSinceLastHearbeat > 2;
  }

  get isAsync(): boolean {
    return !!(this.flag & StatusFlag.AsyncChat);
  }

  get isBotEscalation(): boolean {
    return !!(this.flag & StatusFlag.BotEscalation);
  }

  get isMobileSdk(): boolean {
    return this.isIosSdk || this.isAndroidSdk;
  }

  get isIosSdk(): boolean {
    return this.browser && this.browser.includes('v24ios_sdk');
  }

  get isAndroidSdk(): boolean {
    return this.browser && this.browser.includes('v24android_sdk');
  }

  private _customerName: string;
  get customerName(): string {
    return this._customerName;
  }

  get visitorStatus(): VisitorStatus {
    // bit of a hack here
    if (this.beingTransferred) {
      if (this.isAsync && !this.isWaiting) {
        return VisitorStatus.IdleTransfer;
      }

      return VisitorStatus.Transferring;
    }

    if (this.isMultiparty) {
      return VisitorStatus.Joining;
    }

    if (this.agentRequestingAssistance) {
      return VisitorStatus.AgentRequestingHelp;
    }

    if (this.isAsync) {
      if (this.channel === Channels.WhatsApp) {
        return VisitorStatus.WhatsApp;
      } else if (this.channel === Channels.Messenger) {
         return VisitorStatus.Messenger;
      } else {
        return VisitorStatus.Sms;
      }
    } else if (this.isEngaged) {
      return VisitorStatus.Engaged;
    } else if (this.requiresAssistance) {
      if (this.onCallback) {
        return VisitorStatus.Callback;
      } else if (this.isWaiting) {
        return VisitorStatus.Waiting;
      } else {
        return VisitorStatus.Assist;
      }
    } else if (this.onCallback) {
      return VisitorStatus.Callback;
    } else if (this.isMaybeLater) {
      return VisitorStatus.MaybeLater;
    } else {
      return VisitorStatus.Timeout;
    }
  }

  get engagementType(): string {
    return this.isMultichat ? 'Chat' : 'VeeCall';
  }

  get callType(): CallType {
    if (this.isMobileSdk) {
      if (this.isMultichat) {
        return CallType.Text;
      } else {
        return CallType.MobileSDKWebrtc;
      }
    } else {
      if (this.isMultichat) {
        return CallType.Text;
      } else if (this.canSupportWebrtc()) {
        return CallType.WebRTC;
      } else {
        return CallType.TextDowngrade;
      }
    }
  }

  // work out callType that we want the visitor panel to be
  // this is because they may have asked for a multichat and got a veestudio agent
  // or veestudio agent and got a veechat
  // Default call type to text for non-veestudio agents
  public actualCallType(agentsLicenceType:LicenceType) : CallType {
    let callType: CallType = CallType.Text;
    if (agentsLicenceType === LicenceType.VeeStudio) {
      if (this.isMobileSdk) {
        callType = CallType.MobileSDKWebrtc;
      }
      else if (!this.hasGetUserMedia) {
        callType = CallType.TextDowngrade;
      }
      else {
        callType = CallType.WebRTC;
      }
    }
    return callType;
  }

  public canSupportWebrtc(): boolean {
    return this.hasGetUserMedia && !this.browserInfo.webrtcBlocked;
  }

  public setCrmData(data: CrmStructure, crmService: CrmService): void {
    this.crmData$.next(data);
    this.setVisitorName(data as CrmData, crmService);
  }

  private setVisitorName(crmData: CrmData, crmService: CrmService): void {
    const firstName = crmService.getCrmFieldValue(crmData, 'Customer Information', 'Firstname');
    const lastName = crmService.getCrmFieldValue(crmData, 'Customer Information', 'Lastname');
    this._customerName = (firstName === undefined ? '' : `${firstName} `) + (lastName === undefined ? '' : lastName);
  }

  private browserInfo_: BrowserInformation;
  get browserInfo(): BrowserInformation {
    if (this.browserInfo_) {
      return this.browserInfo_;
    } else {
      const newInfo = Browsers.getBrowserInfo(this.browser);
      if (!Browsers.UnknownBrowser(newInfo)) {
        this.browserInfo_ = newInfo;
      }
      return newInfo;
    }
  }

  getTimeSinceResponse(): number {
    if (this.lastResponseTime) {
      return Math.floor(Math.max(0, Date.now() - this.lastResponseTime.getTime()) / 1000);
    } else if (this.transcript.length > 0) {
      const lastMessageTimestamp = this.transcript[this.transcript.length - 1].timestamp;
      return Math.floor(Math.max(0, Date.now() - lastMessageTimestamp.getTime()) / 1000);
    } else {
      return 0;
    }
  }
}
