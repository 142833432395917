import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CrmField } from '../../../services/crm-service/crm-field';
import { CheckListField } from '../../../classes/checklist/CheckListField';

@Component({
  selector: 'app-crm-field-email',
  templateUrl: './crm-field-email.component.html',
  styleUrls: ['./crm-field-email.component.scss']
})
export class CrmFieldEmailComponent implements OnInit {
  private _field: CrmField;

  @Input() set field(field: CrmField) {
    this._field = field;
  }

  get field(): CrmField {
    return this._field;
  }

  @Input() disableCrm: boolean;

  @Output() saveField: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  public onFieldValueChange(event) {
    this.field.value = event.target.value;
    this.saveField.emit();
  }
}
