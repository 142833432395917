<table class="table table-fixed table-borderless table-hover w-100">
  <tbody class="table-body">
    <tr>
      <td class="font-weight-bold">
        <fa-icon [icon]="faFile" class="pr-2"></fa-icon>
        {{ "VISITORDETAILS_ROWNAME_CURRENTPAGE" | Translate : 'Current Page'}}
      </td>
      <td class="visitor-current-page text-right text-break"><p title="{{page}}">{{page}}</p></td>
    </tr>

    <tr>
      <td class="font-weight-bold">
        <fa-icon [icon]="faPuzzlePiece" class="pr-2"></fa-icon>
        {{ "VISITORDETAILS_ROWNAME_SECTION" | Translate : 'Section' }}
      </td>
      <td class="visitor-current-section text-right text-break"><p>{{section}}</p></td>
    </tr>

    <tr>
      <td class="font-weight-bold">
        <fa-icon [icon]="faGlobe" class="pr-2"></fa-icon>
        {{ "VISITORDETAILS_ROWNAME_BROWSER" | Translate : 'Browser' }}
      </td>
      <td class="visitor-browser text-right text-break"><p>{{browser}}</p></td>
    </tr>

    <tr>
      <td class="font-weight-bold">
        <fa-icon [icon]="faMobile" class="pr-2"></fa-icon>
        {{ "VISITORDETAILS_ROWNAME_DEVICETYPE" | Translate : 'Device Type' }}
      </td>
      <td class="visitor-device-type text-right text-break"><p>{{deviceType}}</p></td>
    </tr>

    <tr>
      <td class="font-weight-bold">
        <fa-icon [icon]="faBuilding" class="pr-2"></fa-icon>
        {{ "VISITORDETAILS_ROWNAME_ORGANISATION" | Translate : 'Organisation' }}
      </td>
      <td class="visitor-org text-right text-break "><p>{{organisation}}</p></td>
    </tr>

    <tr>
      <td class="font-weight-bold">
        <fa-icon [icon]="faLocationArrow" class="pr-2"></fa-icon>
        {{ "VISITORDETAILS_ROWNAME_LOCATION" | Translate : 'Location' }}
      </td>
      <td class="visitor-location text-right text-break"><p>{{location}}</p></td>
    </tr>

    <tr>
      <td class="font-weight-bold">
        <fa-icon [icon]="faAddressCard" class="pr-2"></fa-icon>
        {{ "VISITORDETAILS_ROWNAME_PREVIOUSOPERATOR" | Translate : 'Previous Operator' }}
      </td>
      <td class="visitor-prev-op text-right text-break"><p>{{prevOp}}</p></td>
    </tr>

    <tr>
      <td class="font-weight-bold">
        <fa-icon [icon]="faMapMarker" class="pr-2"></fa-icon>
        {{ "VISITORDETAILS_ROWNAME_IDENTITY" | Translate : 'IP/Identity' }}
      </td>
      <td class="visitor-ip text-right text-break"><p>{{ip}}</p></td>
    </tr>

    <tr>
      <td class="font-weight-bold">
        <fa-icon [icon]="faLink" class="pr-2"></fa-icon>
        {{ "VISITORDETAILS_ROWNAME_REFERRER" | Translate : 'Referrer' }}
      </td>
      <td class="visitor-referrer text-right text-break"><p>{{referrer}}</p></td>
    </tr>
  </tbody>

</table>
