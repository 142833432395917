import {EventEmitter, Injectable} from '@angular/core';
import {CrmBackendService} from './crm.backend.service';
import {LoggingService} from '../logging.service';
import {AuthService} from '../auth-service/auth.service';
import {BehaviorSubject, forkJoin, Observable, of, switchMap} from 'rxjs';
import {CrmCategory, CrmData, CrmDataCategory, CrmStructure} from './crm-category';
import {BrowsingHistory} from './browsing-history';
import {EngagementEvent} from './engagement-event';
import {ContactFilterData, CrmContact} from './crm-contact';
import {CrmDataField, CrmField} from './crm-field';
import {CheckListField} from '../../classes/checklist/CheckListField';
import * as moment from 'moment';
import {Opportunity, OpportunityStatus} from './opportunity';
import {AppointmentFilterData, AppointmentType, InsertAppointment} from './appointment';
import {SessionTypes} from './sessionTypes';
import {MatchedCustomer} from './matched-customer';
import {EngagementChatHistory} from './EngagementChatHistory';
import {EngageUserMethod, UserEngagementRequest} from './user-engagement-request';
import Guid from '../../classes/Guid';
import {TranslatePipe} from '../../filters/Translate.pipe';
import {SettingsService} from '../settings-service/settings.service';
import {Agent} from '../../classes/agent';
import {ReassignmentResponse} from '../../enums/response-code.enum';
import {EngagementPurchaseHistory} from './engagement-purchase-history';
import {shareReplay} from 'rxjs/operators';
import {ChatHistory} from './ChatHistory';

@Injectable({
  providedIn: 'root'
})
export class CrmService {
  public crmStructure: BehaviorSubject<CrmStructure> = new BehaviorSubject(null);
  public opportunityStatus: BehaviorSubject<OpportunityStatus[]> = new BehaviorSubject(null);
  public siteSections: BehaviorSubject<string[]> = new BehaviorSubject(null);
  public sessionTypes: BehaviorSubject<SessionTypes[]> = new BehaviorSubject(null);
  public defaultChecklist: Observable<CheckListField[]>;

  constructor(
    private crmBackendService: CrmBackendService,
    private logger: LoggingService,
    private authService: AuthService,
    private settingsService: SettingsService,
    private translate: TranslatePipe
  ) { }

  public loadAll(): Observable<unknown> {
    const obs = forkJoin([
      this.loadCrmDataStructure(),
      this.loadOpportunityStatus(),
      this.loadDefaultChecklist(),
      this.loadSiteSections(),
      this.loadSessionTypes()
    ]);

    obs.subscribe();
    return obs as Observable<unknown>;
  }

  public loadDefaultChecklist(): Observable<CheckListField[]> {
    this.defaultChecklist = this.getChecklistInternal('Default').pipe(shareReplay(1));
    return this.defaultChecklist;
  }

  public loadCrmDataStructure(): Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        this.crmBackendService.loadCRMDataStructure(this.authService.currentAgent.value.authToken).subscribe(
          structure => {
            this.crmStructure.next(structure);
            observer.next(true);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting crm structure', err);
            observer.next(false);
            observer.complete();
          }
        );
      }
    );
  }

  public loadAppointmentTypes(): Observable<AppointmentType[]> {
    return new Observable<AppointmentType[]>( observer => {
      this.crmBackendService.loadAppointmentTypes(this.authService.currentAgent.value.authToken).subscribe(
        types => {
          observer.next(types);
          observer.complete();
        },
        err => {
          this.logger.error('Error getting Appointment Types', err);
          observer.next(null);
          observer.complete();
        }
      );
    });
  }

  public loadAgents(): Observable<Agent[]> {
    return new Observable<Agent[]>( observer => {
      this.crmBackendService.loadAgents(this.authService.currentAgent.value.authToken).subscribe(
        agents => {
          observer.next(agents);
          observer.complete();
        },
        err => {
          this.logger.error('Error getting Agents List', err);
          observer.next(null);
          observer.complete();
        }
      );
    });
  }

  public getAvailableAgents(startTime: string, endTime: string): Observable<Agent[]> {
    return new Observable<Agent[]>( observer => {
      this.crmBackendService.getAvailableAgents(this.authService.currentAgent.value.authToken, startTime, endTime).subscribe(
        agents => {
          observer.next(agents);
          observer.complete();
        },
        err => {
          this.logger.error('Error getting Available Agents List', err);
          observer.next(null);
          observer.complete();
        }
      );
    });
  }


  public loadOpportunityStatus(): Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        this.crmBackendService.loadOpportunityStatus(this.authService.currentAgent.value.authToken).subscribe(
          oppStatus => {
            this.opportunityStatus.next(oppStatus);
            observer.next(true);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting opportunity status', err);
            observer.next(false);
            observer.complete();
          }
        );
      }
    );
  }

  public getOpportunitiesForContact(vee24Guid: string): Observable<Opportunity[]> {
    return new Observable(
      observer => {
        this.crmBackendService.getOpportunitiesForContact(this.authService.currentAgent.value.authToken, vee24Guid).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting Opportunities for Contact', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  filterOpportunityData(vee24Guid: string, customerName: string, opportunityTitle: string, opportunityStatus: number): Observable<Opportunity[]> {
    return new Observable(
      observer => {
        this.crmBackendService.filterOpportunityData(this.authService.currentAgent.value.authToken, vee24Guid, customerName, opportunityTitle, opportunityStatus).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error Filtering Opportunities for Contact', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  public getOpportunityById(opportunityId: string): Observable<Opportunity> {
    return new Observable(
      observer => {
        this.crmBackendService.getOpportunityById(this.authService.currentAgent.value.authToken, opportunityId).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting Opportunity By Id', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  public insertUpdateOpportunity(vee24Guid: string, opportunityTitle: string, opprtunityProduct: string, opportunityNotes: string, opportunityStatus: string, opportunityValue: string, opportunityId: string): Observable<boolean> {
    return new Observable(
      observer => {
        this.crmBackendService.insertUpdateOpportunity(this.authService.currentAgent.value.authToken, vee24Guid, opportunityTitle
          , opprtunityProduct, opportunityNotes, opportunityStatus, opportunityValue, opportunityId).subscribe(
            data => {
              observer.next(data);
              observer.complete();
            },
            err => {
              this.logger.error('Error inserting Opportunity Data', err);
              observer.next(null);
              observer.complete();
            }
          );
      }
    );
  }

  public loadCustomerData(userGuid: string): Observable<CrmStructure> {
    return new Observable(
      observer => {
        this.crmBackendService.loadCustomerData(this.authService.currentAgent.value.authToken, userGuid).subscribe(
          data => {
            observer.next(this.createCustomerDataStructure(data));
            observer.complete();
          },
          err => {
            this.logger.error('Error getting crm data', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  public getSessionHistory(userGuid: string): Observable<EngagementEvent[]> {
    return new Observable(
      observer => {
        this.crmBackendService.getSessionHistory(this.authService.currentAgent.value.authToken, userGuid).subscribe(
          data => {
            if (data) {
              data.sort((e1: EngagementEvent, e2: EngagementEvent) => {
                const e1Time = moment.utc(e1.StartTime, 'DD-MM-YYYY HH:mm:ss').local();
                const e2Time = moment.utc(e2.StartTime, 'DD-MM-YYYY HH:mm:ss').local();

                return e2Time.valueOf() - e1Time.valueOf();
              });

              observer.next(data);
            } else {
              observer.next([]);
            }

            observer.complete();
          },
          err => {
            this.logger.error('Error getting session history data', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  public getChatTranscript(engagementGuid: string): Observable<EngagementChatHistory[]> {
    return new Observable(
      observer => {
        this.crmBackendService.getEngagementChatHistory(this.authService.currentAgent.value.authToken, engagementGuid).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting engagement chat history data', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  public getChatHistory(vee24guid: Guid, engagementGuid: Guid, count: number = 100, watermark: number = -1): Observable<ChatHistory[]> {
    return new Observable(
      observer => {
        this.crmBackendService.getChatHistory(this.authService.currentAgent.value.authToken, vee24guid, engagementGuid, count, watermark).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting engagement chat history data', err);
            observer.next([]);
            observer.complete();
          }
        );
      }
    );
  }

  public getPurchaseHistory(engagementGuid: string): Observable<EngagementPurchaseHistory[]> {
    return new Observable(
      observer => {
        this.crmBackendService.getPurchaseHistory(this.authService.currentAgent.value.authToken, engagementGuid).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting engagement purchase history data', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }


  public getBrowsingHistory(userGuid: string, sessionGuid: string): Observable<BrowsingHistory[]> {
    return new Observable(
      observer => {
        this.crmBackendService.getBrowsingHistory(this.authService.currentAgent.value.authToken, userGuid, sessionGuid).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting browsing history', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  public filterContactData(customerName: string, customerEmail: string, customerPhone: string, pagesize: number, pagenumber: number, startDate: string, endDate: string): Observable<ContactFilterData> {
    return new Observable(
      observer => {
        this.crmBackendService.filterContactData(this.authService.currentAgent.value.authToken, customerName, customerEmail, customerPhone, pagesize, pagenumber, startDate, endDate).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting contact data', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  public checkEmailDuplication(data: CrmStructure, userGuid: string): Observable<CrmStructure> {
    const email = this.getCrmFieldValue(this.extractCustomerData(data), 'Customer Information', 'Email');

    return new Observable(
      observer => {
        this.crmBackendService.checkEmailDuplication(this.authService.currentAgent.value.authToken, email, userGuid).subscribe(
          customer => {
            if (customer && Array.from(customer.list).length > 0) {
              observer.next(this.createCustomerDataStructure(customer));
            } else {
              observer.next(null);
            }
            observer.complete();
          },
          err => {
            this.logger.error('Error checking customer email duplication', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  public setCrmData(crmData: CrmStructure, userGuid: string): Observable<boolean> {
    return new Observable(
      observer => {
        this.crmBackendService.setCrmData(this.authService.currentAgent.value.authToken, userGuid, this.extractCustomerData(crmData)).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error setting crm data', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  public createCustomerDataStructure(data: CrmData): CrmStructure {
    const customerStructure = new CrmStructure();
    customerStructure.list = [];

    const crmStructure = this.crmStructure.value;

    if (!crmStructure?.list) {
      this.logger.error('list is not defiend');
      return customerStructure;
    }

    if (data.list == null) {
      data.list = [];
    }

    for (const category of Array.from(crmStructure.list)) {
      const newCategory = new CrmCategory();
      newCategory.name = category.name;
      newCategory.fields = [];

      const resourceCategoryKey = `CRM_CATEGORY_${category.name}`;
      newCategory.translatedName = this.settingsService.getResourceOrDefault(resourceCategoryKey, category.name);

      const customerCategories: CrmDataCategory[] = data.list.filter(cat => cat.name === category.name);
      const customerFields: CrmDataField[] = customerCategories.length === 0 ? [] : customerCategories[0].fields;

      for (const field of Array.from(category.fields)) {

        const newField = new CrmField();
        newField.name = field.name;
        newField.category = category.name;
        newField.required = field.required;
        newField.readOnly = field.readOnly;
        newField.type = field.type;
        newField.url = field.url;
        newField.value = field.value;
        newField.choices = field.choices;
        newField.cannedText = field.cannedText;

        const resourceKey = `CRM_FIELD_${category.name}:${field.name}`;
        const translatedTitleDefault = newField.name ? newField.name.replace(/([A-Z]+)/g, ' $1').trim() : '';
        newField.translatedTitle = this.settingsService.getResourceOrDefault(resourceKey, translatedTitleDefault);

        for (const fld of customerFields) {
          if (fld.name === newField.name) {
            newField.value = fld.value;
            break;
          }
        }
        newCategory.fields.push(newField);
      }
      customerStructure.list.push(newCategory);
    }

    return customerStructure;
  }

  public extractCustomerData(data: CrmStructure): CrmData {
    const newData = new CrmData();
    newData.list = [];

    for (const cat of data.list) {
      const category = new CrmDataCategory();
      category.name = cat.name;
      category.fields = [];

      for (const fld of cat.fields) {
        const newField = new CrmDataField();
        newField.name = fld.name;
        newField.value = fld.value;

        category.fields.push(newField);
      }
      newData.list.push(category);
    }

    return newData;
  }

  public getCrmFieldValue(data: CrmData, categoryName: string, fieldName: string): string {
    for (const category of data.list) {
      if (category.name === categoryName) {
        for (const field of category.fields) {
          if (field.name === fieldName) {
            if (field.value !== null) {
              return field.value;
            }
          }
        }
      }
    }
  }

  public getChecklistFields(checklistName: string): Observable<CheckListField[]> {
    if (checklistName === 'Default') {
      return this.defaultChecklist;
    } else {
      return this.getChecklistInternal(checklistName)
        .pipe(switchMap(x => {
          if (x == null || x.length === 0) {
            return this.defaultChecklist;
          } else {
            return of(x);
          }
        }));
    }
  }

  private getChecklistInternal(checklistName: string): Observable<CheckListField[]>  {
    return new Observable(
      observer => {
        this.crmBackendService.getChecklistFields(this.authService.currentAgent.value.authToken, checklistName).subscribe(
          checklist => {
            observer.next(checklist);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting crm data', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  validateChecklist(checklistFields: CheckListField[], data: CrmStructure) {
    for (const checklistField of checklistFields) {
      const crmField = checklistField.CRMField.split(':')
      const fieldValue = this.getCrmFieldValue(this.extractCustomerData(data), crmField[0], crmField[1]);
      switch (checklistField.CheckAction) {
        case 'Not Null': {
          if (fieldValue == '' || fieldValue == null || fieldValue == undefined) {
            checklistField.IsRulePassed = false;
          }
          else {
            checklistField.IsRulePassed = true;
          }
        }
          break;
        case 'Equals': {
          checklistField.IsRulePassed = (fieldValue === checklistField.CheckValue);
        }
          break;
        case 'Date Greater Than': {
          if (fieldValue == '' || fieldValue == null || fieldValue == undefined) {
            checklistField.IsRulePassed = false;
          }
          else {
            //checklistField.IsRulePassed = true;
            if (checklistField.CheckValue == '' || checklistField.CheckValue == null || checklistField.CheckValue == undefined) {
              checklistField.IsRulePassed = true;
            }
            else {
              const fieldValueDate = moment.utc(fieldValue, 'DD-MM-YYYY HH:mm:ss').local().toDate();
              const checkValue = moment.utc(checklistField.CheckValue, 'YYYY/MM/DD').local().toDate();;
              checklistField.IsRulePassed = fieldValueDate > checkValue;
            }
          }
        }
          break;
        case 'Date Greater Than Now': {
          if (fieldValue == '' || fieldValue == null || fieldValue == undefined) {
            checklistField.IsRulePassed = false;
          }
          else {
            const fieldValueDate = moment.utc(fieldValue, 'DD-MM-YYYY HH:mm:ss').local().toDate();
            const checkValue = new Date();
            checklistField.IsRulePassed = fieldValueDate > checkValue;
          }
        }
          break;
        case 'Regular Expression': {
          const rg = new RegExp(checklistField.CheckValue);
          checklistField.IsRulePassed = rg.test(fieldValue);
        }
          break;
        default: {
          checklistField.IsRulePassed = true;
        }
      }
    }
  }
  //------------------------------------------------------------- Appointment Start ------------------------------------------------------------
  public loadSiteSections(): Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        this.crmBackendService.loadSiteSections(this.authService.currentAgent.value.authToken).subscribe(
          section => {
            this.siteSections.next(section);
            observer.next(true);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting site sections', err);
            observer.next(false);
            observer.complete();
          }
        );
      }
    );
  }
  public loadSessionTypes(): Observable<boolean> {
    return new Observable<boolean>(
      observer => {
        this.crmBackendService.loadSessionTypes(this.authService.currentAgent.value.authToken).subscribe(
          session => {
            this.sessionTypes.next(session);
            observer.next(true);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting Session Types', err);
            observer.next(false);
            observer.complete();
          }
        );
      }
    );
  }

  public filterAppointmentData(appointmentTypeId: number, callType: string, status: number, agents: string[], from: string, to: string, pagesize: number, pagenumber: number): Observable<AppointmentFilterData> {
    return new Observable(
      observer => {
        this.crmBackendService.filterAppointmentData(this.authService.currentAgent.value.authToken, appointmentTypeId,callType, status, agents, from, to, pagesize, pagenumber).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting appointment data', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  public updateAppointmentStatus(appointmentGuid: string, status: number): Observable<boolean> {
    return new Observable(
      observer => {
        this.crmBackendService.updateAppointmentStatus(this.authService.currentAgent.value.authToken, appointmentGuid, status).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting appointment data', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  public updateAppointmentNotes(appointmentGuid: string, notes: string): Observable<boolean> {
    return new Observable(
      observer => {
        this.crmBackendService.updateAppointmentNotes(this.authService.currentAgent.value.authToken, appointmentGuid, notes).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error getting appointment data', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  public reassignAppointmentAgent(appointmentGuid: String, agent: String): Observable<ReassignmentResponse> {
    return new Observable(
      observer => {
        this.crmBackendService.reassignAppointmentAgent(this.authService.currentAgent.value.authToken, appointmentGuid, agent, this.authService.currentAgent.value.username).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error reassigning appointment', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }


  public insertAppointment(appoinment: InsertAppointment, engagementGuid: string): Observable<boolean> {
    return new Observable(
      observer => {
        this.crmBackendService.insertAppointment(this.authService.currentAgent.value.authToken, appoinment, engagementGuid).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error inserting appointment', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

   public updateAppointment(appoinment: InsertAppointment, engagementGuid: string): Observable<boolean> {
    return new Observable(
      observer => {
        this.crmBackendService.updateAppointment(this.authService.currentAgent.value.authToken, appoinment, engagementGuid).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error updating appointment', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  public deleteAppointment(appointmentGuid: String): Observable<boolean> {
    return new Observable(
      observer => {
        this.crmBackendService.deleteAppointment(this.authService.currentAgent.value.authToken, appointmentGuid).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error deleting appointment', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  isValidAppointment(appointment: InsertAppointment): InsertAppointment {
    if (appointment == undefined) {
      appointment = new InsertAppointment();
      appointment.validationError = "";
      appointment.isEditing = false;
      return appointment;
    }
    appointment.validationError = "";
    if (appointment.isEditing == false) {
      return appointment;
    }
    if (appointment.callType == undefined || appointment.callType == "None") {
      return appointment;
    }
    else {
      if (appointment.siteSection == undefined || appointment.siteSection == "None") {
        appointment.validationError = "Select Site Section in Appointment Tab";
        return appointment;
      }
      else {
        var now = new Date();
        var appointmentDate = appointment.startDate;
        var startDateStr: string = moment(appointmentDate + " " + appointment.startTime).format("YYYY-MM-DD HH:mm").toString()
        var startDate = new Date(startDateStr);

        appointmentDate = appointment.endDate == undefined ? appointmentDate : appointment.endDate;
        var endDateStr: string = moment(appointmentDate + " " + appointment.endTime).format("YYYY-MM-DD HH:mm").toString()
        var endDate = new Date(endDateStr);
        if (startDate < now) {
          appointment.validationError = this.translate.transform("APPOINTMENT_VALIDATION_ONLYFUTUREDATES","Only Future Appointment Dates are allowed.");
          return appointment;
        }
        else if (endDate < startDate) {
          appointment.validationError = this.translate.transform("APPOINTMENT_VALIDATION_ENDDATEMUSTBEGREATER","End Date must be greater than Start Date.");
          return appointment;
        }
        else {
          appointment.startDate = startDateStr;
          appointment.endDate = endDateStr;
          return appointment;
        }
      }
    }
  }

  //------------------------------------------------------------- Appointment End ------------------------------------------------------------

  findCustomer(searchTerm: string): Observable<MatchedCustomer[]> {
    return new Observable(
      observer => {
        this.crmBackendService.findCustomer(this.authService.currentAgent.value.authToken, searchTerm).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          err => {
            this.logger.error('Error looking up customers', err);
            observer.next(null);
            observer.complete();
          }
        );
      }
    );
  }

  public blockCustomer(
    vee24guid: string,
    ip: string,
    reason: string
  ): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.crmBackendService
        .blockCustomer(
          this.authService.currentAgent.value.authToken,
          vee24guid,
          ip,
          reason
        )
        .subscribe(
          (data) => {
            observer.next(data);
            observer.complete();
          },
          (err) => {
            this.logger.error("Error when calling the customer blocking method", err);
            observer.next(false);
            observer.complete();
          }
        );
    });
  }

  public engagementRequest: EventEmitter<UserEngagementRequest> = new EventEmitter<UserEngagementRequest>();

  public engageWithUser(request: UserEngagementRequest): Observable<void> {
    return new Observable<void>(subscriber => {
      switch (request.method) {
        case EngageUserMethod.Phone:
          this.validatePhoneNumberEngageRequest(request.contact);
          this.engagementRequest.emit(request);
          break;
        default:
          throw new Error(`Unknown contact type ${request.method}`);
      }

      subscriber.complete();
    });
  }

  private validatePhoneNumberEngageRequest(contact: CrmContact) {
    if (!contact) {
      throw new Error('Invalid contact, empty');
    }

    if (!CrmService.isValidVee24Guid(contact.vee24Guid)) {
      throw new Error('Invalid vee24 guid');
    }

    if (!CrmService.isValidPhoneNumber(contact.customerPhone)) {
      throw new Error('Invalid phone number');
    }
  }

  public static isValidPhoneNumber(phoneNumber: string): boolean {
    const e164Regex = /^\+[1-9]\d{1,14}$/;
    return phoneNumber && e164Regex.test(phoneNumber);
  }

  private static isValidVee24Guid(guid: string): boolean {
    return Guid.isValidUUID(guid);
  }
}

