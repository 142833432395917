import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CrmField } from '../../../services/crm-service/crm-field';

@Component({
  selector: 'app-crm-field-number',
  templateUrl: './crm-field-number.component.html',
  styleUrls: ['./crm-field-number.component.scss']
})
export class CrmFieldNumberComponent implements OnInit {
  private _field: CrmField;

  @Input() set field(field: CrmField) {
    this._field = field;
  }

  get field(): CrmField {
    return this._field;
  }

  @Input() disableCrm: boolean;

  @Output() saveField: EventEmitter<CrmField> = new EventEmitter<CrmField>();

  constructor() { }

  ngOnInit() {
  }

  onFieldValueChange(event) {
    this.field.value = event.target.value;
    this.saveField.emit();
  }
}
