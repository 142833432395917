import {KpiSetting} from '../../../../services/dashboard-service/kpisetting';
import {DashboardData} from '../../../../services/visitor-service/dashboard-data';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslatePipe} from '../../../../filters/Translate.pipe';
import {ProductMode} from '../../supervisor.component';
import {TimeUtil} from '../../../../utils/time-util';
import {faInfoCircle, faWarning} from '@fortawesome/free-solid-svg-icons';
import {AgentStatus} from '../../../../classes/visitor/AgentStatus';
import { OnlineState } from '../../../../enums/online-state.enum';
import {Visitor} from '../../../../services/visitor-service/visitor';

interface Card {
  imgPath: string;
  name: string;
  stat: number;
  outOf: number;
  type: DashboardCardType;
  hiLoPreference: CardHiLoPreference;
}

enum CardHiLoPreference {
  High,
  Low,
  None
}

export enum DashboardCardType {
  operatorsOnBreak,
  veeStudioOperators,
  veeStudioOperatorsAvailable,
  veeStudioOperatorsBusy,
  veeStudioOperatorsNotAvailable,

  veeChatOperators,
  veeChatSessionsPossible,
  veeChatEngaged,
  veeChatAdditionalCapacity,
  requests,

  asyncChatEngaged,

  longestWaitTime,
}

@Component({
  selector: 'app-dash-panel',
  templateUrl: './dash-panel.component.html',
  styleUrls: ['./dash-panel.component.scss'],
  providers: [TranslatePipe]
})

export class DashPanelComponent {
  @Input() set selectedAgents(selectedAgents: Map<string, AgentStatus>) {
    this.VeeStudioCards[0].stat = 0;// dashboardData.veeStudioOperators;
    this.VeeStudioCards[1].stat = 0;// dashboardData.veeStudioOperatorsAvailable;
    this.VeeStudioCards[2].stat = 0;// dashboardData.veeStudioOperatorsBusy + dashboardData.operatorsOnBreak;
    this.VeeStudioCards[3].stat = 0;// dashboardData.veeStudioOperatorsBusy;
    this.VeeStudioCards[4].stat = 0;// dashboardData.operatorsOnBreak;

    this.VeeChatCards[0].stat = 0;// dashboardData.veeChatOperators;
    this.VeeChatCards[1].stat = 0;// dashboardData.operatorsOnBreak;

    this.AsyncChatCards[0].stat = 0;// dashboardData.veeChatOperators;
    this.AsyncChatCards[1].stat = 0;// dashboardData.operatorsOnBreak;

    for (const [_, agent] of selectedAgents) {
      switch (agent.status) {
        default:
        case OnlineState.MobilePresenterWaiting:
        case OnlineState.MobilePresenterEngaged:
        case OnlineState.OffLine:
          break;
        case OnlineState.Available:
          this.VeeStudioCards[1].stat++;// = dashboardData.veeStudioOperatorsAvailable;
          break;
        case OnlineState.Engaged:
          this.VeeStudioCards[3].stat++;// = dashboardData.veeStudioOperatorsBusy;
          break;
        case OnlineState.PostEngagement:
          this.VeeStudioCards[3].stat++;// = dashboardData.veeStudioOperatorsBusy;
          break;
        case OnlineState.MultiChat:
          this.VeeChatCards[0].stat++;// = dashboardData.veeChatOperators;
          this.AsyncChatCards[0].stat++;// = dashboardData.veeChatOperators;
          break;
        case OnlineState.OnBreak:
          this.VeeStudioCards[4].stat++;// = dashboardData.operatorsOnBreak;
          this.VeeChatCards[1].stat++;// = dashboardData.operatorsOnBreak;
          this.AsyncChatCards[1].stat++;// = dashboardData.operatorsOnBreak;
          break;
        case OnlineState.CrmOpportunity:
          this.VeeStudioCards[3].stat++;// = dashboardData.veeStudioOperatorsBusy;
          break;
        case OnlineState.Supervisor:
          break;
        case OnlineState.Home:
          this.VeeStudioCards[3].stat++;// = dashboardData.veeStudioOperatorsBusy;
          break;
        case OnlineState.Appointment:
          this.VeeStudioCards[3].stat++;// = dashboardData.veeStudioOperatorsBusy;
          break;
      }

      this.VeeStudioCards[0].stat = this.VeeStudioCards[1].stat + this.VeeStudioCards[3].stat;
      this.VeeStudioCards[2].stat = this.VeeStudioCards[3].stat + this.VeeStudioCards[4].stat;
    }
  }

  @Input() set selectedVisitors(selectedVisitors: Visitor[]) {
    this.AsyncChatCards[2].stat = 0;// = dashboardData.conversationAssigned;

    for (const v of selectedVisitors) {
      if (v.isAsync) {
        if (v.isEngaged) {
          this.AsyncChatCards[2].stat++;// = dashboardData.conversationAssigned;
        }
      }
    }
  }

  @Input() set notEngagedVisitors(notEngagedVisitors: Visitor[]) {
    this.AsyncChatCards[3].stat = 0;// = dashboardData.conversationUnassigned;

    let longestWait = 0;
    for (const v of notEngagedVisitors) {
      if (v.isAsync) {
        if (!v.isEngaged) {
          this.AsyncChatCards[3].stat++;// = dashboardData.conversationUnassigned;
        }
      }

      longestWait = Math.max(longestWait, v.assistTime);
    }

    this.VeeStudioCards[6].stat = longestWait;// dashboardData.veeStudioLongestWaitTime;
    this.VeeChatCards[6].stat = longestWait;// dashboardData.veeChatLongestWaitTime;
    this.AsyncChatCards[4].stat = longestWait;
  }

  public VeeStudioCards: Card[] = [
    { imgPath: '../assets/images/globe.svg',
      name: this.translate.transform("DASHBOARD_STATUS_AGENTONLINE",'Agents Online'),
      stat: 0,
      outOf:0,
      type: DashboardCardType.veeStudioOperators,
      hiLoPreference: CardHiLoPreference.High },
    { imgPath: '../assets/images/headset.svg',
      name: this.translate.transform("DASHBOARD_STATUS_AGENTAVAILABLE",'Agents Available'),
      stat: 0,
      outOf:0,
      type: DashboardCardType.veeStudioOperatorsAvailable,
      hiLoPreference: CardHiLoPreference.High },
    { imgPath: '../assets/images/coffee.svg',
      name: this.translate.transform("DASHBOARD_STATUS_AGENTNOTAVAILABLE",'Agents Not Available'),
      stat: 0,
      outOf:0,
      type: DashboardCardType.veeStudioOperatorsNotAvailable,
      hiLoPreference: CardHiLoPreference.None },
    { imgPath: '../assets/images/chat bubbles.svg',
      name: this.translate.transform("DASHBOARD_STATUS_AGENTINENGAGEMENT",'Agents in Engagement'),
      stat: 0,
      outOf:0,
      type: DashboardCardType.veeStudioOperatorsBusy,
      hiLoPreference: CardHiLoPreference.None },
    { imgPath: '../assets/images/coffee.svg',
      name: this.translate.transform("DASHBOARD_STATUS_AGENTONBREAK",'Agents on Break'),
      stat: 0,
      outOf:0,
      type: DashboardCardType.operatorsOnBreak,
      hiLoPreference: CardHiLoPreference.None },
    { imgPath: '../assets/images/clock.svg',
      name: this.translate.transform("DASHBOARD_STATUS_REQUESTWAITING",'Requests Waiting'),
      stat: 0,
      outOf:0,
      type: DashboardCardType.requests,
      hiLoPreference: CardHiLoPreference.Low },
    { imgPath: '../assets/images/hourglass.svg',
      name: this.translate.transform("DASHBOARD_STATUS_OLDWAITINGREQUEST",'Oldest Waiting Request'),
      stat: 0,
      outOf:0,
      type: DashboardCardType.longestWaitTime,
      hiLoPreference: CardHiLoPreference.Low }
  ];

  public VeeChatCards: Card[] = [
    { imgPath: '../assets/images/globe.svg',
      name: this.translate.transform("DASHBOARD_STATUS_AGENTONLINE",'Agents Online'),
      stat: 0,
      outOf:0,
      type: DashboardCardType.veeChatOperators,
      hiLoPreference: CardHiLoPreference.High },
    { imgPath: '../assets/images/coffee.svg',
      name: this.translate.transform("DASHBOARD_STATUS_AGENTONBREAK",'Agents on Break'),
      stat: 0,
      outOf:0,
      type: DashboardCardType.operatorsOnBreak,
      hiLoPreference: CardHiLoPreference.None },
    { imgPath: '../assets/images/additional capacity.svg',
      name: this.translate.transform("DASHBOARD_STATUS_ADDITIONALCAPACITY",'Additional Capacity'),
      stat: 0,
      outOf:0,
      type: DashboardCardType.veeChatAdditionalCapacity,
      hiLoPreference: CardHiLoPreference.High },
    { imgPath: '../assets/images/headset.svg',
      name: this.translate.transform("DASHBOARD_STATUS_SESSIONAVAILABLE",'Sessions Available'),
      stat: 0,
      outOf:0,
      type: DashboardCardType.veeChatSessionsPossible,
      hiLoPreference: CardHiLoPreference.High },
    { imgPath: '../assets/images/chat bubbles.svg',
      name: this.translate.transform("DASHBOARD_STATUS_SESSIONENGAGED",'Sessions Engaged'),
      stat: 0,
      outOf:0,
      type: DashboardCardType.veeChatEngaged,
      hiLoPreference: CardHiLoPreference.High },
    { imgPath: '../assets/images/clock.svg',
      name: this.translate.transform("DASHBOARD_STATUS_REQUESTWAITING",'Requests Waiting'),
      stat: 0,
      outOf:0,
      type: DashboardCardType.requests,
      hiLoPreference: CardHiLoPreference.Low },
    { imgPath: '../assets/images/hourglass.svg',
      name: this.translate.transform("DASHBOARD_STATUS_OLDWAITINGREQUEST",'Oldest Waiting Request'),
      stat: 0,
      outOf:0,
      type: DashboardCardType.longestWaitTime,
      hiLoPreference: CardHiLoPreference.Low }
  ];

  public AsyncChatCards: Card[] = [
    { imgPath: '../assets/images/globe.svg',
      name: this.translate.transform("DASHBOARD_STATUS_AGENTONLINE",'Agents Online'),
      stat: 0,
      outOf:0,
      type: DashboardCardType.veeChatOperators,
      hiLoPreference: CardHiLoPreference.High },
    { imgPath: '../assets/images/coffee.svg',
      name: this.translate.transform("DASHBOARD_STATUS_AGENTONBREAK",'Agents on Break'),
      stat: 0,
      outOf:0,
      type: DashboardCardType.operatorsOnBreak,
      hiLoPreference: CardHiLoPreference.None },
    { imgPath: '../assets/images/chat bubbles.svg',
      name: this.translate.transform("DASHBOARD_STATUS_SESSIONENGAGED",'Sessions Engaged'),
      stat: 0,
      outOf:0,
      type: DashboardCardType.asyncChatEngaged,
      hiLoPreference: CardHiLoPreference.High },
    { imgPath: '../assets/images/clock.svg',
      name: this.translate.transform("DASHBOARD_STATUS_REQUESTWAITING",'Requests Waiting'),
      stat: 0,
      outOf:0,
      type: DashboardCardType.requests,
      hiLoPreference: CardHiLoPreference.Low },
    { imgPath: '../assets/images/hourglass.svg',
      name: this.translate.transform("DASHBOARD_STATUS_OLDWAITINGREQUEST",'Oldest Waiting Request'),
      stat: 0,
      outOf:0,
      type: DashboardCardType.longestWaitTime,
      hiLoPreference: CardHiLoPreference.Low }
  ];

  DashboardCardType = DashboardCardType;
  faInfoCircle = faInfoCircle;

  @Input() set mode (mode: ProductMode) {
    switch (mode) {
      case ProductMode.VeeStudio:
        this.selectedCards = this.VeeStudioCards;
        break;
      case ProductMode.VeeChat:
        this.selectedCards = this.VeeChatCards;
        break;
      case ProductMode.AsyncChat:
        this.selectedCards = this.AsyncChatCards;
        break;
    }

    this.pullDetails(this.selectedCards[0]);
  }

  private kpiLookup: Map<Card, [number, number]> = new Map();
  private kpiReceived = false;
  private _kpiSettings: KpiSetting[];
  @Input() set kpiSettings(kpiSettings: KpiSetting[]) {
    if (kpiSettings && kpiSettings.length > 0) {
      this._kpiSettings = kpiSettings;
      this.kpiReceived = true;
      this.kpiLookup.clear();
    }
  }

  @Input() set dashboardData(dashboardData: DashboardData) {
    this.VeeStudioCards[5].stat = dashboardData.veeStudioRequest;

    this.VeeChatCards[2].stat = dashboardData.veeChatAdditionalCapacity;
    this.VeeChatCards[3].stat = dashboardData.veeChatSessionsPossible;
    this.VeeChatCards[4].stat = dashboardData.veeChatEngaged;
    this.VeeChatCards[5].stat = dashboardData.veeChatRequest;
  }

  @Output() showCardInfo: EventEmitter<DashboardCardType> = new EventEmitter<DashboardCardType>();

  public selectedCards: Card[] = this.VeeStudioCards;
  public selectedCard: Card = this.VeeStudioCards[0];

  constructor(private readonly translate: TranslatePipe) { }



  getColorClass(card: Card): string {
    if (!this.kpiReceived) {
      return 'surface-grey';
    }

    const vals = this.setKPIVals(card);
    //red : val[0] amber : val[1]
    if (card.hiLoPreference ===  CardHiLoPreference.High) {
      if (card.stat > vals[1]) {
        return 'surface-green';
      }
      if (card.stat <= vals[0]) {
        return 'surface-red';
      }
      if (card.stat <= vals[1]) {
        return 'surface-orange';
      }
    }
    else {
      if (card.stat >= vals[0]) {
        return 'surface-red';
      }
      if (card.stat < vals[1]) {
        return 'surface-green';
      }
      if (card.stat <= vals[0]) {
        return 'surface-orange';
      }
    }
  }

  // obtains KPI high and mid threshold according to the type of data
  // return array with red (high) and amber (mid) values
  setKPIVals(card: Card): number[] {
    let vals = this.kpiLookup.get(card);
    if (vals === undefined) {
      let red = 0;
      let amber = 0;
      for (let i = 0; i < this._kpiSettings.length; i++) {
        if (this._kpiSettings[i].SettingName === 'KPI_RED_' + card.type) {
          red = Number(this._kpiSettings[i].SettingValue);
        }
        if (this._kpiSettings[i].SettingName === 'KPI_AMBER_' + card.type) {
          amber = Number(this._kpiSettings[i].SettingValue);
        }
      }
      vals = [red, amber];
      this.kpiLookup.set(card, vals);
    }
    return vals;
  }

  // card selection, will eventually pull detail information for each card
  pullDetails(currentCard: Card) {
    this.selectedCard = currentCard;
    this.showCardInfo.emit(currentCard.type);
  }

  text(card: Card): string {
    if (card.type == DashboardCardType.longestWaitTime) {
      return TimeUtil.PrettyRelativeTime(card.stat ?? 0);
    } else {
      return `${card.stat ?? 0}`;
    }
  }

  protected readonly faWarning = faWarning;
}
