<form class="d-flex flex-row w-100 gap-2 mb-2 pr-2">
    <input type="search"
           class="flex-grow-1"
           name="search-term"
           pInputText  [disabled]="disabled"
           placeholder='{{ "CUSTOMERSEARCH_PLACEHOLDER_NAME" | Translate : "Name or Email Address" }}'
           [(ngModel)]="searchTerm">

    <p-button appDebounceClick
            styleClass=" center-icon"  [disabled]="disabled"
            (clicked)="searchForCustomer()">
      <fa-icon [icon]="faSearch"
               class="search-icon">
      </fa-icon>
    </p-button>
</form>
<div class="search-results" *ngIf="rows">
  <p-table [paginator]="true" [rows]="5" [value]="rows">
    <ng-template pTemplate="header">
      <tr>
        <th>{{ "CUSTOMERSEARCH_COLUMN_NAME" | Translate : "Name" }}</th>
        <th>{{ "CUSTOMERSEARCH_COLUMN_EMAIL" | Translate : "Email" }}</th>
        <th>{{ "CUSTOMERSEARCH_COLUMN_PHONENUMBER" | Translate : "Phone Number"  }}</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>
        <td>{{row.customerName}}</td>
        <td>{{row.customerEmail}}</td>
        <td>{{row.customerPhoneNumber}}</td>
        <td>
          <p-button id="load-customer-btn"
                    [pTooltip]="disabled ? ('CUSTOMERSEARCH_MERGE_DISABLED' | Translate : 'Merge functionality is only available when customer is not in the background and for primary agent') : ('CUSTOMERSEARCH_MERGE' | Translate : 'Merge')"
                  [disabled]="disabled"
                   styleClass="  center-icon"
                  (onClick)="loadCustomer(row)">
            <fa-icon [icon]="faSignInAlt"
                     class="load-customer-icon">
            </fa-icon>
          </p-button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="3"> {{ "TABLE_LABEL_NO_RESULTS" | Translate : 'No results' }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
