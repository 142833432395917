import {Component, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import { Visitor } from '../../../services/visitor-service/visitor';
import { Browsers } from '../../../utils/browsers';
import {CallStatuses} from '../../../utils/call-statuses';
import { Subscription } from 'rxjs';
import {faPhone} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: '[app-call-list-row]',
  templateUrl: './call-list-row.component.html',
  styleUrls: ['./call-list-row.component.scss']
})
export class CallListRowComponent implements OnDestroy {
  public CallStatuses = CallStatuses;
  public Browsers = Browsers;

  public loading: boolean = true;
  private subs: Subscription[] = [];


  private _visitor: Visitor;
  @Input() set visitor(visitor: Visitor) {
    this._visitor = visitor;
    this.subs.push(this._visitor.details.subscribe(() => this.loading = false));
  }
  get visitor(): Visitor {
    return this._visitor;
  }

  @Output() acceptCall = new EventEmitter();

  constructor(
  ) { }

  public accept(): void {
    this.acceptCall.emit();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  protected readonly faPhone = faPhone;
}
