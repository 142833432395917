import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Engagement, EngagementAgent, EngagementState, EngagementStateType, EngagementVisitor, CustomerSizes } from '../../../services/engagement';
import { EngagementService } from '../../../services/engagement.service';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import { distinctUntilChanged, map, filter, tap, take } from 'rxjs/operators';
import { Visitor } from '../../../services/visitor-service/visitor';
import { VisitorDetails } from '../../../classes/visitor-details';
import { VisitorService } from '../../../services/visitor-service/visitor.service';
import { EngagementAsset } from '../../../classes/EngagementAssets';
import { AlertService, AlertType } from '../../../services/alert-service/alert.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { EndChatAgent, EndChatModalComponent, EndType } from '../../../components/end-chat-modal/end-chat-modal.component';
import { FileTransferService, ValidateFileType } from '../../../services/file-transfer-service/file-transfer.service';
import { IFileUpload } from '../../../services/file-transfer-service/file-upload-interface';
import { CrmStructure } from '../../../services/crm-service/crm-category';
import { EngagementEvent } from '../../../services/crm-service/engagement-event';
import { BrowsingHistory } from '../../../services/crm-service/browsing-history';
import { InsertAppointment } from '../../../services/crm-service/appointment';
import { CrmService } from '../../../services/crm-service/crm.service';
import { CrmEmailStateType, CrmEmailState } from '../../../services/crm-service/crm-email-state';
import { CrmEmail } from '../../../utils/crm-email';
import {CheckListField, ChecklistFieldType} from '../../../classes/checklist/CheckListField';
import { Opportunity } from '../../../services/crm-service/opportunity';
import { AuthService } from '../../../services/auth-service/auth.service';
import {Features, FeatureService} from '../../../services/feature-service/feature.service';
import {TextMessage, TextMessages} from '../../../classes/TextMessage';
import { CustomerInvite } from '../../../components/engagement-join-customers/CustomerInvite';
import { EmailService } from '../../../services/email-service/email.service';
import { MatchedCustomer } from '../../../services/crm-service/matched-customer';
import { VisitorSiteDetails } from '../../../classes/visitor/VisitorSiteDetails';
import { VisitorSessionDetails } from '../../../classes/visitor/VisitorSessionDetails';
import { TranslatePipe } from '../../../filters/Translate.pipe';
import {OutboundMessage} from '../../../components/engagement-text-chat/engagement-text-chat.component';
import { BrowserService } from '../../../services/browser-service/browser.service';
import { BlockCustomerModalComponent } from '../../../components/block-user-modal/block-user-modal.component';

import {PostEngagementStatus} from '../../../classes/post-engagement.status';
import {ModalService} from '../../../services/modal.service';
import {Section} from "../../../components/visitor-information/visitor-information.component";

@Component({
  selector: 'app-veechat-engagement',
  templateUrl: './veechat-engagement.component.html',
  styleUrls: ['./veechat-engagement.component.scss'],
  providers: [TranslatePipe]
})
export class VeechatEngagementComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public static readonly NO_ENGAGEMENT = '';
  private static readonly EMPTY_VISITOR_DETAILS = {
    page: '',
    section: '',
    prevOp: '',
    organisation: '',
    location: '',
    ip: '',
    referrer: '',
    useragent: '',
  };
  private static readonly EMPTY_VISITOR_SESSION_DETAILS = {
    ip: '',
    referrer: '',
    organisation: '',
    width: '',
    height: '',
    deviceScaleFactor: '0',
    isMobile: false,
    isTablet: false,
    clientWidth: '',
    clientHeight: ''
  };
  private modalRef: DynamicDialogRef;

  public EngagementState = EngagementState;
  public username: string;

  @Output() engagementEnded = new EventEmitter();

  private _engagementId: string;
  @Input() public set engagementId(engagementId: string) {
    if (this._engagementId === engagementId) {
      return;
    } else if (engagementId === VeechatEngagementComponent.NO_ENGAGEMENT) {
      this.clearBindings();
      this._engagementId = VeechatEngagementComponent.NO_ENGAGEMENT;
    } else {
      this._engagementId = engagementId;
      this.rebindUI();
    }
  }

  public get engagementId(): string {
    return this._engagementId;
  }


  @Output() engagementChange = new EventEmitter<Engagement>();

  public engagement: Engagement;
  public appointment: InsertAppointment;

  public readonly useCoBrowsing: boolean;
  public readonly showCustomerHasBackgrounded: boolean;

  constructor(
    private engagementService: EngagementService,
    private visitorService: VisitorService,
    private fileTransferService: FileTransferService,
    private alertService: AlertService,
    private modalService: ModalService,
    private authService: AuthService,
    private crmService: CrmService,
    private featureService: FeatureService,
    private emailService: EmailService,
    private readonly browserService: BrowserService,
    private translate: TranslatePipe
  ) {
    this.useCoBrowsing = this.featureService.has(Features.COBROWSE);
    this.showCustomerHasBackgrounded = true;
  }

  public visitorMessage$: BehaviorSubject<TextMessage>;
  public currentUrl$: BehaviorSubject<string> = new BehaviorSubject<string>('about:blank');
  public currentState$: BehaviorSubject<EngagementStateType>;
  public privateChatMessages: BehaviorSubject<TextMessage[]>;

  public isPrimaryAgent: Observable<boolean>;
  public isBlockedOn: Observable<boolean>;
  public isPresentingAgent: Observable<boolean>;
  public presentingAgent$: BehaviorSubject<string> = new BehaviorSubject('');
  public primaryAgent$: BehaviorSubject<string> = new BehaviorSubject('');
  public privateChatEnabled: BehaviorSubject<boolean>;
  public privateChatAvailable: boolean;

  translationOn$: Subject<boolean>;

  protected getSuggestionOn$: Observable<boolean>;
  protected autoReplyOn$: Observable<boolean>;


  public visitorDetails$: BehaviorSubject<VisitorSiteDetails> = new BehaviorSubject<VisitorSiteDetails>(VeechatEngagementComponent.EMPTY_VISITOR_DETAILS);
  public visitorSessionDetails$: BehaviorSubject<VisitorSessionDetails> = new BehaviorSubject<VisitorSessionDetails>(VeechatEngagementComponent.EMPTY_VISITOR_SESSION_DETAILS);
  public visitorCrmData$: BehaviorSubject<CrmStructure> = new BehaviorSubject(new CrmStructure());
  public visitorSessionHistory$: BehaviorSubject<EngagementEvent[]> = new BehaviorSubject<EngagementEvent[]>([]);
  public visitorBrowsingHistory$: BehaviorSubject<BrowsingHistory[]> = new BehaviorSubject<BrowsingHistory[]>([]);
  public emailState: BehaviorSubject<CrmEmailStateType> = new BehaviorSubject<CrmEmailStateType>({ type: CrmEmailState.Valid });
  public fileTransfers$: Observable<IFileUpload[]>;
  public showingModal$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private subs: Array<Subscription> = [];

  public agents: ReadonlyMap<string, EngagementAgent> = new Map([]);
  public visitors: ReadonlyMap<string, EngagementVisitor> = new Map([]);

  public checklistfields: BehaviorSubject<CheckListField[]> = new BehaviorSubject<CheckListField[]>([]);

  private onSubmitClickCount: number = 0;

  public setActiveTab: string = '';

  public requestingHelp$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public currentSection: Section = Section.Contact;
  public unreadPrivateMessages: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public unreadMessages: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public currentPanelPositionAndSize$: Observable<CustomerSizes>;

  ngOnInit() {
    this.privateChatAvailable = this.featureService.has(Features.PRIVATE_CHAT);
  }

  ngOnDestroy() {
    this.clearBindings();
  }

  private clearBindings() {

    for (const sub of this.subs) {
      sub.unsubscribe();
    }
    this.subs = [];
    this.engagement = undefined;
    this.visitorDetails$.next(VeechatEngagementComponent.EMPTY_VISITOR_DETAILS);
    this.visitorCrmData$.next(null);
    this.visitorSessionHistory$.next(null);
    this.visitorBrowsingHistory$.next(null);
    this.fileTransfers$ = undefined;
    this.isPrimaryAgent = undefined;
    this.isPresentingAgent = undefined;
    this.presentingAgent$ = undefined;
    this.primaryAgent$ = undefined;
    this.username = undefined;
    this.requestingHelp$.next(false);

    this.agents = new Map([]);
    this.visitors = new Map([]);

    this.closeModal();
  }

  private rebindUI() {

    this.clearBindings();
    this.engagement = this.engagementService.getEngagement(this._engagementId);

    // If we fail to get the engagement then clear
    if (this.engagement === undefined) {
      this._engagementId = VeechatEngagementComponent.NO_ENGAGEMENT;
      return;
    }

    this.translationOn$ = this.engagement.translationOn$;
    this.visitorMessage$ = this.engagement.visitorTypingMessage;

    const urlSub = this.engagement.currentPage
      .pipe(distinctUntilChanged())
      .subscribe(url => {
        if(url && url.length > 0){
          this.currentUrl$.next(url);
        }
        else {
          this.currentUrl$.next(this.engagement.visitor.lastPage);
        }
      });

    this.subs.push(urlSub);

    this.currentState$ = this.engagement.currentState;

    this.visitorDetails$.next(this.createDetails(this.engagement.visitor));

    this.subs.push(
      this.modalService.showingModal$.pipe(distinctUntilChanged()).subscribe((isShown: boolean) => {
        this.showingModal$.next(isShown);
      })
    );

    this.subs.push(this.engagement.visitor.details.subscribe(deets => {
      if (deets != null) {
        this.visitorSessionDetails$.next(this.createSessionDetails(deets));
      }
    }));

    this.subs.push(this.engagement.visitor.crmData.subscribe(crmData => {
      if (crmData != null) {
        this.visitorCrmData$.next(crmData);
      }
    }));

    this.subs.push(this.engagement.visitor.sessionHistory.subscribe(history => {
      if (history != null) {
        this.visitorSessionHistory$.next(history);
      }
    }));

    this.subs.push(this.engagement.visitor.browsingHistory.subscribe(history => {
      if (history != null) {
        this.visitorBrowsingHistory$.next(history);
      }
    }));

    this.fileTransfers$ = this.engagement.fileTransfers$.pipe(map(transfers => Array.from(transfers.values())));

    this.isPrimaryAgent = this.engagement.isPrimary;
    this.isBlockedOn = this.engagement.blockedOn$;
    this.isPresentingAgent = this.engagement.isPresenter;
    this.presentingAgent$ = this.engagement.presentingAgent;
    this.primaryAgent$ = this.engagement.primaryAgent;
    this.agents = this.engagement.roomAgents;
    this.visitors = this.engagement.roomVisitors;
    this.username = this.engagement.username;

    this.privateChatEnabled = this.engagement.privateChatEnabled;
    this.unreadPrivateMessages = this.engagement.unreadPrivateMessages;
    this.unreadMessages = this.engagement.unreadMessages;
    this.privateChatMessages = this.engagement.privateMessages;
    this.requestingHelp$ = this.engagement.requestingHelp$;
    this.getSuggestionOn$ = this.engagement.agentAssistBotGetSuggestions;
    this.autoReplyOn$ = this.engagement.agentAssistBotAutosendMessage;

    this.currentPanelPositionAndSize$ = this.engagement.panelPositionAndSize
    .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));

    this.emailState.next(CrmEmail.isValidEmail(this.visitorCrmData$.value));

    this.engagementChange.emit(this.engagement);
  }

  uploadFiles(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      const result = this.fileTransferService.validateFile(files[i]);
      switch (result.type) {
        default:
        case ValidateFileType.FileExtensionInvalid:
          this.alertService.addAlert(files[i].name + ' has an invalid file extension', AlertType.Danger);
          break;
        case ValidateFileType.InvalidOther:
          this.alertService.addAlert(files[i].name + ' is invalid', AlertType.Danger);
          break;
        case ValidateFileType.FileTooLarge:
          this.alertService.addAlert(files[i].name + ' is too large', AlertType.Danger);
          break;
        case ValidateFileType.Valid:
          break;
      }
      this.engagement.uploadFile(files[i]);
    }
  }

  cancelFileTransfer(file: IFileUpload) {
    this.engagement.removeFileUpload(file);
  }

  sendMessage(message: OutboundMessage) {
      this.engagement.sendChatMessage(message.formattedMessage.message);
  }

  setTranslation(enabled: boolean) {
    this.engagement.setTranslationEnabled(enabled);
  }

  public setGetSuggestion(enabled: boolean) {
    this.engagement.setGetSuggestionEnabled(enabled);
  }

  public setAutoReply(enabled: boolean) {
    this.engagement.setAutoReplyEnabled(enabled);
  }

  selectJoiningOperator() {
    this.engagement.selectJoiningOperator();
  }

  cancelJoin() {
    this.engagement.cancelJoin();
  }

  joinRequest($event: any) {
    this.engagement.sendInviteRequest($event);
  }

  customerJoinRequest($event: CustomerInvite) {
    this.engagement.customerJoinRequest($event);
  }

  changePresenter($event: string) {
    this.engagement.changePresenter($event);
  }

  kickAgent($event: string) {
    this.engagement.kickAgent($event);
  }

  selectTransferringOperator() {
    this.engagement.selectTransferringOperator();
  }

  cancelTransfer() {
    this.engagement.cancelTransfer();
  }

  transferRequest($event: any) {
    this.engagement.sendInviteRequest($event);
  }

  isProceedOnChecklistFailed(postStatus: PostEngagementStatus) {

    this.closeModal();

    const data = {
      endChatType: EndType.END_ON_CHECKLIST_FAIL,
      onEndChat: (newUsername: string) => {
        this.closeModal();
        this.submit(postStatus);
      },
      cancelEndChat: () => {
        if (this.checklistfields.value.some(field => field.IsRulePassed == false && !field.CRMField.startsWith("Custom Task:"))) {
          this.setActiveTab = 'crm';
        }
        this.closeModal();
      }
    };

    this.modalRef = this.modalService.openModal(EndChatModalComponent, {
      data,
      closeOnEscape: false,
      showHeader: true,
      closable: false,
      header: this.translate.transform('ENDCHATMODAL_HEADER_PROCEEDCHAT', 'Not all recommended fields have been entered. Are you sure you want to proceed?'),
      contentStyle: { width: '500px' }
    });
  }

  onPostEngagementSubmit(postStatus: PostEngagementStatus) {
    if (this.checklistfields.value.length === 0) {
      this.submit(postStatus);
      return;
    }

    this.onSubmitClickCount++;

    if (this.hasMandatoryFieldNotPassed()) {
      this.setActiveTab = `crm`;
      this.alertService.addAlert(this.translate.transform("ENGAGEMENTOPPORTUNITY_ALERT_VALIDATION", `Not all required fields have been entered.`), AlertType.Danger);
      return;
    }

    if (this.hasFailedRuleAndSubmittedMultipleTimes()) {
      this.isProceedOnChecklistFailed(postStatus);
      return;
    }

    if (this.hasFailedRule()) {
      this.setActiveTab = `crm`;
      this.alertService.addAlert(this.translate.transform("ENGAGEMENT_ALERT_FIELDVALIDATION", `Not all recommended fields have been entered.`), AlertType.Danger);
      return;
    }

    this.submit(postStatus);
  }

  private hasMandatoryFieldNotPassed(): boolean {
    return this.checklistfields.value.some(field => field.IsCustomTask === false && field.IsRulePassed == false && field.CheckType === ChecklistFieldType.MANDATORY);
  }

  private hasFailedRuleAndSubmittedMultipleTimes(): boolean {
    return this.checklistfields.value.some(field => field.IsCustomTask === false && field.IsRulePassed === false) && this.onSubmitClickCount > 1;
  }

  private hasFailedRule(): boolean {
    return this.checklistfields.value.some(field => field.IsCustomTask === false && field.IsRulePassed === false);
  }

  submit(postStatus: PostEngagementStatus) {
    const engagementId = this._engagementId;

    this.emailService.submitMessages(engagementId, this.engagement.visitor.userGuid).subscribe();

    this.appointment = this.crmService.isValidAppointment(this.appointment);
    if (this.appointment.validationError != "") {
      this.alertService.addAlert(this.appointment.validationError, AlertType.Danger);
      return;
    }
    this.visitorService.qualifyEngagement(engagementId, postStatus.status, postStatus.substatus, postStatus.notes, this.appointment).subscribe(
      response => {
        if (response.success) {
          if (this._engagementId === this._engagementId) {
            this.onEngagementEnded();
          }
        } else {
          // todo: what should we do with these instead of just displaying an alert
          // this.alertService.addAlert(response.message, AlertType.Danger));
        }
      },
      error => {
        // todo: what should we do with these instead of just displaying an alert
        // this.alertService.addAlert(`TIMEOUT`, AlertType.Danger));
      }
    );
  }

  onEngagementEnded() {
    this.engagementEnded.emit();
  }

  public leaveChat(engagement: Engagement) {
    this.closeModal();
    engagement.leaveChat();
  }

  public showEndChatModal() {
    const engagement = this.engagement;

    // If the room is locked we ignore this method call
    if (engagement.roomLocked) {
      this.closeModal();
      return;
    }

    // Either leave the chat if we are not the primary agent
    // or end the chat if we are the primary agent
    const sub = engagement.engagementEnded.subscribe((ended) => {
      if (ended) {
        sub.unsubscribe();
        this.cancelEndChat();
        this.alertService.clearAlerts();
      }
    });

    const shouldEndChat: boolean = engagement.isPrimaryAgent();
    const endChatAgents: EndChatAgent[] = [];
    for (const [agentUsername, agent] of this.agents) {
      if (engagement.username !== agentUsername) {
        endChatAgents.push({
          username: agentUsername,
          nickname: agent.nickname,
        });
      }
    }

    const data = {
      agents: endChatAgents,
      endChatType: shouldEndChat ? EndType.END_CHAT : EndType.LEAVE_CHAT,
      onEndChat: (newUsername: string) => {
        sub.unsubscribe();

        if (shouldEndChat) {
          engagement.unlockRoom();
          if (newUsername.length > 0) {
            this.transferToNewPrimaryAndLeave(engagement, newUsername);
          } else {
            this.onEndChat(engagement);
          }
        } else {
          this.leaveChat(engagement);
        }
      },
      cancelEndChat: () => {
        sub.unsubscribe();
        if (shouldEndChat) {
          engagement.unlockRoom();
        }
        this.cancelEndChat();
      }
    };

    this.modalRef = this.modalService.openModal(EndChatModalComponent, {
      data,
      closeOnEscape: false,
      showHeader: true,
      closable: false,
      header: shouldEndChat ? this.translate.transform('ENDCHATMODAL_HEADER_ENDCHAT', 'Are you sure you want to end this chat?') : this.translate.transform('ENDCHATMODAL_HEADER_LEAVECHAT', 'Are you sure you want to leave this chat?'),
      contentStyle: {width: '500px'}
    });

    if (shouldEndChat) {
      engagement.lockRoom();
    }
  }

  public showBlockCustomerModal() {
    const engagement = this.engagement;
    const sub = engagement.engagementEnded.subscribe((ended) => {
      if (ended) {
        sub.unsubscribe();
        this.alertService.clearAlerts();
        this.cancelEndChat();
      }
    });

    const data = {
      onBlockCustomer: (reason: string) => {
        sub.unsubscribe();
        this.onBlockCustomer(engagement, reason);
      },
      cancelBlockCustomer: () => {
        sub.unsubscribe();
        this.cancelEndChat();
      }
    };

    this.modalRef = this.modalService.openModal(BlockCustomerModalComponent, {
      data,
      closeOnEscape: false,
      showHeader: true,
      closable: false,
      header: this.translate.transform('BLOCKCUSTOMERMODAL_HEADER_BLOCKUSER', 'Are you sure you want to block customer?'),
      contentStyle: {width: '500px'}
    });

  }

  private transferToNewPrimaryAndLeave(engagement: Engagement, newUsername: string) {
    // Only change the presenter if it is us
    if (engagement.isPresentingAgent()) {
      engagement.changePresenter(newUsername).subscribe();
    }

    engagement.changePrimary(newUsername).subscribe(
      () => this.leaveChat(engagement),
      () => { }
    );
  }

  public onEndChat(engagement: Engagement) {
    this.getCheckListFields('Default');
    this.closeModal();

    engagement.endChat()
      .then(() => {
      })
      .catch(() => {
      });
  }

  public onBlockCustomer(engagement: Engagement, reason: string) {
    this.closeModal();

    let ip = "0.0.0.0";
    engagement.visitor.details.subscribe(x => ip = x.IPAddress || "0.0.0.0");

    this.crmService
      .blockCustomer(
        engagement.visitor.userGuid,
        ip,
        reason.length > 0 ? reason : "Default"
      )
      .subscribe((data) => {
        if (!data) {
          this.alertService.addAlert(
            this.translate.transform(
              "BLOCKCUSTOMER_ALERT_ERROR",
              "Error when calling the user blocking method."
            ),
            AlertType.Danger
          );
        }
        engagement.setBlockedOn();
        if (!engagement.engagementEnded.value) {
          this.onEndChat(engagement);
        }
      });
  }

  public cancelEndChat() {
   this.closeModal();
  }

  private closeModal() {
    if (this.modalRef) {
      this.modalService.closeModal(this.modalRef);
      this.modalRef = null;
    }
  }

  private createDetails(visitor: Visitor): VisitorSiteDetails {
    return {
      section: visitor.lastSection,
      prevOp: visitor.previousOperator,
      location: visitor.location,
      useragent: visitor.browser,
      page: visitor.lastPage,
    };
  }

  private createSessionDetails(details: VisitorDetails): VisitorSessionDetails {
    const parsedDetails = {
      organisation: details.Org,
      ip: details.IPAddress,
      referrer: details.Referrer,
      deviceScaleFactor: details.DevicePixelRatio,
      isMobile: details.isMobile === "1",
      isTablet: details.isTablet === "1",
      clientWidth: details.ClientWidth,
      clientHeight: details.ClientHeight
    };


    return parsedDetails;
  }

  public selectAsset(asset: EngagementAsset) {
    // Only push assets for the presenting agent
    if (this.engagement.isPresentingAgent()) {
      this.engagement.showPage(asset.Url);
      this.currentUrl$.next(asset.Url);

      const browser = this.browserService.getBrowser(this.engagementId);

      if (browser) {
        browser.changeUrl(asset.Url);
      }
    }
  }

  saveCrmData() {
    this.emailState.next(CrmEmail.isValidEmail(this.visitorCrmData$.value));
    this.engagement.setCrmData(this.visitorCrmData$.value);
    this.validateCheckList();
  }

  getCheckListFields(checklistName: string) {
      this.crmService.getChecklistFields(checklistName).subscribe(checklist => {
        if (checklist == null) {
          this.checklistfields.next([]);
        } else {
          this.checklistfields.next(checklist);
          this.validateCheckList();
        }
      });
  }

  validateCheckList() {
    this.crmService.validateChecklist(this.checklistfields.value, this.visitorCrmData$.value);
  }

  sendPrivateChatMessage(privateMessage: string) {
    this.engagement.sendPrivateChatMessage(privateMessage);
  }

  toggleHelpRequest() {
    this.engagement.toggleHelpRequest();
  }

  agentTextChange(agentText: string): void {
    this.engagement.agentTextChange(agentText);
  }

  saveOpportunity(opp: Opportunity) {
    this.crmService.insertUpdateOpportunity(this.engagement.visitor.userGuid, opp.opportunityTitle, opp.opportunityProduct, opp.opportunityNotes,
      opp.opportunityStatus.toString(), opp.opportunityValue, opp.opportunityId.toString()).subscribe(
        res => {
          if (res) {
            this.alertService.addAlert(this.translate.transform("ENGAGEMENTOPPORTUNITY_ALERT_SUCCESS", 'Opportunity Saved'), AlertType.Success);
            this.rebindUI();
          }
        },
        err => {
          this.alertService.addAlert(this.translate.transform("ENGAGEMENTOPPORTUNITY_ALERT_FAILEDOPPORTUNITY", 'Failed to save opportunity'), AlertType.Danger);
        });
  }

  loadCustomer(customer: MatchedCustomer) {
    const currentUserId = this.engagement.visitor.userGuid;
    this.engagement.switchCustomer(currentUserId, customer.customerGuid);
  }

  setPanelPosition($event: [string, string]) {
    this.engagement.setPanelPosition($event[0], $event[1]);
  }

  savePost($event: PostEngagementStatus) {
    this.visitorService.savePostStatus(this.engagement.engagementId.toString(), this.engagement.visitor.userGuid, this.engagement.visitor.sessionGuid, $event);
  }
}
