<div class="d-flex flex-column gap-4 h-100">
  <div class="flex-grow-1">
    <p>
      {{ "APPOINTMENTTRANSFER_LABEL_DESCRIPTION" | Translate : "If you transfer the appointment to another agent, it can not be restored later" }}
    </p>

    <p-dropdown [options]="(availableAgentsList)" optionValue="username" optionLabel="realname"
                placeholder="{{ 'APPOINTMENTTRANSFER_LABEL_AVAIALABLEAGENTS' | Translate : 'Available Agents' }}"
                [(ngModel)]="selectedAgent" [showClear]="false">
    </p-dropdown>

  </div>

  <div class="d-flex flex-row gap-2 mt-4 justify-content-end align-items-center">
    <p-button styleClass="p-button-text text-secondary" (click)="onCancelTransfer()">{{
        "MODAL_BUTTON_CANCEL" | Translate : "Cancel" }} </p-button>
    <p-button [disabled]="!selectedAgent"   (onClick)="onTransfer()">{{ "MODAL_BUTTON_TRANSFER" |
      Translate : "Transfer" }} </p-button>
  </div>
</div>


