import {Component, OnDestroy, OnInit} from '@angular/core';
import {VisitorService} from '../../services/visitor-service/visitor.service';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {EngagementService} from '../../services/engagement.service';
import {OnlineService} from '../../services/online.service';
import {SettingsService} from '../../services/settings-service/settings.service';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth-service/auth.service';
import {OnlineState} from '../../enums/online-state.enum';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {Agent} from '../../classes/agent';
import {Visitor} from '../../services/visitor-service/visitor';
import {Engagement, EngagementState} from '../../services/engagement';
import {AgentStatus} from '../../classes/visitor/AgentStatus';
import {LoggingService} from '../../services/logging.service';
import {TimeUtil} from '../../utils/time-util';
import {CrmService} from '../../services/crm-service/crm.service';
import {Features, FeatureService} from '../../services/feature-service/feature.service';
import {KpiSetting} from '../../services/dashboard-service/kpisetting';
import {DashboardData} from '../../services/visitor-service/dashboard-data';
import {LoadingService} from '../../services/loading.service';
import {AlertService, AlertType} from '../../services/alert-service/alert.service';
import {NotificationService} from '../../services/notification.service';
import {Title} from '@angular/platform-browser';
import {LicenceType} from '../../enums/licence-type.enum';
import {AsyncConversation} from '../../services/async-conversation';
import {WorkStatus} from '../../classes/work-status';
import {MenuItem} from "primeng/api";

export enum SupervisingEngagementTypes {
  Snooping,
  Supervising,
  Veestudio,
  Veechat,
  None,
  SnoopingOnAsyncChat,
}

export enum ProductMode {
  VeeStudio = 'VeeStudio',
  VeeChat = 'VeeChat',
  AsyncChat = 'AsyncChat',
}

export interface NoEngagement {
  type: SupervisingEngagementTypes.None;
}

export interface SnoopingEngagement {
  type: SupervisingEngagementTypes.Snooping;
  engagementId: string;
  visitor: Visitor;
}

export interface JoinedEngagement {
  type: SupervisingEngagementTypes.Supervising;
  engagementId: string;
  engagement: Engagement;
  visitor: Visitor;
}

export interface SnoopingAsyncChat {
  type: SupervisingEngagementTypes.SnoopingOnAsyncChat;
  conversationId: string;
  conversation: AsyncConversation;
  visitor: Visitor;
}

export type Snooping = SnoopingEngagement | SnoopingAsyncChat;
export type Joined = JoinedEngagement;
export type SupervisingEngagement = Snooping | Joined | NoEngagement;


export enum SnoopingType {
  Visitor, Conversation, AsyncChat
}
export interface SnoopingConfigurationVisitor {
  type: SnoopingType.Visitor;
  visitor: Visitor;
}
export interface SnoopingConfigurationAsyncChat {
  type: SnoopingType.AsyncChat;
  visitor: Visitor;
}
export type SnoopingConfiguration = SnoopingConfigurationVisitor | SnoopingConfigurationAsyncChat;

export enum HelpRequestType {
  EngagementHelpRequest, ConversationHelpRequest
}

export interface EngagementHelpRequest {
  type: HelpRequestType.EngagementHelpRequest;
  engagementGuid: string;
  visitor: Visitor;
  requestTime: Date;
}

export type HelpRequest = EngagementHelpRequest;

@Component({
  selector: 'app-supervisor',
  templateUrl: './supervisor.component.html',
  styleUrls: ['./supervisor.component.scss'],
  providers: [ VisitorService ]
})
export class SupervisorComponent implements OnInit, OnDestroy {
  public static USE_WINDOW_BEFOREUNLOAD = true;

  private static readonly DASHBOARD_TIMER_MS = 1000 * 5;

  public activeItem: MenuItem;
  public productModes: MenuItem[] = [
    {
      label: ProductMode.VeeStudio,
      icon: 'pi pi-video',
      command: () => {
        this.filterByVeeStudio();
      }
    },
    {
      label: 'VeeChat',
      icon: 'pi pi-comments',
      command: () => {
        this.filterByVeechat();
      }
    },
    {
      label: 'AsyncChat',
      icon: 'pi pi-comments',
      command: () => {
        this.filterByAsyncChat();
      }
    }
  ];


  private static readonly NO_SUPERVISING_ENGAGEMENT: NoEngagement = {
    type: SupervisingEngagementTypes.None
  };

  public SupervisingEngagementTypes = SupervisingEngagementTypes;
  public TimeUtil = TimeUtil;
  public OnlineState = OnlineState;

  public currentState$: BehaviorSubject<OnlineState> = new BehaviorSubject<OnlineState>(OnlineState.Supervisor);
  private subscriptions: Array<Subscription> = [];
  private currentAgent: BehaviorSubject<Agent | null> = new BehaviorSubject<Agent | null>(null);

  private visitors$: Observable<Visitor[]> = new BehaviorSubject([]);

  private veestudioVisitors: BehaviorSubject<Visitor[]> = new BehaviorSubject([]);
  private veechatVisitors: BehaviorSubject<Visitor[]> = new BehaviorSubject([]);
  private asyncChatVisitors: BehaviorSubject<Visitor[]> = new BehaviorSubject<Visitor[]>([]);
  public selectedVisitors$: Subject<Visitor[]> = new Subject<Visitor[]>()
  private notEngagedVeeStudioVisitors: BehaviorSubject<Visitor[]> = new BehaviorSubject([]);
  private notEngagedVeeChatVisitors: BehaviorSubject<Visitor[]> = new BehaviorSubject([]);
  private notEngagedAsyncChatVisitors: BehaviorSubject<Visitor[]> = new BehaviorSubject([]);
  public notEngagedVisitors$: Subject<Visitor[]> = new Subject<Visitor[]>();

  private veestudioAgents$: BehaviorSubject<Map<string, AgentStatus>> = new BehaviorSubject<Map<string, AgentStatus>>(new Map());
  private veechatAgents$: BehaviorSubject<Map<string, AgentStatus>> = new BehaviorSubject<Map<string, AgentStatus>>(new Map());

  /**
   * Agents and their statuses filtered by selection groups/classifications.
   */
  public selectedAgents$: Observable<Map<string, AgentStatus>> = new Subject<Map<string, AgentStatus>>();

  public supervisingEngagement: SupervisingEngagement = SupervisorComponent.NO_SUPERVISING_ENGAGEMENT;
  public snoopingChats: BehaviorSubject<Map<string, Snooping>> = new BehaviorSubject<Map<string, Snooping>>(new Map([]));
  public supervisingEngagements: BehaviorSubject<Map<string, Joined>> = new BehaviorSubject<Map<string, Joined>>(new Map([]));

  /**
   * The agent groups that the agent has selected  in `selectedOpGroupIds` enriched with the group name.
   */
  public agentGroups: Map<number, string> = new Map();

  /**
   * Agent groups that this supervisor belongs to.
   */
  public currentAgentGroups: Map<number, string> = new Map();

  /**
   * Agent groups for engagements that this agent is supervising.
   */
  public supervisingGroups: Set<string> = new Set();

  /**
   * This is not used, it is read but never updated.
   */
  private selectedGroups: Set<number> = new Set();

  /**
   * The groups ids that have been selected in the drop down list
   */
  private selectedOpGroupIds: Set<number> = new Set();

  // True is card mode, false is table mode.
  public cardMode = true;
  public kpiSet: KpiSetting[];
  public dashboardData$: BehaviorSubject<DashboardData> = new BehaviorSubject<DashboardData>(new DashboardData());

  public helpRequestEngagements: Map<string, HelpRequest> = new Map();

  public privateChatAvailable: boolean;

  private dashboardTimer = -1;
  public noInternet: BehaviorSubject<boolean>;

  private readonly unloadMethod = (e: Event) => this.onBeforeUnload(e);

  constructor(
    private visitorService: VisitorService,
    private engagementService: EngagementService,
    private onlineService: OnlineService,
    private settingsService: SettingsService,
    private loggingService: LoggingService,
    private router: Router,
    private authService: AuthService,
    private crmService: CrmService,
    private featureService: FeatureService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private notificationService: NotificationService,
    private titleService: Title,
  ) {
    this.noInternet = this.visitorService.noInternet;
    this.currentAgent = this.authService.currentAgent;
  }

  public ngOnInit() {
    this.privateChatAvailable = this.featureService.has(Features.PRIVATE_CHAT);

    if (this.featureService.has(Features.VEESTUDIO)) {
      this.filterByVeeStudio();
    } else {
      this.filterByVeechat();
    }

    this.visitorService.startConnection(LicenceType.Supervisor, false).subscribe(success => {
      if (!success) {
        this.router.navigateByUrl('/login');
      } else {
        if (SupervisorComponent.USE_WINDOW_BEFOREUNLOAD) {
          // Prevent page reloading if user has interacted with it
          window.addEventListener('beforeunload', this.unloadMethod);
        }

        this.onlineService.setCurrentState(OnlineState.OnBreak, WorkStatus.None);
        this.settingsService.loadAll();
        this.crmService.loadAll();

        const stateSub = this.onlineService.currentState.pipe(distinctUntilChanged())
          .subscribe(([state, _]) => this.currentState$.next(state));
        this.subscriptions.push(stateSub);


        this.visitorService.getKPIData(this.currentAgent.value.sitename).subscribe(data => {
          this.kpiSet = data;
        });
        this.startDashboardTimer();
      }

      this.visitors$ = this.visitorService.visitors;

      this.visitors$.subscribe((newVisitors) => this.updateVisitors(newVisitors));
    });

    this.visitorService.agentStatus.subscribe(a => {
      this.veechatAgents$.next(new Map([...a].filter(([_, v]) => v.status === OnlineState.MultiChat || v.status === OnlineState.OnBreak)));
      this.veestudioAgents$.next(new Map([...a].filter(([_, v]) => v.status !== OnlineState.MultiChat && v.status !== OnlineState.OffLine)));
    });

    this.currentAgent.subscribe(agent => {
      this.selectedOpGroupIds = new Set<number>();
      this.agentGroups = new Map<number, string>();
      if (agent && agent.groups) {
        agent.groups.map(g => {
          this.currentAgentGroups.set(g.Id, g.Name);
          this.agentGroups.set(g.Id, g.Name);
          this.selectedOpGroupIds.add(g.Id); // If No group is selected assume.All Selected.
        });
      }
    });

    this.visitorService.visitors.subscribe(v => {
      this.veestudioVisitors.next(v.filter(visitor => !visitor.isMultichat && visitor.isEngaged));
      this.notEngagedVeeStudioVisitors.next(v.filter(visitor => !visitor.isMultichat && !visitor.isEngaged));

      this.veechatVisitors.next(v.filter(visitor => visitor.isMultichat && !visitor.isAsync && visitor.isEngaged));
      this.notEngagedVeeChatVisitors.next(v.filter(visitor => visitor.isMultichat && !visitor.isAsync && !visitor.isEngaged));

      this.asyncChatVisitors.next(v.filter(visitor => visitor.isAsync && visitor.isEngaged));
      this.notEngagedAsyncChatVisitors.next(v.filter(visitor => visitor.isAsync && !visitor.isEngaged));
    });

    if (this.privateChatAvailable) {
      this.visitorService.visitors.subscribe(v => {
        this.updateHelpVisitors(v);

        v.map(vis => {
          if (vis.agentRequestingAssistance && !this.helpRequestEngagements.has(vis.engagementGuid)) {
            vis.agentRequestAssistTime = new Date();
            this.helpRequestEngagements.set(vis.engagementGuid, {
              type: HelpRequestType.EngagementHelpRequest,
              engagementGuid: vis.engagementGuid,
              requestTime: vis.agentRequestAssistTime,
              visitor: vis,
            });

            this.alertService.playNewPrivateChatSound();
            const title = 'Agent Assistance Request';
            const options = {body: 'New Private Chat', icon: '../../assets/images/veechat-icon.png', tag: 'visitorCall', silent: true};
            this.notificationService.create(title, options);

          } else if (this.helpRequestEngagements.has(vis.engagementGuid) && !vis.agentRequestingAssistance) {
            this.helpRequestEngagements.delete(vis.engagementGuid);
          }
        });

        this.helpRequestEngagements = new Map([...this.helpRequestEngagements.entries()].sort((v1, v2) =>
          v1[1].requestTime.getTime() - v2[1].requestTime.getTime()));
      });
    }

    this.engagementService.engagements.subscribe(engList => {
      this.supervisingEngagements.value.clear();
      engList.filter(e => e.privateChatEnabled || e.supPublicChatEnabled)
      .map(e => this.supervisingEngagements.value.set(e.engagementId.toString(), {
        type: SupervisingEngagementTypes.Supervising,
        engagement: e,
        engagementId: e.engagementId.toString(),
        visitor: e.visitor
      }));
    });
  }


  ngOnDestroy() {
    this.subscriptions.map(sub => sub.unsubscribe());
    this.stopDashboardTimer();
  }

  updateHelpVisitors(newVisitors: Visitor[]) {
    const validEngagementIds = newVisitors.map(visitor => visitor.engagementGuid);

    [...this.helpRequestEngagements].map(([_, helpRequest]) => {
      switch (helpRequest.type) {
        case HelpRequestType.EngagementHelpRequest:
          if (!validEngagementIds.find((id) => id === helpRequest.engagementGuid)) {
            this.helpRequestEngagements.delete(helpRequest.engagementGuid);
          }
          break;
      }
    });
  }

  showCardView() {
    this.cardMode = true;
  }

  showTableView() {
    this.cardMode = false;
  }

  filterByVeechat() {
    this.activeItem = this.productModes[1];
    this.titleService.setTitle('VeeChat');
    this.selectedVisitors$ = this.veechatVisitors;
    const filter: (status: AgentStatus) => boolean = (status) => {
      return (status.workStatus & WorkStatus.Multichat) && status.groups.some(g => this.selectedOpGroupIds.has(g.Id));
    }
    this.selectedAgents$ = this.veechatAgents$.pipe(map((s) => this.agentGroupFilter(s, filter)));
    this.notEngagedVisitors$ = this.notEngagedVeeChatVisitors;
  }

  filterByAsyncChat() {
    this.activeItem = this.productModes[2];
    this.titleService.setTitle('VeeChat');
    this.selectedVisitors$ = this.asyncChatVisitors;
    const filter: (status: AgentStatus) => boolean = (status) => {
      return (status.workStatus & WorkStatus.Async) && status.groups.some(g => this.selectedOpGroupIds.has(g.Id));
    }
    this.selectedAgents$ = this.veechatAgents$.pipe(map((s) => this.agentGroupFilter(s, filter)));
    this.notEngagedVisitors$ = this.notEngagedAsyncChatVisitors;
  }

  filterByVeeStudio() {
    this.activeItem = this.productModes[0];
    this.titleService.setTitle('VeeStudio');
    this.selectedVisitors$ = this.veestudioVisitors;
    const filter: (status: AgentStatus) => boolean = (status) => status.groups.some(g => this.selectedOpGroupIds.has(g.Id));
    this.selectedAgents$ = this.veestudioAgents$.pipe(map((s) => this.agentGroupFilter(s, filter)));
    this.notEngagedVisitors$ = this.notEngagedVeeStudioVisitors;
  }

  private agentGroupFilter(agents: Map<string, AgentStatus>, filter: (AgentStatus) => boolean): Map<string, AgentStatus> {
    // If there are no selected groups then do not apply the filter.
    // Otherwise, filter the agents to only have those belonging to the
    // selectedOpGroupIds collection.
    if (this.selectedOpGroupIds.size === 0) {
      return agents;
    } else {
      const filtered = new Map<string, AgentStatus>();
      for (const [username, status] of agents) {
        if (filter(status)) {
          filtered.set(username, status);
        }
      }
      return filtered;
    }
  }

  onChange($event) {
    this.visitorService.sendFilterDataForOpGroups([...$event.value].join(','));
    this.filterByIds($event.value);
  }

  filterByIds(ids: Set<number>) {
    this.selectedOpGroupIds = new Set();
    for (const id of ids) {
      this.selectedOpGroupIds.add(id);
    }

    // Update the filter used in the dashboard-view
    this.agentGroups = new Map();
    if (this.selectedOpGroupIds.size === 0) {
      // Size zero, all groups selected
      for (const [id, name] of this.currentAgentGroups) {
        this.agentGroups.set(id, name);
      }
    } else {
      for (const [id, name] of this.currentAgentGroups) {
        if (this.selectedOpGroupIds.has(id)) {
          this.agentGroups.set(id, name);
        }
      }
    }

    // Push the agents again to retrigger the filter
    this.veechatAgents$.next(this.veechatAgents$.value);
    this.veestudioAgents$.next(this.veestudioAgents$.value);
  }

  onEndBreak() {
    this.onlineService.setCurrentState(OnlineState.Supervisor, WorkStatus.None);
    this.router.navigateByUrl('/supervisor');
  }

  enterSession(sessionConfiguration: SnoopingConfiguration) {
    if (sessionConfiguration.type === SnoopingType.Visitor) {
      this.enterVisitorSession(sessionConfiguration.visitor);
    } else if (sessionConfiguration.type == SnoopingType.AsyncChat) {
      this.enterAsyncChatSession(sessionConfiguration.visitor);
    } else {
    }
  }

  private enterVisitorSession(visitor: Visitor) {
    if (!visitor.engagementGuid) {
      this.loggingService.warn(`Visitor ${visitor.userGuid} has no engagement guid!`);
      return;
    }

    const engagement = this.engagementService.getEngagement(visitor.engagementGuid);
    if (engagement) {
      this.displayEngagementView(engagement);
    } else if (this.snoopingChats.value.has(visitor.engagementGuid)) {
      this.displaySnooping(this.snoopingChats.value.get(visitor.engagementGuid));
    }
  }

  private enterAsyncChatSession(visitor: Visitor) {
    if (this.snoopingChats.value.has(visitor.sessionGuid)) {
      this.displaySnooping(this.snoopingChats.value.get(visitor.sessionGuid));
    } else {
      this.visitorService.getAsyncChat({ Id: visitor.sessionGuid }).subscribe(newConversation => {
        const snooping: SnoopingAsyncChat = {
          type: SupervisingEngagementTypes.SnoopingOnAsyncChat,
          visitor,
          conversation: newConversation,
          conversationId: visitor.sessionGuid,
        };
        this.snoopingChats.value.set(visitor.sessionGuid, snooping);
        newConversation.visitor.agentGroup = visitor.agentGroup; // Hack to add in agent groups
        this.displaySnooping(snooping);
      });
    }
  }

  startSnooping(configuration: SnoopingConfiguration) {
    this.loadingService.isLoading.next(false);
    switch (configuration.type) {
      case SnoopingType.AsyncChat:
        this.enterAsyncChatSession(configuration.visitor);
        break;

      case SnoopingType.Visitor:
        this.startSnoopingOnVisitor(configuration.visitor);
        break;
    }
  }

  private startSnoopingOnVisitor(visitor: Visitor) {
    if (!visitor.engagementGuid) {
      this.loggingService.warn(`Visitor ${visitor.userGuid} has no engagement guid!`);
      return;
    }

    const snoopingVisitor: SnoopingEngagement = {
      type: SupervisingEngagementTypes.Snooping,
      engagementId: visitor.engagementGuid,
      visitor
    };

    this.snoopingChats.value.set(visitor.engagementGuid, snoopingVisitor);

    this.displaySnooping(snoopingVisitor);
  }

  stopSnooping(snoopingEngagement: Snooping) {
    if (snoopingEngagement.type === SupervisingEngagementTypes.Snooping) {
      this.snoopingChats.value.delete(snoopingEngagement.engagementId);
    } else if (snoopingEngagement.type === SupervisingEngagementTypes.SnoopingOnAsyncChat) {
      this.visitorService.leaveAsyncChat(snoopingEngagement.conversation.id, snoopingEngagement.conversation.threadId);
      this.snoopingChats.value.delete(snoopingEngagement.visitor.sessionGuid);
    } else {
    }

    this.refreshSupervisingGroups();

    this.showDashboard();
  }

  private refreshSupervisingGroups() {
    // Clear any agent groups that aren't being used.
    this.supervisingGroups.clear();

    [...this.snoopingChats.value].map(([_, s]) => {
      const groups = s.visitor.agentGroup;
      groups.map(g => {
        this.supervisingGroups.add(g.Name);
      });
    });

    [...this.supervisingEngagements.value].map(([_, s]) => {
      const groups = s.visitor.agentGroup;
      groups.map(g => {
        this.supervisingGroups.add(g.Name);
      });
    });
  }

  joinEngagement(visitor: Visitor, enablePrivateChat: boolean): Promise<Engagement> {
    this.loadingService.isLoading.next(true);
    this.snoopingChats.value.delete(visitor.engagementGuid);

    const engagementId = visitor.engagementGuid;

    return new Promise((resolve, reject) => {
      const newEngagement = this.engagementService.createSupervisorEngagement(visitor, LicenceType.Supervisor);

      if (newEngagement) {
        newEngagement.currentState.subscribe(newState => {
          if (newState.type === EngagementState.Ended) {
            this.engagementService.endEngagement(engagementId);
          }
        });

        newEngagement.initialise().subscribe((success) => {
          if (success) {
            this.loggingService.info('Connected');
            if (enablePrivateChat) {
              newEngagement.enablePrivateChat();
            } else {
              newEngagement.supPublicChatEnabled.next(true);
            }
            if (newEngagement.visitor.agentRequestingAssistance) {
              this.visitorService.acceptAgentHelpRequest(newEngagement.engagementId.toString());
            }
            this.displayEngagementView(newEngagement);

            // trigger a notification for a new message
            newEngagement.newMessage.subscribe(({ privateMessage, message }) => {
              if (message) {
                if (privateMessage) {
                  this.alertService.playNewPrivateMessageSound();
                } else {
                  this.alertService.playNewMessageSound();
                }

                const title = 'New Message';
                const options = {body: message.message, icon: '../../assets/images/veechat-icon.png', tag: 'visitorMessage', silent: true};
                this.notificationService.create(title, options);
              }
            });

            resolve(newEngagement);
          } else {
            this.loggingService.error('Failed to connect');
            this.alertService.addResourceAlert("SUPERVISOR_ALERT_ERRORACCEPTREQUEST", `Error joining engagement.`);
            newEngagement.endChat();
            this.startSnooping({ type: SnoopingType.Visitor, visitor });
            reject('Failed to connect to engagement hub');
          }
        }, (err) => {
          newEngagement.endChat();
          this.loggingService.error('Error trying to connect', err);
          this.alertService.addResourceAlert("SUPERVISOR_ALERT_ERRORACCEPTREQUEST", `Error joining engagement.`);
          this.startSnooping({ type: SnoopingType.Visitor, visitor });
          reject(err);
        });
      } else {
        this.loggingService.error('Unable to create engagement!');
        this.alertService.addResourceAlert("SUPERVISOR_ALERT_ERRORACCEPTREQUEST", `Error creating engagement.`);
        this.startSnooping({ type: SnoopingType.Visitor, visitor });
        reject('Engagement service failed to create engagement');
      }
    });
  }

  displayEngagementView(engagement: Engagement) {
    this.loadingService.isLoading.next(false);
    const newView: JoinedEngagement = {
      type: SupervisingEngagementTypes.Supervising,
      engagementId: engagement.engagementId.toString(),
      engagement,
      visitor: engagement.visitor,
    };

    this.supervisingEngagement = newView;
  }

  private displaySnooping(snooping: Snooping) {
    this.loadingService.isLoading.next(false);
    this.supervisingEngagement = snooping;

    // todo: how do this?
    snooping.visitor.agentGroup.map(group => {
      if (this.selectedGroups.size > 0 && this.selectedGroups.has(group.Id)) {
        this.supervisingGroups.add(group.Name);
      } else if (this.selectedGroups.size === 0) {
        this.supervisingGroups.add(group.Name);
      }
    });
  }

  helpAgent(snoopingConfiguration: SnoopingConfiguration) {
    switch (snoopingConfiguration.type) {
      case SnoopingType.Visitor:
        this.startSnoopingOnVisitor(snoopingConfiguration.visitor);
        this.joinEngagement(snoopingConfiguration.visitor, true);
        break;
    }
  }

  engagementEnded(engagement: Engagement) {
    this.supervisingEngagements.value.delete(engagement.engagementId.toString());
    this.refreshSupervisingGroups();
    this.showDashboard();
  }

  getVisitorsArray(): Snooping[] {
    return [...this.snoopingChats.value.values()];
  }

  getEngagementsArray() {
    return [...this.supervisingEngagements.value.values()];
  }

  private onBeforeUnload(e: Event) {
    if (this.engagementService.activeEngagements.value === 0) {
      return;
    }

    e.preventDefault();
    e.returnValue = true;
  }

  public showDashboard() {
    this.supervisingEngagement = SupervisorComponent.NO_SUPERVISING_ENGAGEMENT;
  }

  private updateVisitors(newVisitors: Visitor[]) {
    const validEngagementIds = newVisitors.map(visitor => visitor.engagementGuid);

    // Store any engagements that we've lost to remove them later
    const idsToRemove: string[] = [];

    for (const [engagementId, snooping] of this.snoopingChats.value) {
      if (snooping.type === SupervisingEngagementTypes.Snooping && !validEngagementIds.find((id) => id === engagementId)) {
        idsToRemove.push(engagementId);

        // Clear the view if we have lost the engagement from our list
        // @ts-ignore ignoring missing engagementId property, undefined is a fine value for comparison.
        if (this.supervisingEngagement.type !== SupervisingEngagementTypes.None && this.supervisingEngagement.engagementId === engagementId) {
          this.showDashboard();
        }
      }
    }

    // Now remove them
    for (const id of idsToRemove) {
      this.snoopingChats.value.delete(id);
      this.supervisingEngagements.value.delete(id);
    }
  }

  private startDashboardTimer() {
    this.stopDashboardTimer();
    this.dashboardTimer = window.setInterval(() => this.getDashboardData(), SupervisorComponent.DASHBOARD_TIMER_MS);
  }

  private stopDashboardTimer() {
    window.clearInterval(this.dashboardTimer);
    this.dashboardTimer = -1;
  }


  private getDashboardData() {
    this.subscriptions.push(
      this.visitorService.getDashboardData().subscribe({
        next: (data) => this.dashboardData$.next(data),
        error: (err) => this.handleDashboardConnectionFailure(err)
    }));
  }

  private handleDashboardConnectionFailure(err) {
    this.stopDashboardTimer();
    this.loggingService.error(err);
    this.alertService.addAlert(this.settingsService.getResourceOrDefault("SERVER_ERROR_DISCONNECTED", "You have been disconnected from the server."), AlertType.Danger);
    this.router.navigateByUrl('/login');
  }

  protected readonly ProductMode = ProductMode;
}
