<div class="d-flex bottom-ctrls justify-content-end gap-1">
  <p-button appDebounceClick id="block-button" styleClass="btn-bottom-control center-icon" *ngIf="!isBlockedOn"
            [disabled]="!isPrimaryAgent"
            severity="secondary"
            [pTooltip]='"ENGAGEMENTCONTROLS_BLOCK_CUSTOMER"  | Translate : "Block customer"'
            (clicked)="blockCustomer()"
  >
    <fa-icon [icon]="faBan"/>
  </p-button>

  <p-button appDebounceClick id="help-button" styleClass="btn-bottom-control center-icon"
            [severity]="requestingHelp ? 'warning' : 'secondary'"
            *ngIf="!isPostEngagement && privateChatAvailable"
            [disabled]="!isPrimaryAgent"
            [pTooltip]='"ENGAGEMENTCONTROLS_TOOLTIP_HELP"  | Translate : "Request Help"'
            (clicked)="helpRequest()">
    <fa-icon [icon]="faHand"/>
  </p-button>

    <p-button appDebounceClick *ngIf="!isPostEngagement && desktopRecordingAvailable" id="record-button"
                           [disabled]="desktopRecordingAlways"
                           [severity]="isRecording ? 'danger' : 'secondary'"
                           styleClass="btn-bottom-control center-icon"
                           pTooltip='{{ "ENGAGEMENTCONTROLS_TOOLTIP_RECORDING"  | Translate : "Desktop Recording" }}'
                           (clicked)="toggleDesktopRecording()">
        <fa-icon [icon]="faCircle"/>
    </p-button>

  <p-button appDebounceClick *ngIf="!isPostEngagement && multipartyAvailable" id="join-button" styleClass="btn-bottom-control center-icon"
            [severity]="isJoin ? 'primary' : 'secondary'"
            tooltipPosition="top" [disabled]="isTransfer"
            [pTooltip]='"ENGAGEMENTCONTROLS_TOOLTIP_MULTIPARTY"  | Translate : "Multiparty"'
            (clicked)="joinEngagement()">
    <fa-icon [icon]="faUsers"/>
  </p-button>

  <p-button appDebounceClick *ngIf="!isPostEngagement && transferAvailable" id="transfer-button" styleClass="btn-bottom-control center-icon"
            [severity]="isTransfer ? 'primary' : 'secondary'" (clicked)="transferEngagement()"
            tooltipPosition="top"
            [pTooltip]='"ENGAGEMENTCONTROLS_TOOLTIP_TRANSFER"  | Translate : "Transfer"'
            [disabled]="roomIsFull() || isJoin">
    <fa-icon [icon]="faRightLeft"/>
  </p-button>

    <p-button appDebounceClick id="pause-button"
                           styleClass="btn-bottom-control center-icon" [severity]="callPaused ? 'primary' : 'secondary'"
                           *ngIf="!isPostEngagement && canPause"
                           [disabled]="!isPrimaryAgent" tooltipPosition="top"
                           [pTooltip]='"ENGAGEMENTCONTROLS_TOOLTIP_PAUSE"  | Translate : "Pause"'
                           [class.paused]="callPaused"
                           (clicked)="togglePauseCall()">
        <fa-icon [icon]="faPause"/>
    </p-button>


  <p-divider *ngIf="!isPostEngagement" layout="vertical" ></p-divider>

  <p-button appDebounceClick id="end-button" *ngIf="!isPostEngagement" severity="danger" styleClass="btn-bottom-control center-icon"
            [pTooltip]="(this.roomAgentsSize > 1 || !this.isPrimaryAgent) ? ('ENGAGEMENTCONTROLS_TOOLTIP_LEAVECHAT' | Translate: 'Leave Chat') : ('ENGAGEMENTCONTROLS_TOOLTIP_ENDCHAT' | Translate: 'End Chat')"
            (clicked)="endEngagement()">
    <fa-icon [icon]="faXmark"/>
  </p-button>
</div>

