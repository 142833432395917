import {Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {
  faTimes,
  faCaretDown,
  faTimesCircle, faCircleExclamation, faCircleCheck
} from '@fortawesome/free-solid-svg-icons';
import { BrowsingHistory } from '../../services/crm-service/browsing-history';
import { EngagementEvent } from '../../services/crm-service/engagement-event';
import { VisitorSessionDetails } from '../../classes/visitor/VisitorSessionDetails';
import { VisitorSiteDetails } from '../../classes/visitor/VisitorSiteDetails';
import { FeatureService, Features } from '../../services/feature-service/feature.service';
import { Opportunity } from '../../services/crm-service/opportunity';
import { CrmStructure } from '../../services/crm-service/crm-category';
import { CrmEmailStateType } from '../../services/crm-service/crm-email-state';
import { CrmService } from '../../services/crm-service/crm.service';
import {CheckListField, ChecklistFieldType} from "../../classes/checklist/CheckListField";
import {Engagement, EngagementState, EngagementStateType} from "../../services/engagement";
import {InsertAppointment} from "../../services/crm-service/appointment";
import {MatchedCustomer} from "../../services/crm-service/matched-customer";


export enum Section {
  Contact = 'Contact',
  Opportunities = 'Opportunities',
  Tasks = 'Tasks',
  SessionInfo = 'Session Information',
  BrowsingHistory = 'Browsing History',
  SessionHistory = 'Session History',
  Appointments = 'Appointments',
  Emails = 'Emails'
}

@Component({
  selector: 'app-visitor-information',
  templateUrl: './visitor-information.component.html',
  styleUrls: ['./visitor-information.component.scss']
})
export class VisitorInformationComponent implements OnInit {


  @Input() engagement: Engagement;
  opportunities: Opportunity[];
  @Input() checklistfields: CheckListField[];
  @Input() currentState: EngagementStateType;


  _currentSection: Section = Section.Contact;
  get currentSection(): Section {
    return this._currentSection;
  }

  @Input() set currentSection(section: Section) {
    if (section !== this._currentSection) {
      this._currentSection = section;
    }
  }
  @Output() currentSectionChange = new EventEmitter<Section>();

  public crmAvailable: boolean;

  @Input() currentUrl: string;
  @Input() visitorDetails: VisitorSiteDetails = {page: '', location: '', prevOp: '', section: '', useragent: ''};
  @Input() visitorSessionDetails: VisitorSessionDetails = {clientHeight:'0', clientWidth:'0', deviceScaleFactor: '1', ip:'', isMobile: false, isTablet: false, organisation:'', referrer: ''};
  @Input() visitorSessionHistory: EngagementEvent[] = [];
  @Input() visitorBrowsingHistory: BrowsingHistory[] = [];
  @Input() visitorCrmData: CrmStructure = null;
  @Input() emailState: CrmEmailStateType;
  @Input() userGuid:string;

  @Output() closeRecord = new EventEmitter();

  @Output() updatedData: EventEmitter<CrmStructure> = new EventEmitter<CrmStructure>();
  @Output() saveOpp = new EventEmitter<Opportunity>();
  @Output() loadOldCustomer: EventEmitter<MatchedCustomer> = new EventEmitter<MatchedCustomer>();

  Section = Section;
  faCaretDown = faCaretDown;
  faTimes = faTimes;

  constructor(private featureService: FeatureService,
    private crmService: CrmService) {
    this.crmAvailable = this.featureService.has(Features.CRM);
  }

  ngOnInit() {
    this.crmAvailable = this.featureService.has(Features.CRM);
    this.getOpportunitiesForContact(this.userGuid);
  }

  getOpportunitiesForContact(vee24Guid: string) {
    this.crmService.getOpportunitiesForContact(vee24Guid).subscribe(data => {
      this.opportunities = data ?? [];
    });
  }

  scrollToSection($element): void {
    this._currentSection = $element.firstElementChild.textContent.trim();
    $element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  close() {
    this.closeRecord.emit();
  }

  isPrimaryAgent() {
    return this.engagement?.isPrimaryAgent();
  }

  getChecklistErrorType() {
    if (this.checklistfields.some(field => !field.IsRulePassed &&
      field.CheckType === ChecklistFieldType.MANDATORY &&
      !field.CRMField.startsWith('Custom Task:'))) {
      return ChecklistFieldType.MANDATORY;
    } else if (this.checklistfields.some(field => !field.IsRulePassed &&
      field.CheckType === ChecklistFieldType.RECOMMENDED &&
      !field.CRMField.startsWith('Custom Task:'))) {
      return ChecklistFieldType.RECOMMENDED;
    }
  }

  isReadOnly(){
    return !this.engagement;
  }

  saveOpportunity(opp: Opportunity) {
    this.saveOpp.emit(opp);
  }

  saveCrmData() {
    this.updatedData.emit();
  }

  loadCustomer(customer: MatchedCustomer) {
    this.getOpportunitiesForContact(customer.customerGuid);
    this.loadOldCustomer.emit(customer);
  }


  protected readonly ChecklistFieldType = ChecklistFieldType;
  protected readonly faTimesCircle = faTimesCircle;
  protected readonly faCircleExclamation = faCircleExclamation;
  protected readonly faCircleCheck = faCircleCheck;
  protected readonly EngagementState = EngagementState;
}
