import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {EngagementService} from '../../../services/engagement.service';
import {Engagement, EngagementStateType, EngagementState} from '../../../services/engagement';
import {LoggingService} from '../../../services/logging.service';
import { VisitorDetails } from '../../../classes/visitor-details';
import { VisitorSiteDetails } from "../../../classes/visitor/VisitorSiteDetails";
import { VisitorSessionDetails } from "../../../classes/visitor/VisitorSessionDetails";
import { Visitor } from '../../../services/visitor-service/visitor';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CrmStructure } from '../../../services/crm-service/crm-category';
import { EngagementEvent } from '../../../services/crm-service/engagement-event';
import { BrowsingHistory } from '../../../services/crm-service/browsing-history';
import {TextMessage, TextMessages} from '../../../classes/TextMessage';
import { EndType, EndChatModalComponent } from '../../../components/end-chat-modal/end-chat-modal.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { CrmEmailState } from '../../../services/crm-service/crm-email-state';
import { Opportunity } from '../../../services/crm-service/opportunity';
import { CrmService } from '../../../services/crm-service/crm.service';
import { distinctUntilChanged } from 'rxjs/operators';
import {ModalService} from '../../../services/modal.service';
import {Section} from "../../../components/visitor-information/visitor-information.component";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {TranslatePipe} from "../../../filters/Translate.pipe";

@Component({
  selector: 'app-supervisor-engagement',
  templateUrl: './supervisor-engagement.component.html',
  styleUrls: ['./supervisor-engagement.component.scss']
})
export class SupervisorEngagementComponent implements OnInit {
  private static readonly EMPTY_VISITOR_DETAILS = {
    page: '',
    section: '',
    prevOp: '',
    location: '',
    useragent: '',
  };

  private static readonly EMPTY_VISITOR_SESSION_DETAILS = {
    ip: '',
    referrer: '',
    organisation: '',
    deviceScaleFactor: '1',
    isMobile: false,
    isTablet: false,
    clientHeight: '0',
    clientWidth: '0',
  };

  private modalRef: DynamicDialogRef;

  private _engagementId: string;
  public engagement: Engagement;
  @Input() set engagementId(engagementId: string) {
    if (engagementId === this._engagementId) {
      this.logger.info(`Ignoring rebind request for ${engagementId} as it is current engagement`);
      return;
    }

    const engagement = this.engagementService.getEngagement(engagementId);

    this.clearBindings();

    if (null == engagement) {
      this.logger.error(`Failed to find engagement ${engagementId}`);
    } else {
      this._engagementId = engagementId;
      this.engagement = engagement;
      this.rebindUI();
    }
  }

  @Input() privateChatAvailable: boolean;

  public currentSection: Section = Section.Contact;
  public currentState$: BehaviorSubject<EngagementStateType>;
  public currentUrl$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public agentText = '';
  public userGuid = '';
  public opportunities: Opportunity[];
  public visitorMessage$: BehaviorSubject<TextMessage>;
  public privateChatMessages: BehaviorSubject<TextMessage[]>;
  public visitorDetails$: BehaviorSubject<VisitorSiteDetails> = new BehaviorSubject<VisitorSiteDetails>(SupervisorEngagementComponent.EMPTY_VISITOR_DETAILS);
  public visitorSessionDetails$: BehaviorSubject<VisitorSessionDetails> = new BehaviorSubject<VisitorSessionDetails>(SupervisorEngagementComponent.EMPTY_VISITOR_SESSION_DETAILS);
  public visitorCrmData$: BehaviorSubject<CrmStructure> = new BehaviorSubject(new CrmStructure());
  public visitorSessionHistory$: BehaviorSubject<EngagementEvent[]> = new BehaviorSubject<EngagementEvent[]>([]);
  public visitorBrowsingHistory$: BehaviorSubject<BrowsingHistory[]> = new BehaviorSubject<BrowsingHistory[]>([]);

  public privateChatEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public supPublicChatEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  CrmEmailState = CrmEmailState;

  @Output() engagementEnded = new EventEmitter();

  constructor(
    private logger: LoggingService,
    private engagementService: EngagementService,
    private modalService: ModalService,
    private crmService: CrmService,
    private translate: TranslatePipe
  ) { }

  ngOnInit() {
  }

  private clearBindings() {
  }

  private rebindUI() {
    this.currentState$ = this.engagement.currentState;
    this.currentState$.subscribe(state => {
      if (state.type === EngagementState.Ended) {
        this.engagementEnded.emit();
      }
    });

    const urlSub = this.engagement.currentPage
      .pipe(distinctUntilChanged())
      .subscribe(url => {
        if (url && url.length > 0) {
          this.currentUrl$.next(url);
        } else {
          this.currentUrl$.next(this.engagement.visitor.lastPage);
        }
      });

    this.subscriptions.push(urlSub);

    this.visitorMessage$ = this.engagement.visitorTypingMessage;
    this.privateChatMessages = this.engagement.privateMessages;

    this.visitorDetails$.next(this.createDetails(this.engagement.visitor));

    this.engagement.visitor.details.subscribe(deets => {
      if (deets != null) {
        this.visitorSessionDetails$.next(this.createSessionDetails(deets));
      }
    });

    this.engagement.visitor.crmData.subscribe(crmData => {
      if (crmData != null) {
        this.visitorCrmData$.next(crmData);
      }
    });

    this.engagement.visitor.sessionHistory.subscribe(history => {
      if (history != null) {
        this.visitorSessionHistory$.next(history);
      }
    });

    this.engagement.visitor.browsingHistory.subscribe(history => {
      if (history != null) {
        this.visitorBrowsingHistory$.next(history);
      }
    });

    if (this.IsCRMOpportunityAvailable()) {
      const opp = this.crmService.getOpportunitiesForContact(this.engagement.visitor.userGuid).subscribe(op => {
        if (op != null) {
          this.opportunities = Array.from(op);
        }
      });
      this.subscriptions.push(opp);
    }

    this.engagement.privateChatEnabled.subscribe(enabled => {
      this.privateChatEnabled.next(enabled)
    });
    this.engagement.supPublicChatEnabled.subscribe(enabled => {
      this.supPublicChatEnabled.next(enabled)
    });

    this.userGuid = this.engagement.visitor.userGuid;
  }

  IsCRMOpportunityAvailable(): boolean {
    return true;
  }


  private createDetails(visitor: Visitor): VisitorSiteDetails {
    return {
      page: visitor.lastPage,
      section: visitor.lastSection,
      prevOp: visitor.previousOperator,
      location: visitor.location,
      useragent: visitor.browser,
    };
  }

  private createSessionDetails(details: VisitorDetails): VisitorSessionDetails {
    return {
      organisation: details.Org,
      ip: details.IPAddress,
      referrer: details.Referrer,
      deviceScaleFactor: details.DevicePixelRatio,
      isMobile: false,
      isTablet: false,
      clientWidth: details.ClientWidth,
      clientHeight: details.ClientHeight
    };
  }

  joinPrivateChat(join: boolean) {
    if (join) {
      this.engagement.enablePrivateChat();
    } else {
      this.engagement.privateChatEnabled.next(false);
      if (!this.engagement.supPublicChatEnabled.value) {
        this.engagement.leaveChat();
        this.onEngagementEnded();
      }
    }
  }

  joinPublicChat(join: boolean) {
    if (join) {
      this.showJoinPublicChatModal();
    } else {
      this.showLeavePublicChatModal();
    }
  }

  sendMessage() {
    const message = this.agentText.trim();

    if (message.length === 0) {
      this.logger.debug('Trying to send a 0 length agent message, ignoring.');
      return;
    }

    this.agentText = '';
    this.engagement.sendChatMessage(message);
  }

  sendPrivateChatMessage(privateMessage: string) {
    this.engagement.sendPrivateChatMessage(privateMessage);
  }

  showJoinPublicChatModal() {
    const data = {
      endChatType: EndType.JOIN_PUBLIC_CHAT,
      onJoinPublicChat: () => {
        this.modalService.closeModal(this.modalRef);
        this.modalRef = null;
        this.engagement.supPublicChatEnabled.next(true);
      }, cancelEndChat: () => {
        this.modalService.closeModal(this.modalRef);
      }
    };

    this.modalRef = this.modalService.openModal(EndChatModalComponent, {
      data,
      closeOnEscape: false,
      showHeader: true,
      closable: false,
      header: this.translate.transform('ENDCHATMODAL_HEADER_CUSTOMERCHAT', 'Enter the Customer Chat?'),
    });
  }

  showLeavePublicChatModal() {
    const data = {
      endChatType: EndType.LEAVE_PUBLIC_CHAT,
      onLeavePublicChat: () => {
        this.modalService.closeModal(this.modalRef);
        this.modalRef = null;
        if (!this.privateChatAvailable || !this.engagement.privateChatEnabled.value) {
          this.onEngagementEnded();
          this.engagement.leaveChat();
        } else {
          this.engagement.supPublicChatEnabled.next(false);
        }
      }, cancelEndChat: () => {
        this.modalService.closeModal(this.modalRef);
        this.modalRef = null;
      }
    };

    this.modalRef = this.modalService.openModal(EndChatModalComponent, {
      data,
      closeOnEscape: false,
      showHeader: true,
      closable: false,
      header: this.translate.transform('ENDCHATMODAL_HEADER_EXITCUSTOMERCHAT', 'Exit the Customer Chat?'),
      contentStyle: { width: '500px'}
    });
  }

  onEngagementEnded() {
    this.engagementEnded.emit();
  }

  protected readonly faPaperPlane = faPaperPlane;
}
