<div class="d-flex bottom-ctrls justify-content-end gap-1">
  <p-button appDebounceClick id="block-button"
            *ngIf="!isBlocked" severity="secondary"
            styleClass="btn-bottom-control center-icon"
            [pTooltip]='"VEECHAT_ASYNC_BLOCK_CUSTOMER"  | Translate : "Block customer"'
            (clicked)="blockCustomer.emit()"
  >
    <fa-icon [icon]="faBan"/>
  </p-button>

  <p-button appDebounceClick id="cold-transfer-button"
            *ngIf="!isBlocked && !ended && hasTransfer"
            styleClass="btn-bottom-control center-icon"
            severity="secondary"
            [pTooltip]='"VEECHAT_ASYNC_COLD_TRANSFER_CUSTOMER"  | Translate : "Cold transfer customer"'
            (clicked)="transferCold.emit()"
  >
    <fa-icon [icon]="faUsers"/>
  </p-button>

  <p-button appDebounceClick id="warm-transfer-button" [canToggle]="false"
            *ngIf="!isBlocked && !ended && hasTransfer"
            styleClass="btn-bottom-control center-icon" severity="secondary"
            [pTooltip]='"VEECHAT_ASYNC_WARM_TRANSFER_CUSTOMER"  | Translate : "Warm transfer customer"'
            (clicked)="transferWarm.emit()"
  >
    <fa-icon [icon]="faRightLeft"/>
  </p-button>


  <p-divider *ngIf="!ended"  layout="vertical"></p-divider>
  <p-button appDebounceClick id="end-button"
            *ngIf="!ended"
            severity="danger"
            styleClass="btn-bottom-control center-icon"
            [pTooltip]='"VEECHAT_ASYNC_TOOLTIP_END"  | Translate : "End"'
            (clicked)="end.emit()">
    <fa-icon [icon]="faXmark"/>
  </p-button>
</div>
