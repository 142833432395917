import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {EngagementStateType, EngagementAgent, Engagement} from '../../services/engagement';
import { CustomerInvite } from '../engagement-join-customers/CustomerInvite';
import {faDesktop, faWindowClose, faHeart, faChevronLeft, faHeartBroken} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-engagement-join',
  templateUrl: './engagement-join.component.html',
  styleUrls: ['./engagement-join.component.scss']
})
export class EngagementJoinComponent implements OnInit {

  @Input() engagement: Engagement;
  @Input() presentingAgent: string;
  @Input() primaryAgent: string;

  @Input() currentState: EngagementStateType;

  @Output() cancelJoinInvite = new EventEmitter<any>();
  @Output() invitationRequest = new EventEmitter<any>();
  @Output() customerInvitationRequest = new EventEmitter<CustomerInvite>();
  @Output() makePresenter = new EventEmitter<string>();
  @Output() kickAgent = new EventEmitter<string>();


  constructor() { }

  ngOnInit() {
  }

  public inviteRequest(event) {
    this.invitationRequest.emit(event);
  }

  public inviteCustomerRequest(event) {
    this.customerInvitationRequest.emit(event);
  }

  public cancelJoin() {
    this.cancelJoinInvite.emit();
  }

  changePresenter($event: string) {
    this.makePresenter.emit($event);
  }

  kick(event: string) {
    this.kickAgent.emit(event);
  }

  protected readonly faChevronLeft = faChevronLeft;
}
