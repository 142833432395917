import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CrmField } from '../../../services/crm-service/crm-field';
import { CheckListField } from '../../../classes/checklist/CheckListField';
import { CrmStructure } from '../../../services/crm-service/crm-category';
import { CrmService } from '../../../services/crm-service/crm.service';

@Component({
  selector: 'app-crm-field-externallink',
  templateUrl: './crm-field-externallink.component.html',
  styleUrls: ['./crm-field-externallink.component.scss']
})
export class CrmFieldExternallinkComponent implements OnInit {
  private _field: CrmField;

  @Input() set field(field: CrmField) {
    this._field = field;
  }

  get field(): CrmField {
    return this._field;
  }

  @Input() disableCrm: boolean;

  @Output() saveField: EventEmitter<CrmField> = new EventEmitter<CrmField>();

  @Input() crmData:  CrmStructure;

  constructor(private crmService: CrmService) { }

  ngOnInit() {
  }

  public onClick($event) {

      $event.preventDefault();

      let url: string = decodeURIComponent(this.field.url);
      const tags:RegExpMatchArray = url.match(/\[(.*?)\]/g);

      for (let i = 0; i < tags.length; i++) {
        url = this.replaceTag(url, tags[i]);
      }

      window.open(url, "external-link");
  }

  private replaceTag(out: string, tag: string) {
    let tagProperties = this.getTagProperties(tag);


    if (tagProperties) {
      let extractedData = this.crmService.extractCustomerData(this.crmData);
      let linkTagCrmData = this.crmService.getCrmFieldValue(extractedData, tagProperties.category, tagProperties.field);

      if (linkTagCrmData && linkTagCrmData != "") {
        out = out.replace(tag, linkTagCrmData);
      }
    }
    return out;
  }

  getTagProperties(tag: string) {
    tag = tag.substring(1, tag.length - 1);
    let tagSplit = tag.split(":");

    if (tagSplit.length === 0) {
      return null;
    }

    return {
      category: tagSplit[0], field: tagSplit[1]
    };
  }



}
