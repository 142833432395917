<div class="mr-4">
  <p-button id="close-button" (onClick)="close()" *ngIf="isReadOnly()" styleClass="p-button-text p-button-lg p-button-plain">
      <fa-icon [icon]="faTimes"></fa-icon>
  </p-button>

  <ul class="d-none d-xxl-block list-group-flush p-0 m-0 text-left" style="width:200px;">
    <li [ngClass]="{'d-none': !crmAvailable}" class="list-group-item">
      <p-button
        (onClick)="scrollToSection(contact)" styleClass="d-flex flex-row" [text]="true" severity="secondary">

        {{ 'CUSTOMERENGAGEMENTDETAILS_HEADER_CONTACT' | Translate : 'Contact' }}

        <div *ngIf="!isReadOnly() && isPrimaryAgent()" [ngSwitch]="getChecklistErrorType()"
             class="checklist-indicator ml-2">
          <fa-icon *ngSwitchCase="ChecklistFieldType.MANDATORY" [icon]="faTimesCircle" class="text-danger"></fa-icon>
          <fa-icon *ngSwitchCase="ChecklistFieldType.RECOMMENDED" [icon]="faCircleExclamation"
                   class="text-warning"></fa-icon>
          <fa-icon *ngSwitchDefault [icon]="faCircleCheck" class="text-success"></fa-icon>
        </div>

      </p-button>
    </li>
    <li [ngClass]="{'d-none': !crmAvailable}" class="list-group-item">
      <p-button
        (onClick)="scrollToSection(opportunity)" styleClass="d-flex flex-row" [text]="true" severity="secondary">
        {{ "CUSTOMERENGAGEMENTDETAILS_BUTTON_OPPORTUNITIES" | Translate : 'Opportunities' }}
      </p-button>
    </li>
    <li [ngClass]="{'d-none': isReadOnly() || !isPrimaryAgent()}" class="list-group-item">
      <p-button
        (onClick)="scrollToSection(tasks)" styleClass="d-flex flex-row" [text]="true" severity="secondary">
        {{ "CUSTOMERENGAGEMENTDETAILS_BUTTON_TASK" | Translate : 'Tasks' }}
      </p-button>
    </li>
    <li [ngClass]="{'d-none': isReadOnly() || currentState?.type !== EngagementState.Post}" class="list-group-item">
      <p-button
        (onClick)="scrollToSection(email)" styleClass="d-flex flex-row" [text]="true" severity="secondary">
        {{ "CUSTOMERENGAGEMENTDETAILS_BUTTON_EMAIL" | Translate : 'Emails' }}
      </p-button>
    </li>
    <li class="list-group-item">
      <p-button (onClick)="scrollToSection(sessioninfo)" styleClass="d-flex flex-row" [text]="true" severity="secondary">
        {{ "CUSTOMERENGAGEMENTDETAILS_BUTTON_SESSIONINFO" | Translate : 'Session Information' }}
      </p-button>
    </li>
    <li class="list-group-item">
      <p-button (onClick)="scrollToSection(browsinghistory)" styleClass="d-flex flex-row" [text]="true" severity="secondary">
        {{ "CUSTOMERENGAGEMENTDETAILS_BUTTON_BROWSEHISTORY" | Translate : 'Browsing History' }}
      </p-button>
    </li>
    <li class="list-group-item">
      <p-button (onClick)="scrollToSection(sessionhistory)" [text]="true" severity="secondary"
                styleClass="d-flex flex-row">
        {{ "CUSTOMERENGAGEMENTDETAILS_BUTTON_SESSIONHISTORY" | Translate : 'Session History' }}
      </p-button>
    </li>
  </ul>
</div>

<div class=" overflow-x-hidden overflow-y-auto flex-grow-1" [class.mr-3]="isReadOnly()">
  <div class="d-flex flex-column sections">
    <div
      [ngClass]="{'d-none': isReadOnly() || (!crmAvailable || !isPrimaryAgent || currentState?.type === EngagementState.Post)}"
      class="pb-5">
      <h3 class="primary-color-text" >{{ "CUSTOMERENGAGEMENTDETAILS_HEADER_CUSTOMER" | Translate : 'Customer' }}</h3>
      <app-engagement-customer-search [disabled]="(engagement?.backgrounded | async) || !engagement?.isPrimaryAgent()"
                                      (loadOldCustomer)="loadCustomer($event)"></app-engagement-customer-search>

    </div>
    <div #contact
         [ngClass]="{'d-none': !crmAvailable}"
         class="pb-5">
      <h3 class="primary-color-text">{{ "CUSTOMERENGAGEMENTDETAILS_HEADER_CONTACT" | Translate : 'Contact' }}</h3>
      <app-crm-data (saveData)="saveCrmData()"
                    [canEditCrm]="isPrimaryAgent()"
                    [checklistfields]="checklistfields"
                    [visitorCrmData]="visitorCrmData"
      ></app-crm-data>
    </div>
    <div #opportunity
         [ngClass]="{'d-none': !crmAvailable}"
         class="pb-5">
      <h3 class="w-75 primary-color-text">{{ "CUSTOMERENGAGEMENTDETAILS_BUTTON_OPPORTUNITIES" | Translate : 'Opportunities' }}</h3>
      <app-veestudio-engagement-opportunity (saveOpp)="saveOpportunity($event)"
                                            [emailState]="emailState"
                                            [isEditable]="!isReadOnly() && isPrimaryAgent()"
                                            [opportunities]="opportunities"
                                            [userGuid]="userGuid"
                                            [visitorCrmData]="visitorCrmData"
      ></app-veestudio-engagement-opportunity>
    </div>

    <div #tasks
         [ngClass]="{'d-none': isReadOnly() || !isPrimaryAgent()}"
         class="pb-5">
      <h3 class="pb-3 primary-color-text">{{ "CUSTOMERENGAGEMENTDETAILS_BUTTON_TASK" | Translate : 'Tasks' }}</h3>
      <app-checklist (focusField)="scrollToSection(contact)"
                     [checklistfields]="checklistfields">

      </app-checklist>

    </div>
    <div #email
         [ngClass]="{'d-none': isReadOnly() || currentState?.type !== EngagementState.Post}"
         class="pb-5">
      <h3 class="pb-3 primary-color-text">{{ "CUSTOMERENGAGEMENTDETAILS_BUTTON_EMAIL" | Translate : 'Emails' }}</h3>
      <app-email [engagement]="engagement"></app-email>
    </div>

    <div #sessioninfo class="pb-5">
      <h3 class="pb-3  primary-color-text">{{ "CUSTOMERENGAGEMENTDETAILS_BUTTON_SESSIONINFO" | Translate : 'Session Information' }}</h3>

      <app-visitor-details class="d-block overflow-auto" [ip]="visitorSessionDetails?.ip"
                           [location]="visitorDetails?.location"
                           [organisation]="visitorSessionDetails?.organisation"
                           [page]="currentUrl?.length > 0 ? currentUrl : visitorDetails?.page"
                           [prevOp]="visitorDetails?.prevOp" [referrer]="visitorSessionDetails?.referrer"
                           [section]="visitorDetails?.section"
                           [useragent]="visitorDetails?.useragent"></app-visitor-details>

    </div>
    <div #browsinghistory class="pb-5">
      <h3 class="pb-3 primary-color-text">{{ "CUSTOMERENGAGEMENTDETAILS_BUTTON_BROWSEHISTORY" | Translate : 'Browsing History' }}</h3>

      <app-browsing-history [visitorBrowsingHistory]="visitorBrowsingHistory">
      </app-browsing-history>

    </div>
    <div #sessionhistory class="pb-5 ">
      <h3 class="pb-3 primary-color-text">{{ "CUSTOMERENGAGEMENTDETAILS_BUTTON_SESSIONHISTORY" | Translate : 'Session History' }}</h3>
      <app-session-history [visitorSessionHistory]="visitorSessionHistory">
      </app-session-history>
    </div>
  </div>

</div>
