import { Injectable } from '@angular/core';
import { TranslationBackendService } from './translation.backend.service';
import { HubTextMessage, ChatMessageEvent } from '../engagementhub.service';
import { Agent } from '../../classes/agent';
import { Observable, of, merge } from 'rxjs';
import { Translation } from './translation';
import { Visitor } from '../visitor-service/visitor';
import { map, tap, filter, concatMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TranslationService {

    constructor(private translationBackendService: TranslationBackendService) { }

    public requestTranslation(message: string, from: string, to: string, token: string): Observable<Translation> {
        return this.translationBackendService.translate(message, from, to, token);
    }

    public translateLocal(event: ChatMessageEvent, agent: Agent, visitor:Visitor): Observable<HubTextMessage> {
        const source$ = of(event);
        const previousMessages$ = source$.pipe(filter(f => f.fromPreviousChat));
        const agentMessages$ = previousMessages$.pipe(filter(f => f.message.SenderIsAgent));
        const otherAgentMessages$ = agentMessages$.pipe(filter(f => !this.equalsLowerCase(f.message.SenderId, agent.username)));
        const nonTranslatableOtherAgentMessages$ = otherAgentMessages$.pipe(
            filter(f =>
                !f.message.MessageCulture || (f.message.MessageCulture && this.sameCulture(f.message.MessageCulture, agent.culture))
            ),
            map(evt => {
                let msg = Object.assign({}, evt.message);
                msg = this.removeDuplicates(msg);
                return msg;
            })
        );

        const translatableOtherAgentMessages$ = otherAgentMessages$.pipe(
            filter(f =>
                f.message.MessageCulture && !this.sameCulture(f.message.MessageCulture, agent.culture)
            ),
            concatMap(evt => this.requestTranslation(evt.message.Message, evt.message.MessageCulture, agent.culture, agent.authToken)),
            map(t => {
                 let msg = Object.assign({}, event.message);
                 msg.OriginalMessage = msg.Message;
                 msg.Message = t.translatedMessage;
                 msg = this.removeDuplicates(msg, !t.hasTranslation);
                 return msg;
            })
        );


        const myMessages$ = agentMessages$.pipe(
            filter(f => this.equalsLowerCase(f.message.SenderId, agent.username)),
            map(evt => this.formatAgentMessage(evt.message))
        );

        const customerMessages$ = previousMessages$.pipe(filter(f => f.message.SenderIsAgent === false));
        const translatableCustomerMessages$ = customerMessages$.pipe(
            filter(f =>
                f.message.MessageCulture && !this.sameCulture(f.message.MessageCulture, agent.culture)
                || !f.message.MessageCulture && !this.sameCulture(visitor.culture, agent.culture)
            ),
            concatMap(evt => {
                const from = evt.message.MessageCulture ? evt.message.MessageCulture : visitor.culture;
                return this.requestTranslation(evt.message.Message, from, agent.culture, agent.authToken);
            }),
            map(t => {
                 let msg = Object.assign({}, event.message);
                 if(t.hasTranslation){
                     // check if translation is the same was message.  This will happen because we do not know the customer message culture
                    msg.OriginalMessage = (msg.MessageCulture || this.equalsLowerCase(msg.Message, t.translatedMessage)) ? msg.OriginalMessage : msg.Message;
                    msg.Message = t.translatedMessage;
                 }

                 msg = this.removeDuplicates(msg, !t.hasTranslation);
                 return msg;
            }),
        );

        const nonTranslatableCustomerMessages$ = customerMessages$.pipe(
            filter(f =>
                f.message.MessageCulture &&  this.sameCulture(f.message.MessageCulture, agent.culture)
                || !f.message.MessageCulture && this.sameCulture(visitor.culture, agent.culture)
            ),
            map(evt => {
                let message = Object.assign({}, evt.message);
                message = this.removeDuplicates(message);
                return message;
            }),
        );

        const translations$ = merge(translatableCustomerMessages$, translatableOtherAgentMessages$);
        const nonTranslations$ = merge(nonTranslatableCustomerMessages$, nonTranslatableOtherAgentMessages$, myMessages$);

        return merge(nonTranslations$, translations$);


    }


    public translateIncoming(event: ChatMessageEvent, agent: Agent, visitor: Visitor): Observable<HubTextMessage> {
        const source$ = of(event);

        const activeMessages$ = source$.pipe(filter(f => f.fromPreviousChat === false));
        const agentMessages$ = activeMessages$.pipe(filter(f => f.message.SenderIsAgent));
        const myMessages$ = agentMessages$.pipe(
            filter(f => this.equalsLowerCase(f.message.SenderId, agent.username)),
            map(evt => this.formatAgentMessage(evt.message))
        );

        const otherAgentMessages$ = agentMessages$.pipe(filter(f => !this.equalsLowerCase(f.message.SenderId, agent.username)));
        const translatableOtherAgentMessages$ = otherAgentMessages$.pipe(
            filter(f =>
                f.message.MessageCulture && !this.sameCulture(f.message.MessageCulture, agent.culture)
            ),
            concatMap(evt => this.requestTranslation(evt.message.Message, evt.message.MessageCulture, agent.culture, agent.authToken)),
            map(t => {
                 let msg = Object.assign({}, event.message);
                 msg.OriginalMessage = msg.Message;
                 msg.Message = t.translatedMessage;

                 msg = this.removeDuplicates(msg, !t.hasTranslation);

                 return msg;
            })
        );

        const nonTranslatableOtherAgentMessages$ = otherAgentMessages$.pipe(
            filter(f =>
                !f.message.MessageCulture || (f.message.MessageCulture && this.sameCulture(f.message.MessageCulture, agent.culture))
            ),
            map(evt => evt.message)
        );

        const customerMessages$ = activeMessages$.pipe(filter(f => !f.message.SenderIsAgent));
        const translatableCustomerMessages$ = customerMessages$.pipe(
            filter(f =>
                f.message.MessageCulture && !this.sameCulture(f.message.MessageCulture, agent.culture)
                || !f.message.MessageCulture && !this.sameCulture(visitor.culture, agent.culture)
            ),
            concatMap(evt => {
                const from = evt.message.MessageCulture ? evt.message.MessageCulture : visitor.culture;
                return this.requestTranslation(evt.message.Message, from, agent.culture, agent.authToken);
            }),
            map(t => {
                 let msg = Object.assign({}, event.message);

                 if(t.hasTranslation){
                    msg.OriginalMessage = msg.Message;
                    msg.Message = t.translatedMessage;
                    msg.MessageCulture = agent.culture;
                 }else{
                     msg.MessageCulture = visitor.culture;
                 }

                 msg = this.removeDuplicates(msg, !t.hasTranslation);

                 return msg;
            })
        );

        const nonTranslatableCustomerMessages$ = customerMessages$.pipe(
            filter(f =>
                f.message.MessageCulture && this.sameCulture(f.message.MessageCulture, agent.culture)
                || !f.message.MessageCulture && this.sameCulture(visitor.culture, agent.culture)
            ),
            map(evt => {
                let msg = Object.assign({}, evt.message);
                msg.MessageCulture = visitor.culture;
                return msg;
            })
        );

        const translations$ = merge(translatableCustomerMessages$, translatableOtherAgentMessages$);
        const nonTranslations$ = merge(nonTranslatableCustomerMessages$, nonTranslatableOtherAgentMessages$, myMessages$);

        return merge(nonTranslations$, translations$);
    }



    public translate(event: ChatMessageEvent, agent: Agent, visitor: Visitor): Observable<HubTextMessage> {
        return merge(this.translateLocal(event, agent, visitor), this.translateIncoming(event, agent, visitor));
    }

    private formatAgentMessage(message: HubTextMessage): HubTextMessage {
        if (message.OriginalMessage && message.OriginalMessage.length > 0) {
            if (this.equalsLowerCase(message.Message, message.OriginalMessage)) {
                message.OriginalMessage = '';
            } else {
                [message.Message, message.OriginalMessage] = [message.OriginalMessage, message.Message];
            }
        }

        return message;
    }


    private removeDuplicates(msg: HubTextMessage, failedTranslation:boolean = false):HubTextMessage{
        if(this.equalsLowerCase(msg.Message, msg.OriginalMessage)){
            msg.OriginalMessage = failedTranslation ? "N/A" : "";
        }
        return msg;
    }

    //TODO: Move to utils
    private equalsLowerCase(str1: string, str2: string): boolean {
        return str1.toLowerCase() === str2.toLowerCase();
    }

    // This wont work for chinese/serbian/latin but it will do for now
    private sameCulture(culture1: string, culture2: string): boolean{
        const c1 = culture1.split('-')[0];
        const c2 = culture2.split('-')[0];
        return this.equalsLowerCase(c1,c2);
    }

}
