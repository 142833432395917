import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OutboundMessage} from '../../../components/engagement-text-chat/engagement-text-chat.component';
import {VisitorService} from '../../../services/visitor-service/visitor.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {CrmEmailStateType} from '../../../services/crm-service/crm-email-state';
import {CrmEmail} from '../../../utils/crm-email';
import {map} from 'rxjs/operators';
import {CrmService} from '../../../services/crm-service/crm.service';
import {AsyncConversation, AsyncConversationState} from '../../../services/async-conversation';
import {FileTransferService} from '../../../services/file-transfer-service/file-transfer.service';
import {IFileUpload} from '../../../services/file-transfer-service/file-upload-interface';
import {LoggingService} from '../../../services/logging.service';
import {AlertService, AlertType} from '../../../services/alert-service/alert.service';
import {TranslatePipe} from '../../../filters/Translate.pipe';
import {EndChatModalComponent, EndType} from '../../../components/end-chat-modal/end-chat-modal.component';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {BlockCustomerModalComponent} from '../../../components/block-user-modal/block-user-modal.component';
import {Features, FeatureService} from '../../../services/feature-service/feature.service';
import {SettingsService} from '../../../services/settings-service/settings.service';
import {Channels} from '../../../services/visitor-service/Channel';
import {TransferRequest} from '../../../components/conversation-transfer/conversation-transfer.component';
import {EngagementAgent} from '../../../services/engagement';
import {InviteRequest} from '../../../components/engagement-transfer-select/engagement-transfer-select.component';
import {PostEngagementStatus} from '../../../classes/post-engagement.status';
import {ModalService} from '../../../services/modal.service';
import {Section} from "../../../components/visitor-information/visitor-information.component";

@Component({
  selector: 'app-veechat-conversation',
  templateUrl: './veechat-conversation.component.html',
  styleUrls: ['./veechat-conversation.component.scss']
})
export class VeechatConversationComponent implements OnInit {
  private static readonly DEFAULT_REENGAGE_MESSAGE = 'Can not respond to customers after 24 hours from last customer message';

  protected readonly AsyncConversationState = AsyncConversationState;

  private settingsSub: Subscription;

  private _conversationId: string;

  public roomAgents: ReadonlyMap<string, EngagementAgent> = new Map([]);

  private reassigned: Subscription | undefined;

  @Input() set conversationId(conversationId: string) {
    if (!this._conversationId || this._conversationId !== conversationId) {
      this._conversationId = conversationId;

      if (conversationId) {
        this.conversation = this.visitorService.conversations.value.find(c => c.id.Id == conversationId);
        this.emailState = this.conversation.visitor.crmData.pipe(map(crm => CrmEmail.isValidEmail(crm)));
      }

      this.reassigned?.unsubscribe();
      this.reassigned = this.conversation?.reassigned?.subscribe(() => this.ended.emit());
    }
  }

  get conversationId(): string {
    return this._conversationId;
  }

  @Output() ended: EventEmitter<void> = new EventEmitter<void>();

  public conversation: AsyncConversation;

  public currentSection: Section = Section.Contact;
  public emailState: Observable<CrmEmailStateType> = new BehaviorSubject<CrmEmailStateType>(CrmEmail.EMAIL_STATE_INCORRECT);

  private modalRef?: DynamicDialogRef;

  public canBlock: boolean = false;

  private canReengageWithWhatsApp: boolean = false;
  private whatsAppReengageMessageText = VeechatConversationComponent.DEFAULT_REENGAGE_MESSAGE;
  private whatsAppReengageTemplate: string;

  private messengerUnavailableMessage: string;
  private smsUnavailableMessage: string;

  public get canReengage(): boolean {
    if (!this.conversation) {
      return false;
    }

    if (this.conversation.channel === Channels.Sms) {
        return false;
      } else {
        return this.canReengageWithWhatsApp;
      }
  }

  public get reengageMessage(): string {
    if (!this.conversation) {
      return "";
    }

    if (this.conversation.channel === Channels.Sms) {
      return this.smsUnavailableMessage;
    } else if (this.conversation.channel === Channels.Messenger) {
      return this.messengerUnavailableMessage;
    } else {
      return this.whatsAppReengageMessageText;
    }
  }

  constructor(
    private readonly visitorService: VisitorService,
    private readonly crmService: CrmService,
    private readonly fileTransfer: FileTransferService,
    private readonly logging: LoggingService,
    private readonly alertService: AlertService,
    private readonly translate: TranslatePipe,
    private readonly modalService: ModalService,
    private readonly featureService: FeatureService,
    private readonly settingsService: SettingsService,
  ) {
  }

  ngOnInit() {
    this.canBlock = true;

    this.settingsSub = this.settingsService.resources.subscribe(_ => {
      const message: string = this.settingsService.getResource('WHATSAPP_REENGAGE_MESSAGE');
      this.canReengageWithWhatsApp = message && message.length > 0;

      this.whatsAppReengageMessageText = this.canReengage ? message : VeechatConversationComponent.DEFAULT_REENGAGE_MESSAGE;

      // todo: 2024-02-29 R.Scott Everyone needs to move to the templated message, the non-template system will stop working end of Q2, 2024
      const template: string = this.settingsService.getResource('WHATSAPP_REENGAGE_TEMPLATE');
      if (template && template.length > 0) {
        this.whatsAppReengageTemplate = template;
      } else {
        // Using legacy message.
        this.whatsAppReengageTemplate = undefined;
      }

      this.smsUnavailableMessage = this.settingsService.getResourceOrDefault('SMS_UNAVAILABLE_MESSAGE', 'SMS unavailable');

      this.messengerUnavailableMessage = this.settingsService.getResourceOrDefault('MESSENGER_UNAVAILABLE_MESSAGE', 'Messenger unavailable');
    });
  }

  ngOnDestroy() {
    this.settingsSub.unsubscribe();
    this.reassigned?.unsubscribe();
  }

  sendMessage($event: OutboundMessage) {
    if (!this.conversation.canReply
        && this.canReengage
        && this.conversation.channel === Channels.WhatsApp
        && this.whatsAppReengageTemplate
    ) {
      this.conversation.sendTemplateMessage(this.whatsAppReengageTemplate).subscribe();
    } else {
      this.conversation.sendMessage($event.plainText).subscribe();
    }
  }

  endAsyncChat() {
    this.showEndChatModal();
  }

  doNothing($event: any) {
    console.log("This does nothing");
  }

  onSaveCrmData() {
    const visitor = this.conversation.visitor;
    const newData = visitor.visitorCRMData;
    this.crmService.setCrmData(visitor.visitorCRMData, visitor.userGuid).subscribe(res => {
      if (res) {
        visitor.setCrmData(newData, this.crmService);
      }
    });
  }

  onPostEngagementSubmit(postStatus: PostEngagementStatus) {
    this.conversation.qualify(postStatus.status, postStatus.substatus, postStatus.notes)
      .subscribe((success) => success && this.ended.emit());
  }

  uploadFiles(files: FileList) {
    for (let i = 0; i < files.length; ++i) {
      const file = files.item(i);
      this.fileTransfer.createFileTransferUsingToken(file, this.conversation.id.Id)
        .then(upload => {
          if (upload) {
            this.conversation.addFileTransfer(upload)
              .then(() => this.logging.debug("File uploaded"))
              .catch(() => this.alertService.addAlert(
                this.translate.transform("VEECHAT_CONV_FILE_UPLOAD_ERROR", "Error uploading file."), AlertType.Danger
              ))
            this.fileTransfer.start(upload);
          } else {
            this.logging.error("Falsy FileUpload returned from fileTransferService, this should never happen!");
          }
        });
    }
  }

  cancelFileUpload(fileUpload: IFileUpload) {
    this.fileTransfer.cancel(fileUpload);
  }

  private showEndChatModal() {
    const conversation = this.conversation;

    if (this.modalRef) {
      throw new Error(`Attempted to end Async ${conversation.id} when already ending another conversation.`);
    }

    const data = {
      agents: [],
      endChatType: EndType.END_CHAT,
      onEndChat: () => {
        this.removeModals();
        conversation.end(this.shouldSendSurvey()).subscribe();
      },
      cancelEndChat: () => {
        this.removeModals();
      }
    };

    this.modalRef = this.modalService.openModal(EndChatModalComponent, {
      data,
      closeOnEscape: false,
      showHeader: true,
      closable: false,
      header: this.translate.transform('ENDCHATMODAL_HEADER_ENDCONVERSATION', 'Are you sure you want to end this conversation?'),
      contentStyle: {width: '500px'}
    });
  }

  private removeModals() {
    this.modalService.closeModal(this.modalRef);
    this.modalRef = null;
  }

  public showBlockCustomerModal() {
    const conversation = this.conversation;

    const data = {
      onBlockCustomer: (reason: string) => {
        this.removeModals();
        this.blockCustomer(conversation, reason);
      },
      cancelBlockCustomer: () => {
        this.removeModals();
      }
    };

    this.modalRef = this.modalService.openModal(BlockCustomerModalComponent, {
      data,
      closeOnEscape: false,
      showHeader: true,
      closable: false,
      header: this.translate.transform('BLOCKCUSTOMERMODAL_HEADER_BLOCKUSER', 'Are you sure you want to block customer?'),
      contentStyle: {width: '500px'}
    });
  }

  private blockCustomer(conversation: AsyncConversation, reason: string) {
    this.crmService
      .blockCustomer(
        conversation.visitor.userGuid,
        "0.0.0.0",
        reason.length > 0 ? reason : "Default"
      )
      .subscribe((data) => {
        if (data) {
          conversation.setBlocked();

          if (conversation.state !== AsyncConversationState.POST) {
            conversation.end(false).subscribe();
          }
        } else {
          this.alertService.addAlert(
            this.translate.transform(
              'BLOCKCUSTOMER_ALERT_ERROR',
              'Error when calling the user blocking method.'
            ),
            AlertType.Danger
          );
        }
      });
  }

  private shouldSendSurvey(): boolean {
    let prefix: string;
    switch (this.conversation.channel) {
      default:
      case Channels.WhatsApp:
        prefix = "WHATSAPP";
        break;
      case Channels.Sms:
        prefix = "SMS";
        break;
      case Channels.Messenger:
        prefix = "MESSENGER";
        break;
    }

    const resource = `${prefix}_SURVEY_SHOW`;
    return this.conversation.canReply && this.settingsService.getResourceSettingEnabledOrDefault(resource, false);
  }

  sendColdTransfer($event: TransferRequest) {
    const conversationId = this.conversationId;
    this.conversation.coldTransferAsyncChat($event.section, $event.message)
      .subscribe((success) => {
        if (success) {
          this.logging.info(`Cold transfer for ${conversationId} to ${$event.section} succeeded.`);
          if (this.conversationId == conversationId) {
            this.ended.emit();
          }
        } else {
          this.logging.error(`Cold transfer for ${conversationId} to ${$event.section} failed.`);
          this.alertService.addResourceAlert("CONVERSATION_COLD_TRANSFER_FAILED", "Failed to transfer conversation.");
        }
      });
    this.cancelTransfer();
  }

  cancelTransfer() {
    this.conversation?.cancelTransfer();
  }

  startColdTransfer() {
    this.conversation?.startColdTransfer();
  }

  startWarmTransfer() {
    this.conversation?.startWarmTransfer();
  }

  sendWarmTransfer($event: InviteRequest) {
    const conversationId = this.conversationId;
    this.conversation?.sendWarmTransfer($event)
      .then(() => {
        this.logging.debug("Warm transfer succeeded");
      })
      .catch((err) => {
        this.alertService.addResourceAlert("CONVERSATION_WARM_TRANSFER_FAILED", "Failed to transfer conversation.");
        this.logging.error(`Failed to send invite request to ${$event.username} for conversation ${conversationId}`, err);
      })
  }

  savePost($event: PostEngagementStatus) {
    this.visitorService.savePostStatus(this.conversation.id.Id, this.conversation.userGuid, this.conversation.id.Id,  $event);
  }
}
