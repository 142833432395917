<div class="d-flex veechat-conversation h-100 w-100">
  <div class="d-flex flex-row flex-grow-1 engagementMainContainer">
    <app-visitor-information
      class="w-100 h-100 mr-2 main-layout"
      [emailState]="emailState | async"
      [(currentSection)]="currentSection"
      [engagement]="conversation"
      [currentUrl]=""
      [currentState]="conversation.isEnded ? 2 : 0"
      [visitorCrmData]="(conversation?.visitor?.crmData | async)"
      [visitorDetails]="conversation.visitorSiteDetail"
      [visitorSessionDetails]="null"
      [checklistfields]="[]"
      [visitorSessionHistory]="(conversation?.visitor?.sessionHistory | async)"
      [visitorBrowsingHistory]="[]"
      [userGuid]="conversation?.userGuid"
      (saveOpp)="doNothing($event)"
      (updatedData)="onSaveCrmData()"
      (loadOldCustomer)="doNothing($event)">
    </app-visitor-information>

    <ng-container [ngSwitch]="conversation?.state">
      <app-engagement-chat-tabset
        class="sidebar-panel"
        *ngSwitchCase="AsyncConversationState.ENGAGED"
        [engagement]="conversation"
        [messages]="conversation?.Messages"
        [agentText]="conversation.canReply ? conversation.agentText : reengageMessage"
        [isClosed]="false"
        [textEntryDisabled]="!conversation.canReply"
        [sendButtonDisabled]="!conversation.canReply && !canReengage"
        (sendMessage)="sendMessage($event)"
        (uploadFiles)="uploadFiles($event)"
        (cancelFilesTransfer)="cancelFileUpload($event)"
        [files]="conversation.uploadingFiles"
        (agentTextChange)="conversation.setAgentText($event)"
        [maxMessageLength]="999"
        (checkListName)="doNothing($event)"
        (savePost)="savePost($event)">
      </app-engagement-chat-tabset>
      <app-conversation-transfer
        class="sidebar-panel  "
        *ngSwitchCase="AsyncConversationState.COLD_TRANSFER"
        (transferRequest)="sendColdTransfer($event)"
        (transferClose)="cancelTransfer()">
      </app-conversation-transfer>

      <app-engagement-transfer
        class="sidebar-panel  "
        *ngSwitchCase="AsyncConversationState.WARM_TRANSFER"
        [transfer]="true"
        [crossSessionTransfer]="false"
        [currentState]="conversation.transferState"
        [roomAgents]="roomAgents"
        [roomId]="conversationId"
        [textMessages]="conversation.transferChatMessages"
        (cancelInvite)="cancelTransfer()"
        (inviteRequest)="sendWarmTransfer($event)">
      </app-engagement-transfer>

      <app-engagement-post
        *ngSwitchCase="AsyncConversationState.POST"
        class="h-100 sidebar-panel  "
        [engagement]="conversation"
        (submitPost)="onPostEngagementSubmit($event)"
        (checkListName)="doNothing($event)">
      </app-engagement-post>
    </ng-container>

    <app-veechat-conversation-controls
      [isBlocked]="conversation.blocked || !canBlock"
      (blockCustomer)="showBlockCustomerModal()"
      [ended]="conversation.isEnded"
      (transferCold)="startColdTransfer()"
      (transferWarm)="startWarmTransfer()"
      (end)="endAsyncChat()">
    </app-veechat-conversation-controls>
  </div>
</div>
