import {Component, Input} from '@angular/core';
import {faExclamationCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import {CheckListField, ChecklistFieldType} from '../../../classes/checklist/CheckListField';
import {CrmField} from '../../../services/crm-service/crm-field';

@Component({
  selector: 'app-crm-field-error',
  templateUrl: './crm-field-error.component.html',
  styleUrls: ['./crm-field-error.component.scss']
})
export class CrmFieldErrorComponent {
  @Input() crmCheckField: CheckListField = new CheckListField();
  @Input() field: CrmField;
  @Input() canEditCrm: boolean;

  protected readonly ChecklistFieldType = ChecklistFieldType;

  faTimesCircle = faTimesCircle;
  faExclamationCircle = faExclamationCircle;

  public isCheckListError() {
    return this.canEditCrm && this.crmCheckField && !this.crmCheckField.IsRulePassed;
  }
}
