
import { Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { VisitorService } from '../../services/visitor-service/visitor.service';
import { Subscription, BehaviorSubject, Observable, of, combineLatest } from 'rxjs';
import { Visitor } from '../../services/visitor-service/visitor';
import { AlertService, AlertType } from '../../services/alert-service/alert.service';
import { LoadingService } from '../../services/loading.service';
import { Router, ActivatedRoute } from '@angular/router';
import { OnlineState } from '../../enums/online-state.enum';
import { OnlineService } from '../../services/online.service';
import { InvitationInitialState, InvitationInModalComponent } from '../invitation-modal/invitation-in-modal.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslatePipe } from '../../filters/Translate.pipe';
import {InvitationRequest, JoinRequest, TransferRequest} from '../../classes/transfer/invitationRequest';
import { NotificationService } from '../../services/notification.service';
import { filter, map, first } from 'rxjs/operators';
import { VisitorSiteDetails } from '../../classes/visitor/VisitorSiteDetails';
import { VisitorSessionDetails } from '../../classes/visitor/VisitorSessionDetails';
import { EngagementEvent } from '../../services/crm-service/engagement-event';
import { BrowsingHistory } from '../../services/crm-service/browsing-history';
import { VisitorDetails } from '../../classes/visitor-details';
import { RingerService } from '../../services/ringer-service/ringer.service';
import {CrmData, CrmStructure} from '../../services/crm-service/crm-category';
import { VisitorUtils } from '../../utils/visitor-utils';
import {HubVisitor} from '../../services/visitor-service/HubVisitor';
import {StatusFlag} from '../../enums/status-flag.enum';
import {ModalService} from '../../services/modal.service';



@Component({
  selector: 'app-call-list',
  templateUrl: './call-list.component.html',
  styleUrls: ['./call-list.component.scss'],
  providers: [TranslatePipe]
})
export class CallListComponent implements OnInit, OnDestroy {

  @Output() endBreak = new EventEmitter();

  public OnlineState = OnlineState;

  public currentState$: Observable<OnlineState>;

  private modalRef: DynamicDialogRef;
  private subscriptions: Subscription[] = [];

  public visitors$: Observable<Visitor[]> = of([]);
  public transfers$: Observable<InvitationRequest[]>;
  public invites$: Observable<InvitationRequest[]>;

  public isScheduledAppointmentsAvailable: boolean;
  public selectedSessionGuid: string = null;

  public visitorDetails: VisitorSiteDetails;
  public visitorSessionDetails$: Observable<VisitorSessionDetails>;
  public visitorSessionHistory$: Observable<EngagementEvent[]>;
  public visitorBrowsingHistory$: Observable<BrowsingHistory[]>;
  public visitorCRMData$: Observable<CrmStructure>;

  public selectedUserGuid: string = null;

  private availableState: OnlineState = OnlineState.Available;
  private path: string = "/veestudio";
  public noInternet: BehaviorSubject<boolean>;



  constructor(
    private alertService: AlertService,
    private loadingService: LoadingService,
    private visitorService: VisitorService,
    private modalService: ModalService,
    private router: Router,
    private onlineService: OnlineService,
    private notificationService: NotificationService,
    private ringerService: RingerService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslatePipe
  ) {
  }

  ngOnInit() {

    this.path = this.router.url;

    this.activatedRoute.data
    .pipe(first())
    .subscribe(v => {
      this.availableState = v.availableState;
      this.init();
    });

    this.noInternet = this.visitorService.noInternet;
  }

  private init() {
    this.currentState$ = this.onlineService.currentState.pipe(map(([s, _]) => s));
    this.onlineService.setCurrentState(this.availableState);

    this.visitors$ = this.visitorService.visitors.pipe(map(v => v.sort(VisitorUtils.longestWaitTimeSort)));
    this.transfers$ = this.visitorService.transfers;
    this.invites$ = this.visitorService.invites;

    const selectedRecordExists$ = combineLatest(this.visitors$, this.transfers$, this.invites$)
    .pipe(
      filter(_ => this.selectedSessionGuid != null),
      map(([v, t, i]) => {
        const hasVisitors = v.find(q => q.sessionGuid === this.selectedSessionGuid) != null;
        const hasTransfers = t.find(q => q.visitor.sessionGuid === this.selectedSessionGuid) != null;
        const hasInvites = i.find(q => q.visitor.sessionGuid === this.selectedSessionGuid) != null;
        const selectedRecordExists = hasVisitors || hasTransfers || hasInvites;
        return selectedRecordExists;
      })
    );

    this.subscriptions.push(selectedRecordExists$.pipe(filter(exists => !exists)).subscribe(_ => this.closeRecord()));

    let notifiedAboutIncomingCall = false;
    this.subscriptions.push(
      this.visitorService.visitors.subscribe(visitors => {
        if (visitors.filter(v => !v.isInactive).length > 0 && this.onlineService.currentState.value[0] === this.availableState) {
          this.ringerService.play();
          if (!notifiedAboutIncomingCall) {
            const title = this.translate.transform("CALLLIST_TITLE_REQUESTFORASSISTANCE", 'Request for Assistance');
            const options = { body: 'New Chat', icon: '../../assets/images/veestudio-icon.png', tag: 'visitorCall', silent: true };
            this.notificationService.create(title, options);
            notifiedAboutIncomingCall = true;
          }
        } else {
          notifiedAboutIncomingCall = false;
        }
      })
    );

    let notifiedABoutIncomingTransfer = false;
    this.subscriptions.push(
      this.visitorService.transfers.subscribe(transfers => {
        if (transfers.length > 0 && this.onlineService.currentState.value[0] === this.availableState) {
          this.ringerService.play();
          if (!notifiedABoutIncomingTransfer) {
            const title = 'Transfer';
            const options = { body: 'New Chat', icon: '../../assets/images/veestudio-icon.png', tag: 'visitorCall', silent: true };
            this.notificationService.create(title, options);
            notifiedABoutIncomingTransfer = true;
          }
        } else {
          notifiedABoutIncomingTransfer = false;
        }
      })
    );

    this.subscriptions.push(
      this.visitorService.invites.subscribe(invites => {
        if (invites.length > 0 && this.onlineService.currentState.value[0] === this.availableState) {
          this.ringerService.play();
        }
      })
    );

    // If we are displaying the modal for a transfer/join then we
    // need to close it if the transfer is removed by the other agent
    const hideModal = (newRequests: InvitationRequest[]) => {
      if (this.modalRef) {
        const req = this.modalService.dialogComponentRefMap.get(this.modalRef).instance.config.data.invitationRequest;
        const reqRoomId = req.roomId;
        if (!newRequests.some(r => r.roomId !== reqRoomId)) {
          this.deselectInvitationModal();
        }
      }
    };

    this.subscriptions.push(this.transfers$.subscribe(hideModal));
    this.subscriptions.push(this.invites$.subscribe(hideModal));
  }



  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];

    this.ringerService.dispose();
  }

  public showTransferAccept(transferRequest: InvitationRequest) {
    const data: InvitationInitialState = {
      acceptCallback: () => this.acceptTransferringIn(transferRequest),
      cancelCallback: () => this.deselectInvitationModal(),
      sendMessage: (message: string) => {
        this.visitorService.sendTransferChatMessage(transferRequest.roomId, transferRequest.sourceAgentUsername, message).subscribe();
      },
      rejectCallback: (rejectReason) => this.rejectTransfer(transferRequest, rejectReason),
      invitationRequest: transferRequest,
      textResources: {
        transferRequestTitle: this.translate.transform("CALLLIST_TITLE_TRANSFERREQUEST", 'Transfer Request'),
        from: this.translate.transform("CALLLIST_LABEL_FROM", 'From:'),
        reason: this.translate.transform("CALLLIST_LABEL_REASON", 'Reason:'),
        transcriptTitle: this.translate.transform("CALLLIST_LABEL_CHATTRANSCRIPT", 'Chat Transcript'),
        rejectTitle: this.translate.transform("CALLLIST_LABEL_REJECTTRANSFER", 'Reject this Transfer'),
        rejectionReasonTitle: this.translate.transform("CALLLIST_LABEL_REJECTIONREASON", 'Rejection reason'),
      },
    };

    this.modalRef = this.modalService.openModal(InvitationInModalComponent, {
      data,
      closeOnEscape: false,
      showHeader: true,
      header: this.translate.transform("CALLLIST_TITLE_TRANSFERREQUEST", 'Transfer Request'),
      contentStyle: { width: '500px'},
      closable: false
    });
  }

  public showJoinAccept(invite: InvitationRequest) {
    const data: InvitationInitialState = {
      acceptCallback: () => this.acceptJoin(invite),
      cancelCallback: () => this.deselectInvitationModal(),
      sendMessage: (message: string) => {
        this.visitorService.sendTransferChatMessage(invite.roomId, invite.sourceAgentUsername, message).subscribe();
      },
      rejectCallback: (rejectReason) => this.rejectJoin(invite, rejectReason),
      invitationRequest: invite,
      textResources: {
        transferRequestTitle: this.translate.transform("CALLLIST_LABEL_JOINREQUEST", 'Join Request'),
        from: this.translate.transform("CALLLIST_LABEL_FROM", 'From:'),
        reason: this.translate.transform("CALLLIST_LABEL_REASON", 'Reason:'),
        transcriptTitle: this.translate.transform("CALLLIST_LABEL_CHATTRANSCRIPT", 'Chat Transcript'),
        rejectTitle: this.translate.transform("CALLLIST_LABEL_REJECTINVITE", 'Reject this Invite'),
        rejectionReasonTitle: this.translate.transform("CALLLIST_LABEL_REJECTIONREASON", 'Rejection reason'),
      },
    };

    this.modalRef = this.modalService.openModal(InvitationInModalComponent, {
      data,
      closeOnEscape: false,
      showHeader: true,
      header: this.translate.transform("CALLLIST_LABEL_JOINREQUEST", 'Join Request'),
      contentStyle: {width: '500px'},
      closable: false
    });
  }

  private closeModal() {
    if (this.modalRef) {
      this.modalService.closeModal(this.modalRef);
      this.modalRef = null;
    }
  }

  public acceptJoin(invite: InvitationRequest): void {
    this.closeModal();
    this.loadingService.isLoading.next(true);
    this.visitorService.acceptJoin(invite).subscribe(
      response => {
        if (response.success) {
          this.visitorService.rejectAllTransfers('Engaged');
          this.visitorService.rejectAllJoins('Engaged');
          this.ringerService.stop();
          this.router.navigateByUrl(`/${this.path}/engagement/${invite.roomId}`);    // need to get the engagement id back
        } else {

          this.alertService.addAlert(this.translate.transform("CALLLIST_ALERT_ERROR_JOIN", "Error accepting invitation"), AlertType.Danger);        }
      },
      error => {
        this.alertService.addAlert(this.translate.transform("CALLLIST_ALERT_TIMEOUT", `TIMEOUT`), AlertType.Danger);
        this.loadingService.isLoading.next(false);
      },
      () => {
        this.loadingService.isLoading.next(false);
      }
    );
  }

  public rejectJoin(invite: InvitationRequest, reason: string): void {
    this.closeModal();
    this.loadingService.isLoading.next(true);

    this.visitorService.rejectInvitation(invite.roomId, invite.sourceAgentUsername, reason).subscribe(
      response => {
        if (response.success) {
          this.ringerService.stop();
          this.loadingService.isLoading.next(false);
        } else {
          this.alertService.addAlert(this.translate.transform("CALLLIST_ALERT_ERROR_REJECT_INVITATION", `Error rejecting invitation`), AlertType.Danger);
        }
      },
      error => {
        this.alertService.addAlert(this.translate.transform("CALLLIST_ALERT_TIMEOUT", `TIMEOUT`), AlertType.Danger);
        this.loadingService.isLoading.next(false);
      },
      () => {
        this.loadingService.isLoading.next(false);
      }
    );
  }

  public accept(visitor: Visitor) {
    if (this.modalRef) {
      this.closeModal();
      return;
    }

    this.loadingService.isLoading.next(true);

    // If we are rejoin then call joinCall to accept the engagement
    // otherwise call the normal acceptEngagement method
    const accept = visitor.isEngaged ? this.visitorService.joinCall : this.visitorService.acceptEngagement;
    accept.apply(this.visitorService, [visitor]).subscribe(
      response => {
        if (response.success) {
          this.ringerService.stop();
          this.router.navigateByUrl(`/${this.path}/engagement/${response.message.engagementId}`);    // need to get the engagement id back
        } else {
          this.alertService.addAlert(this.translate.transform("CALLLIST_ALERT_ERROR_PREFIX", "Error accepting engagement request: ") + response.message, AlertType.Danger);
        }
      },
      error => {
        this.alertService.addAlert(this.translate.transform("CALLLIST_ALERT_TIMEOUT", `TIMEOUT`), AlertType.Danger);
        this.loadingService.isLoading.next(false);
      },
      () => {
        this.loadingService.isLoading.next(false);
      }
    );

  }

  public acceptTransferringIn(transferRequest: InvitationRequest): void {
    this.closeModal();
    this.loadingService.isLoading.next(true);

    this.visitorService.acceptTransferringIn(transferRequest).subscribe(
      response => {
        if (response.success) {
          this.visitorService.rejectAllTransfers('Engaged');
          this.visitorService.rejectAllJoins('Engaged');
          this.ringerService.stop();
          this.router.navigateByUrl(`/${this.path}/engagement/${transferRequest.roomId}`);    // need to get the engagement id back
        } else {
          this.alertService.addAlert(this.translate.transform("CALLLIST_ALERT_ERROR_ACCEPT_TRANSFER", `Error accepting transfer`), AlertType.Danger);
        }
      },
      error => {
        this.alertService.addAlert(this.translate.transform("CALLLIST_ALERT_TIMEOUT", `TIMEOUT`), AlertType.Danger);
        this.loadingService.isLoading.next(false);
      },
      () => {
        this.loadingService.isLoading.next(false);
      }
    );
  }

  public deselectInvitationModal() {
    this.closeModal();
    this.ringerService.stop();
  }

  public rejectTransfer(transferRequest: InvitationRequest, rejectionText: string) {
    this.closeModal();
    this.loadingService.isLoading.next(true);

    this.visitorService.rejectTransferringIn(transferRequest.roomId, transferRequest.sourceAgentUsername, rejectionText).subscribe(
      response => {
        if (response.success) {
          this.loadingService.isLoading.next(false);
          this.ringerService.stop();
        } else {
          this.alertService.addAlert(this.translate.transform("CALLLIST_ALERT_ERROR_REJECT_TRANSFER", `Error rejecting transfer`), AlertType.Danger);
        }
      },
      error => {
        this.alertService.addAlert(this.translate.transform("CALLLIST_ALERT_TIMEOUT", `TIMEOUT`), AlertType.Danger);
        this.loadingService.isLoading.next(false);
      },
      () => {
        this.loadingService.isLoading.next(false);
      }
    );
  }

  onEndBreak() {
    this.onlineService.setCurrentState(this.availableState);
  }

  selectVisitor(visitor: Visitor) {

    this.selectedSessionGuid = visitor.sessionGuid;
    this.selectedUserGuid = visitor.userGuid;

    this.visitorDetails = this.createDetails(visitor);
    this.visitorCRMData$ = visitor.crmData;
    this.visitorBrowsingHistory$ = visitor.browsingHistory.asObservable();
    this.visitorSessionHistory$ = visitor.sessionHistory.asObservable();
    this.visitorSessionDetails$ = visitor.details.pipe(map(details => this.createSessionDetails(details)));

    this.onlineService.virtualBackground.next(visitor.virtualBackground.value);
  }

  closeRecord() {
    this.selectedSessionGuid = null;
    this.onlineService.virtualBackground.next(null);
  }

  private createDetails(visitor: Visitor): VisitorSiteDetails {
    return {
      section: visitor.lastSection,
      prevOp: visitor.previousOperator,
      location: visitor.location,
      useragent: visitor.browser,
      page: visitor.lastPage,
    };
  }

  private createSessionDetails(details: VisitorDetails): VisitorSessionDetails {
    return {
      organisation: details.Org,
      ip: details.IPAddress,
      referrer: details.Referrer,
      deviceScaleFactor: details.DevicePixelRatio,
      isMobile: false,
      isTablet: false,
      clientWidth: details.ClientWidth,
      clientHeight: details.ClientHeight
    };
  }

}
