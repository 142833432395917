<div class="container-fluid border-bottom py-2">
  <div class="row d-flex align-items-center">
    <div class="col-2">
      <p-avatar size="normal" shape="square" [styleClass]="getStyleClassForOnlineState()" label="{{ photo ? undefined : StringUtils.getInitials(name) }}" image="{{ photo }}"> </p-avatar>
    </div>
    <div class="col">
      <p>{{ name }}</p>
    </div>
    <div class="col-4">
      <div class="d-flex flex-row flex-grow-1 justify-content-end">
        <p-button severity="secondary" (onClick)="menu.toggle($event)" [rounded]="true" *ngIf="hasVisibleMenuItems()"
                  type="button">
          <fa-icon [icon]="faEllipsisV"></fa-icon>
        </p-button>

        <p-menu appendTo="body" #menu [model]="menuItems" [popup]="true">
          <ng-template pTemplate="item" let-item>
            <div class="d-flex flex-row align-items-center justify-content-between gap-2 p-3" role="button">
              <span [class]="item.icon"></span>
              <p class="text-truncate">{{item.label}}</p>
            </div>
          </ng-template>
        </p-menu>
         </div>
    </div>
  </div>
</div>
