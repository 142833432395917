import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslatePipe } from '../../filters/Translate.pipe';
import { CrmService } from '../../services/crm-service/crm.service';
import {BehaviorSubject, Subscription } from 'rxjs';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import {ShareOptions} from '../display-media-modal/display-media-modal.component';

export interface AppointmentTransferModalInitialState {
  appointmentGuid: string;
  startDate: string;
  endDate: string;
  onAttemptTransfer: (selectedAgent: string) => void;
  onCancelTransfer: () => {};
}
@Component({
  selector: 'app-appointment-transfer-modal',
  templateUrl: './appointment-transfer-modal.component.html',
  styleUrls: ['./appointment-transfer-modal.component.scss'],
  providers: [TranslatePipe]
})
export class AppointmentTransferModalComponent implements OnInit {

  private subscriptions: Subscription[] = [];
  public availableAgentsList: any = [];
  public selectedAgent: string;
  public appointmentGuid: string;
  public startDate: string;
  public endDate: string;
  public get data() {
    return this.dialogConfig.data;
  }


  constructor(
    private crmService: CrmService,
    private dialogConfig: DynamicDialogConfig<AppointmentTransferModalInitialState>
  ) {
    this.appointmentGuid = this.data.appointmentGuid;
    this.startDate = this.data.startDate;
    this.endDate = this.data.endDate;
  }

  ngOnInit() {
    this.loadAvailableAgents();
  }

  public onTransfer() {
    this.data?.onAttemptTransfer( this.selectedAgent );
  }
  public onCancelTransfer() {
    this.data?.onCancelTransfer();
  }

  private loadAvailableAgents() {
    const agents = this.crmService.getAvailableAgents(this.startDate, this.endDate).subscribe(types => {
      if (types && types.length > 0) {
        this.availableAgentsList = Array.from(types);
      }
    });
    this.subscriptions.push(agents);
  }
}
