import {Visitor} from '../services/visitor-service/visitor';
import {VisitorStatus} from '../enums/EngagementStatus.enum';

export class VisitorUtils {
    public static longestWaitTimeSort(v1: Visitor, v2: Visitor): number {
        return v2.assistTime - v1.assistTime;
    }

    public static veechatSort(v1: Visitor, v2: Visitor): number {
      if (v1.visitorStatus === v2.visitorStatus) {
        return v2.assistTime - v1.assistTime;
      } else {
        return v1.visitorStatus - v2.visitorStatus;
      }
      if (v1.visitorStatus === VisitorStatus.IdleTransfer
        && v2.visitorStatus !== VisitorStatus.IdleTransfer) {
        return 1;
      } else if (v2.visitorStatus === VisitorStatus.IdleTransfer
        && v1.visitorStatus !== VisitorStatus.IdleTransfer) {
        return -1;
      }

      return v2.assistTime - v1.assistTime;
    }
}
