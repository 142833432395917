export class CrmField {
  public static readonly TYPE_TEXT = 'Text';
  public static readonly TYPE_NOTE = 'Note';
  public static readonly TYPE_NUMBER = 'Number';
  public static readonly TYPE_CHOICE = 'Choice';
  public static readonly TYPE_TYPEAHEAD = 'Typeahead';
  public static readonly TYPE_DATETIME = 'DateTime';
  public static readonly TYPE_EXTERNAL_LINK = 'ExternalLink';
  public static readonly TYPE_LOCAL_COMMAND = 'LocalVariable';

  name: string;
  required?: boolean;
  type: string;
  readOnly?: boolean;
  choices?: any[];
  cannedText?: string[];
  url?: string;
  value?: string;
  category?: string;

  translatedTitle?: string;
}

export class CrmDataField {
  name: string;
  value: string;
}
