export class Timezones {
  public static TIMEZONE_LOOKUP: Map<string, string> = new Map (
    [
      ['Central Europe Standard Time', 'CET'],
      ['Central Standard Time', 'CST'],
      ['China Standard Time', 'CST'],
      ['Eastern Standard Time', 'EST'],
      ['GMT Standard Time', 'GMT'],
      ['India Standard Time', 'IST'],
      ['Tokyo Standard Time', 'JST'],
      ['Mountain Standard Time', 'MST'],
      ['Pacific Standard Time', 'PST'],
      ['Russian Standard Time', 'RST'],
      ['AUS Eastern Standard Time', 'AES'],
      ['Arab Standard Time', 'AST'],
      ['Central Brazilian Standard Time', 'BRT'],
      ['SE Asia Standard Time', 'ICT'],
      ['North Asia East Standard Time', 'WIT']
    ]
  );
}
