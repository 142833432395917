import { Pipe, PipeTransform } from "@angular/core";
import { Visitor } from "../services/visitor-service/visitor";
import {DefaultGroup} from "../pages/veechat/veechat-call-list/veechat-call-list.component";

@Pipe({
  name: 'visitorFilter',
  pure: false
})
export class VisitorFilterPipe implements PipeTransform {
  transform(visitor: Visitor[], selectedDept: string): Visitor[] {
    const filtered: Visitor[] = [];

    if (selectedDept != DefaultGroup.NoSuperVisor) {
      for (const info of visitor) {
        if (info.agentGroup.some(group => group.Name === selectedDept)) {
          filtered.push(info);
        }
      }
    }
    else {
      return visitor;
    }

    return filtered;
  }
}
