import { StatusEnum } from '../../enums/appointment-status';
import * as moment from 'moment';
export class AppointmentFilterData {
    totalAppointments: number;
    appointmentList: Appointment[];

    constructor(appointmentFilterData: AppointmentFilterData = null){
      if (appointmentFilterData != null) {
        this.totalAppointments = appointmentFilterData.totalAppointments;
        this.appointmentList = appointmentFilterData.appointmentList;
      }
    }
  }

  export class Appointment {
    appointmentId: number;
    appointmentGuid: string;
    startDate: string;
    endDate: string;
    callType: string;
    appointmentTypeId: number;
    categoryId: number;
    appointmentType: string;
    agentUserName: string;
    agent: string;
    description: string;
    status: string;

    appointmentDate: string;
    startTime: string;
    endTime: string;

    customerId: number;
    userGuid:string;
    customerName:string;
    customerEmail:string;
    customerPhone:string;
    customerNotes:string;
    notes:string;

    constructor(appointment: Appointment = null) {
      if (appointment != null) {
        this.appointmentGuid = appointment.appointmentGuid;
        this.appointmentId = appointment.appointmentId;
        this.agent = appointment.agent;
        this.agentUserName = appointment.agentUserName
        this.callType = appointment.callType;
        this.appointmentType = appointment.appointmentType;
        this.appointmentTypeId = appointment.appointmentTypeId;
        this.description = appointment.description;
        this.endDate = appointment.endDate;
        this.categoryId = appointment.categoryId;
        this.status = appointment.status;
        this.startDate =  moment(appointment.startDate).format('dddd MMMM DD, YYYY hh:mm A');
        this.endDate =   moment(appointment.endDate).format('dddd MMMM DD, YYYY hh:mm A');
        this.appointmentDate =  moment(appointment.startDate).format('dddd MMMM DD, YYYY');
        this.startTime =   moment(appointment.startDate).format('hh:mm A');
        this.endTime =  moment(appointment.endDate).format('hh:mm A');

        this.customerEmail = appointment.customerEmail;
        this.customerName = appointment.customerName;
        this.customerNotes = appointment.customerNotes;
        this.customerPhone = appointment.customerPhone;
        this.userGuid = appointment.userGuid;
        this.customerId = appointment.customerId;
        this.notes = appointment.notes;
      }
    }
  }
  export class  InsertAppointment {
    userGuid:String;
    startDate:String;
    endDate:String;
    siteSection:String;
    appointmentGuid:String;
    callType:string;
    engagementGuid:String;
    startTime:String;
    endTime:String;
    subject:String;
    isEditing:Boolean;
    notes:string;
    validationError:string;
    constructor(){
      this.subject = "";
      this.notes = "";
      this.isEditing = false;
    }
  }

  export class AppointmentType {
    appointmentTypeId: number;
    appointmentType: string;
  }

  export class AppointmentFilterParameter {
    appointmentTypeId : number;
    callType: string;
    status: number;
    from: string;
    to: string;
    onlyCurrentAgentData: boolean;
    agents: string[];
    constructor() {
      this.appointmentTypeId = 0;
      this.callType = '';
      this.status = 0;
      this.onlyCurrentAgentData = true;
      this.agents = [];
    }
  }
